import z from 'zod';

import { links } from '../app/router/paths';

const lineOfCreditAgreementParams = z.object({
	rentAmountCents: z.string(),
	feeCents: z.string(),
	fullName: z.string(),
	userEmail: z.string(),
	address: z.string(),
	signDate: z.string(),
	version: z.string(),
});
type PadParams = z.infer<typeof lineOfCreditAgreementParams>;

export const buildLineOfCreditAgreementLink = (params: PadParams) => {
	const query = new URLSearchParams(params);
	return `${links.DEFAULT.AGREEMENT.LINE_OF_CREDIT}#${query.toString()}`;
};

export const parseLineOfCreditAgreementPadParams = () => {
	const hash = window.location.hash.replace('#', '');
	const paramsObject = Object.fromEntries(new URLSearchParams(hash).entries());
	return lineOfCreditAgreementParams.parse(paramsObject);
};
