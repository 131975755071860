import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Modal } from '../../../base-ui/components';

import { usePageLinks } from '../use-page-links.hook';

type Props = {
	variant: 'onboarding' | 'account';
	indirectModel: boolean;
	closeModal: () => void;
};

export const CreditBuilderConfirmModal = ({ variant, indirectModel, closeModal }: Props) => {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-builder.confirm-decline-credit-builder-modal');
	const intl = useIntl();

	return (
		<Modal
			type="configuration"
			headerText={intl.formatMessage({
				defaultMessage: "Are you sure that you don't want to build your credit?",
				id: 'credit-builder-confirm-modal.are-you-sure',
			})}
			contentText={intl.formatMessage({
				defaultMessage:
					'You can build credit history with each rent payment regardless of how you pay your rent.',
				id: 'credit-builder-confirm-modal.build-credit-history',
			})}
			buttonText={intl.formatMessage({ defaultMessage: 'Not now', id: 'credit-builder-confirm-modal.not-now' })}
			buttonTestId="credit-builder-confirm-modal-not-now"
			buttonHandler={() => {
				closeModal();
				navigate(pageLinks.onIAmSure({ variant, indirectModel }));
			}}
			altButtonText={intl.formatMessage({
				defaultMessage: "Let's build credit",
				id: 'credit-builder-confirm-modal.build-credit',
			})}
			altButtonHandler={closeModal}
		/>
	);
};
