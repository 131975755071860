import React, { useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import z from 'zod';

import { zodPhoneVerificationCodeSchema } from '../../../shared/zod-schemas';

import { Alert, AlertText, AlertTitle, Button, Center, Footer, FormInput, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { firebaseErrors } from '../../contexts/firebase/firebase-api';
import { useFirebase, useInterface } from '../../contexts/hooks';
import { links } from '../../router/paths';
import { useAsyncHandler } from '../../utils/hooks/use-async-handler';

const Panel = styled.div`
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 44.6rem;
`;

export const VerifyPhone = () => {
	const { handlers } = useFirebase();
	const { setModal } = useInterface();

	const [codeVerifyError, setCodeVerifyError] = useState<unknown>();
	const [verificationCodeResent, setVerificationCodeResent] = useState(false);

	const [handleSignInVerifySMSCode, { loading }] = useAsyncHandler(handlers.handleSignInVerifySMSCode);
	const [handleReSendSmsCode, { loading: loadingReSend }] = useAsyncHandler(handlers.handleReSendSmsCode);

	const intl = useIntl();
	const schema = useMemo(
		() =>
			z.object({
				code: zodPhoneVerificationCodeSchema(intl),
			}),
		[intl],
	);
	type Schema = z.infer<typeof schema>;

	const form = useForm<Schema>({ resolver: zodResolver(schema) });

	const handleSubmit = ({ code }: Schema) => {
		handleSignInVerifySMSCode(code.toString()).catch((error) => {
			if (error.code === firebaseErrors.ERROR_CODE_MULTI_FACTOR_INVALID_CODE) {
				return form.setError('code', {
					message: intl.formatMessage({
						defaultMessage: 'Verification code is invalid',
						id: 'verify-phone.verification-code-is-invalid',
					}),
				});
			}
			if (error.code === firebaseErrors.ERROR_CODE_RECENT_SIGN_IN) {
				setModal(
					<div>
						<FormattedMessage defaultMessage="Sign In Again" id="common.sign-in-again" />
					</div>,
				);
			}
			setCodeVerifyError(error);
		});
	};

	const handleResendVerificationCode = async () => {
		await handleReSendSmsCode();
		setVerificationCodeResent(true);
	};

	return (
		<>
			<BackButton />
			<Center>
				<Panel>
					<Text type="title" center>
						<FormattedMessage defaultMessage="Verify phone number" id="verify-phone.verify-phone-number" />
					</Text>
					<Text center>
						<FormattedMessage
							defaultMessage="A code was sent to phone number. Please enter the code here once it arrives"
							id="verify-phone.enter-code"
						/>
					</Text>
				</Panel>

				{Boolean(codeVerifyError) && (
					<Alert>
						<AlertTitle>
							<FormattedMessage defaultMessage="An error ocurred" id="alert-error.an-error-occurred" />
						</AlertTitle>
						<AlertText>
							<FormattedMessage
								defaultMessage="Something went wrong at our end. Don't worry, we'll fix soon."
								id="forgot-password.error-text"
							/>
						</AlertText>
						<Button linkTo={links.DEFAULT.SIGN_IN}>
							<FormattedMessage defaultMessage="Sign In Again" id="common.sign-in-again" />
						</Button>
					</Alert>
				)}

				{!codeVerifyError && (
					<FormProvider {...form}>
						<form onSubmit={form.handleSubmit(handleSubmit)}>
							<FormInput
								name="code"
								placeholder={intl.formatMessage({
									defaultMessage: 'Verification code',
									id: 'verify-phone.verification-code',
								})}
								inputMode="numeric"
								testId="verify-phone-input-code"
							/>

							<Footer>
								<Button htmlType="submit" disabled={loading} testId="verify-phone-button-verify">
									<FormattedMessage defaultMessage="Verify" id="common.verify" />
								</Button>
								<Button
									type="link"
									onClick={handleResendVerificationCode}
									disabled={loadingReSend || verificationCodeResent}
									testId="verify-phone-button-resend"
								>
									{verificationCodeResent ? (
										<FormattedMessage
											defaultMessage="Verification code was resent"
											id="registration-verify-phone.code-resent"
										/>
									) : (
										<FormattedMessage
											defaultMessage="Resend the verification code"
											id="registration-verify-phone.resend-code"
										/>
									)}
								</Button>
							</Footer>
						</form>
					</FormProvider>
				)}
			</Center>
		</>
	);
};
