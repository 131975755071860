export { VerifyPhone } from './login/verify-phone';
export { SignUp } from './registration/sign-up';
export { SignIn } from './login/sign-in';
export { ForgotPassword } from './login/forgot-password';
export { AuthActions } from './login/auth-actions';
export { ResetPassword } from './login/reset-password';

export { Home } from './home';
export { NotFound404 } from './not-found-404';

export { AccountSelectSchedule } from './account/account-select-schedule';
export { AccountConfirmCustomRent } from './account/account-confirm-custom-rent';
export { OnboardingSurvey as AccountOnboardingSurvey } from './onboarding-survery';
export { EnableCustomRent } from './account/enable-custom-rent';

export { RegistrationVerifyPhone } from './registration/registration-verify-phone';
export { RegistrationVerifyEmail } from './registration/registration-verify-email';

export { CustomRentOnboardingSelectProperty } from './custom-rent-onboarding/1-select-property';
export { CustomRentOnboardingSelectUnit } from './custom-rent-onboarding/2-select-unit';
export { CustomRentOnboardingRecommendedUnits } from './custom-rent-onboarding/3a-recommended-units';
export { CustomRentOnboardingManualAddressOnboarding } from './custom-rent-onboarding/3b-manual-address';
export { LandlordConnected as CustomRentOnboardingLandlordConnected } from './custom-rent-onboarding/4-landlord-connected';
export { CustomRentOnboardingConnectBank } from './custom-rent-onboarding/9-connect-bank';
export { CustomRentOnboardingAwaitingTransactions } from './custom-rent-onboarding/12-awaiting-transactions';
export { CustomRentOnboardingCannotValidateEarnings } from './custom-rent-onboarding/12b-cannot-validate-earnings';
export { LandlordPaymentMethod } from './custom-rent-onboarding/10-landlord-payment-method';
export { SelectRentAmount } from './custom-rent-onboarding/6-select-rent-amount';
export { CustomRentRoommatesRentAmountConfirm } from './custom-rent-onboarding/7-roommates-rent-amount-confirm';
export { SelectSchedule } from './custom-rent-onboarding/8-select-schedule';
export { ConfirmCustomRent } from './custom-rent-onboarding/13-confirm-custom-rent';

export { EnterBankDetails } from './manual-bank-onboarding/enter-bank-details';
export { StartVerification } from './manual-bank-onboarding/start-verification';

export { ConnectBank } from './bank-onboarding/connect-bank';
export { AwaitingTransactions } from './bank-onboarding/awaiting-transactions';
export { CannotValidateEarnings } from './bank-onboarding/cannot-validate-earnings';

export { SelectProperty } from './residency/select-property';
export { SelectUnit } from './residency/select-unit';
export { RecommendedUnits } from './residency/recommended-units';
export { ManualAddress } from './residency/manual-address';
export { LandlordConnected } from './residency/landlord-connected';

export { LandingAvenueLiving } from './landing/landing-avenue-living';
