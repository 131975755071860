import { publicConfig } from '../../../functions/src/shared/config';

import { bankStatementStorage } from '../app/contexts/firebase/firebase-api';

export const uploadBankStatementsOptions = (userId: string, accountId: string) => ({
	storage: bankStatementStorage,
	pathPrefix: `${userId}/`,
	metadata: {
		[publicConfig.bankStatements.uploadMetadata.userId]: userId ?? '',
		[publicConfig.bankStatements.uploadMetadata.accountId]: accountId ?? '',
	},
});
