import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import congrats from 'url:../../static/shapes/congrats.png';
import learning from 'url:../../static/shapes/learning.png';
import payToZenbase from 'url:../../static/shapes/pay-to-zenbase.png';
import reschedule from 'url:../../static/shapes/reschedule.png';
import support from 'url:../../static/shapes/support.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Box, Icon, Spacing, Text } from '../../../base-ui/components';

import { HighlightTeal } from '../../components/highlighted';
import { ImgPreload } from '../../components/img-preload';
import { OnboardingSuccess } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { PageVariant, usePageLinks } from '../use-page-links.hook';

type CreditBoostSuccessCongratsProps = {
	variant: PageVariant;
};
export function CreditBoostSuccessCongrats({ variant }: CreditBoostSuccessCongratsProps) {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.success.congrats');

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={congrats} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Congrats for setting up your rent payments with Zenbase!"
							id="credit-boost-success.congrats-title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="You can now build credit even faster with rent payments through Zenbase."
							id="credit-boost-success.congrats-subtitle"
						/>
					}
					beforeCta={
						<>
							<img src={learning} alt="" width="180" />
							<Spacing $size="m" />
							<Text center color="title">
								<FormattedMessage
									defaultMessage="Here are a few things to remember."
									id="credit-boost-success.learning-title"
								/>
							</Text>
							<Spacing $size="l" />
							<Icon icon="DownArrow" color="green" />
						</>
					}
					cta={<FormattedMessage defaultMessage="Next" id="common.next" />}
					ctaTestId="onboarding-success-credit-boost-next-button"
					onNext={() => navigate(pageLinks.onNext({ variant }))}
					backButton={false}
				/>
			</Box>

			<ImgPreload src={support} />
			<ImgPreload src={payToZenbase} />
			<ImgPreload src={reschedule} />
		</>
	);
}

type CreditBoostSuccessDoNotPayLandlordProps = {
	variant: PageVariant;
};
export function CreditBoostSuccessDoNotPayLandlord({ variant }: CreditBoostSuccessDoNotPayLandlordProps) {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.success.do-not-pay-landlord');

	return (
		<OnboardingSuccess
			imageSrc={payToZenbase}
			title={
				<FormattedMessage
					defaultMessage="Don't need to make any payments directly to your landlord"
					id="credit-boost-success.do-not-pay-landlord-title"
				/>
			}
			subtitle={
				<FormattedMessage
					defaultMessage="Your landlord will be all set on the <color>1st</color>, let <color>Zenbase take care of your payments.</color>"
					id="credit-boost-success.do-not-pay-landlord-subtitle"
					values={{ HighlightTeal }}
				/>
			}
			ctaTestId="onboarding-success-credit-boost-do-not-pay-landlord-button"
			onNext={() => navigate(pageLinks.onNext({ variant }))}
		/>
	);
}

type CreditBoostSuccessCheckYourReportedPaymentsProps = {
	variant: PageVariant;
};
export function CreditBoostSuccessCheckYourReportedPayments({
	variant,
}: CreditBoostSuccessCheckYourReportedPaymentsProps) {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.success.check-your-reported-payments');

	return (
		<OnboardingSuccess
			imageSrc={reschedule}
			title={
				<FormattedMessage
					defaultMessage="Check your reported rent payments"
					id="credit-boost-success.check-rent-payments-title"
				/>
			}
			subtitle={
				<FormattedMessage
					defaultMessage="You can monitor your reported rent payments to the bureaus on your Zenbase dashboard."
					id="credit-boost-success.check-rent-payments-subtitle"
				/>
			}
			ctaTestId="onboarding-success-credit-boost-check-rent-payments-button"
			onNext={() => navigate(pageLinks.onNext({ variant }))}
		/>
	);
}

export function CreditBoostSuccessAlwaysHereToAssist() {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.success.always-here-to-assist');

	const trackOnboardingEvent = useTrackOnboardingEvent();

	return (
		<OnboardingSuccess
			imageSrc={support}
			title={
				<FormattedMessage
					defaultMessage="Always here to assist"
					id="credit-boost-success.always-here-to-assist-title"
				/>
			}
			subtitle={
				<FormattedMessage
					defaultMessage="If you have any questions around your payments and other solutions like custom rent payments feel free to text message your Zenbase team."
					id="credit-boost-success.always-here-to-assist-subtitle"
				/>
			}
			ctaTestId="onboarding-success-credit-boost-here-to-assist-button"
			onNext={() => {
				trackOnboardingEvent.mutate(
					{
						type: BusinessEventType.OnboardingCreditBoostEnd,
					},
					{
						onSuccess() {
							navigate(pageLinks.onNext());
						},
					},
				);
			}}
		/>
	);
}
