import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { centsToAmount } from '../../../../../../functions/src/shared/monetary';
import { FeatureType } from '../../../../../../functions/src/shared/user';

import { trpc } from '../../../../shared/trpc/client';

import { links } from '../../../router/paths';
import * as S from '../profile-errors/profile-errors.style';

export const ScheduledDirectDebitPayment = () => {
	const payments = trpc.user.listDirectDebitTransactions.useQuery(undefined);
	const navigate = useNavigate();

	if (!payments.data?.length) {
		return null;
	}

	const payment = payments.data[0];

	return (
		<S.Wrapper
			data-testid="direct-debit-pending-payment"
			onClick={() =>
				navigate(
					generatePath(links.ACCOUNT.DIRECT_DEBIT.CHARGE, {
						ptid: payment.id,
					}),
				)
			}
		>
			<S.Title>
				<FormattedMessage
					defaultMessage="You have pending {featureType} Debit Card payment for {amount}. {pay}"
					id="dashboard-pending-direct-debit.text-v2"
					values={{
						featureType: FeatureType[payment.featureId],
						amount: (
							<FormattedNumber
								value={centsToAmount(payment.amountCents)}
								style="currency"
								currency="CAD"
							/>
						),
						pay: (
							<u style={{ cursor: 'pointer' }}>
								<FormattedMessage defaultMessage="Pay now" id="dashboard-pending-direct-debit.pay" />
							</u>
						),
					}}
				/>
			</S.Title>
		</S.Wrapper>
	);
};
