import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import residency from 'url:../static/shapes/residency.png';

import { notNullish } from '../../../../functions/src/shared/utils';

import { trpc } from '../../shared/trpc/client';
import { triggerChatWidget } from '../../shared/zendesk';

import { Anchor } from '../../base-ui/components';

import { useUserData } from '../contexts/user-data-context';
import { links } from '../router/paths';
import { OnboardingSuccess } from './onboarding-success';

export const DidYouMoveOut = () => {
	const navigate = useNavigate();
	const intl = useIntl();
	const {
		home,
		handlers: { forceRefetch },
	} = useUserData();

	const start = trpc.user.startDidYouMoveOut.useMutation();
	const dismiss = trpc.user.dismissDidYouMoveOut.useMutation({
		onSuccess() {
			forceRefetch().then(() => {
				navigate(links.ACCOUNT.DASHBOARD, { replace: true });
			});
		},
	});

	const movedOutResidency = home?.movedOutResidency;

	useEffect(() => {
		if (movedOutResidency) {
			start.mutate();
		}
	}, [movedOutResidency]);

	const handleClose = () => {
		if (movedOutResidency) {
			dismiss.mutate();
		}
		navigate(links.ACCOUNT.DASHBOARD, { replace: true });
	};

	const address = [
		movedOutResidency?.addressLine1,
		movedOutResidency?.city,
		movedOutResidency?.state,
		movedOutResidency?.postalCode,
	]
		.filter(notNullish)
		.join(', ');

	const at = intl.formatMessage({ id: 'common.at-address', defaultMessage: 'at' });

	return (
		<>
			<OnboardingSuccess
				title={<FormattedMessage id="did-you-move-out.title" defaultMessage="Did you move out?" />}
				subtitle={
					<>
						<FormattedMessage
							id="did-you-move-out.subtitle"
							defaultMessage="It looks like you moved out of your home{address}."
							values={{ address: address ? ` ${at} ${address}` : '' }}
						/>
						<br />
						<br />
						<FormattedMessage
							id="did-you-move-out.contact-support"
							defaultMessage="Please update your address to set up Zenbase. If you need any help please {contactSupport}."
							values={{
								contactSupport: (
									<Anchor onClick={triggerChatWidget}>
										<FormattedMessage
											id="did-you-move-out.contact-support-link"
											defaultMessage="contact our customer support team"
										/>
									</Anchor>
								),
							}}
						/>
					</>
				}
				imageSrc={residency}
				imageProps={{
					padding: '5rem 3rem 0',
					textAlign: 'center',
					maxHeight: '28rem',
				}}
				ctaTestId="onboarding-did-you-move-out-update-address-button"
				cta={<FormattedMessage id="did-you-move-out.update-address" defaultMessage="Update My Address" />}
				onNext={() => {
					navigate(links.ACCOUNT.RESIDENCY.SELECT_PROPERTY, { replace: true });
				}}
				footerStickyOnMobile
				backButton={false}
				secondaryCta={<FormattedMessage id="profile.contact-support" defaultMessage="Contact Support" />}
				onSecondaryCta={() => {
					triggerChatWidget();
				}}
				closeButton
				onClose={handleClose}
				addMobileStickyMarginTop
			/>
		</>
	);
};
