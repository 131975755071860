import React from 'react';
import { useIntl } from 'react-intl';

import { RentMonth } from '../../../../functions/src/shared/rent-month';

import { formatRentMonthIntl } from '../../shared/components/formatted-rent-month';

import { Modal } from '../../base-ui/components';

type Props = {
	rentMonth: RentMonth;
	onContinue: () => void;
};

export const ConfirmCustomRentCannotEnrollForMonth = (props: Props) => {
	return (
		<ConfirmCannotEnrollForMonth
			rentMonth={props.rentMonth}
			onContinue={props.onContinue}
			featureName="CustomRent"
		/>
	);
};
export const ConfirmCreditBoostCannotEnrollForMonth = (props: Props) => {
	return (
		<ConfirmCannotEnrollForMonth
			rentMonth={props.rentMonth}
			onContinue={props.onContinue}
			featureName="CreditBoost"
		/>
	);
};

export const ConfirmCannotEnrollForMonth = ({
	rentMonth,
	onContinue,
	featureName,
}: Props & { featureName: 'CustomRent' | 'CreditBoost' }) => {
	const intl = useIntl();

	return (
		<Modal
			type="warning"
			headerText={intl.formatMessage(
				{
					id: 'confirm-rent-cannot-enroll-for-month.header-text',
					defaultMessage: `Cannot enroll for {rentMonth}`,
				},
				{ rentMonth: formatRentMonthIntl(intl, rentMonth) },
			)}
			contentText={intl.formatMessage(
				{
					id: 'confirm-rent-cannot-enroll-for-month.content-text',
					defaultMessage: `The deadline for signing up to {featureName} for {rentMonth} has passed. You can still sign up for {nextRentMonth}.`,
				},
				{
					rentMonth: formatRentMonthIntl(intl, rentMonth),
					nextRentMonth: formatRentMonthIntl(intl, rentMonth.next()),
					featureName,
				},
			)}
			buttonText={intl.formatMessage({
				id: 'common.continue',
				defaultMessage: 'Continue',
			})}
			buttonHandler={onContinue}
		/>
	);
};
