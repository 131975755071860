import React, { ReactNode } from 'react';
import { useController } from 'react-hook-form';

import { Input, InputMode } from '../input/input';
import { PasswordInput } from '../password-input';
import { PhoneInput } from '../phone-input/phone-input';
import { RadioCheckbox } from '../radio/radio-checkbox';

type FormInputProps = {
	name: string;
	placeholder?: string;
	type?: string;
	disabled?: boolean;
	testId?: string;
	inputMode?: InputMode;
	label?: ReactNode;
	autoFocus?: boolean;
	prefix?: string;
	onClick?: (...args: unknown[]) => void;
	autoComplete?: 'off' | string;
	transform?: (val?: string) => string | undefined;
};

export const FormInput = ({
	disabled = false,
	type = 'text',
	placeholder,
	name,
	inputMode,
	testId,
	label,
	autoFocus,
	prefix,
	onClick,
	autoComplete,
	transform,
}: FormInputProps) => {
	const {
		fieldState: { error },
		field,
	} = useController({ name });

	const errorMessage = error?.message;

	const handleOnBlur = () => {
		field.onChange(transform ? transform(field.value?.trim?.()) : field.value?.trim?.());
		field.onBlur();
	};

	if (type === 'password') {
		return (
			<PasswordInput
				value={field.value}
				onInputChange={field.onChange}
				placeholder={placeholder}
				type={type}
				disabled={disabled}
				id={name}
				name={name}
				errorText={errorMessage}
				onBlur={field.onBlur}
				testId={testId}
			/>
		);
	}

	if (type === 'tel') {
		return (
			<PhoneInput
				name={name}
				value={field.value}
				onChange={(value) => field.onChange(value)}
				placeholder={placeholder}
				testId={testId}
				disabled={disabled}
				errorText={errorMessage}
			/>
		);
	}

	if (type === 'checkbox') {
		return <RadioCheckbox {...field} errorMessage={errorMessage} label={label} testId={testId} />;
	}

	return (
		<Input
			value={field.value}
			onInputChange={field.onChange}
			placeholder={placeholder}
			type={type}
			disabled={disabled}
			id={name}
			name={name}
			errorText={errorMessage}
			onBlur={handleOnBlur}
			testId={testId}
			inputMode={inputMode}
			autoFocus={autoFocus}
			prefix={prefix}
			onClick={() => {
				return onClick?.(field.value);
			}}
			autoComplete={autoComplete}
		/>
	);
};
