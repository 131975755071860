import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import success from 'url:../../static/shapes/connect-bank.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { trpc } from '../../../shared/trpc/client';

import { Box, Spacing } from '../../../base-ui/components';

import { useUserData } from '../../contexts/hooks';
import { OnboardingSuccess } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { PageVariant, usePageLinks } from '../use-page-links.hook';

export function CreditBoostDirectDebitSuccess({ variant }: { variant: PageVariant }) {
	const pageLinks = usePageLinks('credit-boost.direct-debit.success');
	const navigate = useNavigate();

	return (
		<DirectDebitCardSuccess
			onSuccess={() => {
				navigate(pageLinks.onSuccess({ variant }));
			}}
		/>
	);
}

type Props = {
	onSuccess?: () => void;
};
export const DirectDebitCardSuccess = ({ onSuccess }: Props) => {
	const trackEvent = useTrackOnboardingEvent();
	const utils = trpc.useUtils();
	const { home } = useUserData();

	function handleNext() {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitSuccessConfirmed },
			{
				onSuccess: () => {
					utils.user.listOnboardingEvents.invalidate();
					onSuccess?.();
				},
			},
		);
	}

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={success} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Your debit card will be used for your Zenbase payments."
							id="custom-rent-onboarding.direct-debit-success.title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="Your recurring payments to Zenbase will be charged automatically to this card ending with {number} on your payment dates."
							id="custom-rent-onboarding.direct-debit-success.text"
							values={{ number: home?.cardAccount?.mask ?? '****' }}
						/>
					}
					cta={
						<FormattedMessage
							defaultMessage="I CONFIRM"
							id="custom-rent-onboarding.direct-debit-success.confirm"
						/>
					}
					onNext={handleNext}
					ctaTestId="direct-debit-card-success-continue-button"
					ctaDisabled={trackEvent.isPending}
					backButton={false}
				/>
			</Box>
		</>
	);
};
