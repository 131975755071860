import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Navigate, useNavigate } from 'react-router-dom';
import directDebitOk from 'url:../../static/shapes/connect-bank.png';

import { CircularProgress } from '@mui/material';

import { trpc } from '../../../shared/trpc/client';

import { Box, Spacing } from '../../../base-ui/components';

import { links } from '../../router/paths';
import { OnboardingSuccess } from '../../templates/onboarding-success';

export const DirectDebitPay = () => {
	const navigate = useNavigate();
	const payments = trpc.user.listDirectDebitTransactions.useQuery(undefined);

	if (payments.isPending) {
		return <CircularProgress sx={{ display: 'block', mx: 'auto' }} />;
	}

	if (!payments.data?.length) {
		return <Navigate to={links.ACCOUNT.DASHBOARD} />;
	}

	const payment = payments.data[0];

	function handleNext() {
		navigate(
			generatePath(links.ACCOUNT.DIRECT_DEBIT.CHARGE, {
				ptid: payment.id,
			}),
		);
	}
	function handleDecline() {
		navigate(links.ACCOUNT.DASHBOARD);
	}

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={directDebitOk} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Your debit card will be used for your Zenbase payments."
							id="custom-rent-onboarding.direct-debit-pay.title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="Your recurring payments to Zenbase will be charged automatically on your payment dates."
							id="custom-rent-onboarding.direct-debit-pay.text"
						/>
					}
					cta={
						<FormattedMessage
							defaultMessage="I CONFIRM"
							id="custom-rent-onboarding.direct-debit-pay.confirm"
						/>
					}
					secondaryCta={
						<FormattedMessage
							defaultMessage="Cancel"
							id="custom-rent-onboarding.direct-debit-setup.cancel"
						/>
					}
					ctaTestId="direct-debit-setup-continue-button"
					onNext={handleNext}
					onSecondaryCta={handleDecline}
				/>
			</Box>
		</>
	);
};
