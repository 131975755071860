import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import awaitingTransaction from 'url:../../static/shapes/awaitingTransaction.png';

import { publicConfig } from '../../../../../functions/src/shared/config';

import { getEnvironment } from '../../../shared/environment';
import { trpc } from '../../../shared/trpc/client';
import { triggerChatWidget } from '../../../shared/zendesk';

import { Button, Footer, Spacing, Text, WarningModal } from '../../../base-ui/components';

import { AwaitingTransactionSteps } from '../../components/awaiting-transaction-steps';
import { StatusPanel } from '../../components/status-panel';
import { Variant } from '../../contexts/bank-onboarding-context/bank-onboarding-context';
import { useBankOnboarding, useInterface } from '../../contexts/hooks';
import { useUserData } from '../../contexts/user-data-context';

const Image = styled.img`
	width: 230px;
	position: absolute;
	right: 5rem;
	z-index: -1;
	top: 5rem;
	@media (max-width: 450px) {
		width: 165px;
		top: 6.5rem;
		right: 0;
	}
`;

type Props = {
	titleText: React.ReactNode;
	connectAgainLink: string;
	connectManuallyLink: string;
};

const ErrorVerifyingTransactionsModal = ({
	connectInstantlyAgainHandler,
	connectManuallyHandler,
	variant,
}: {
	connectManuallyHandler: () => void;
	connectInstantlyAgainHandler: () => void;
	variant: Variant;
}) => {
	if (variant === 'credit-boost') {
		// TODO: creditboost copy
		return (
			<WarningModal
				headerText={
					<FormattedMessage
						defaultMessage="There was an issue validating your transactions through instant bank connection"
						id="awaiting-transactions.error-verifying-transactions-modal.header"
					/>
				}
				contentText={
					<FormattedMessage
						defaultMessage="Please try connecting again"
						id="awaiting-transactions.error-verifying-transactions-modal.please-try-again"
					/>
				}
				buttonHandler={connectInstantlyAgainHandler}
				buttonText={
					<FormattedMessage
						defaultMessage="Try again"
						id="awaiting-transactions.error-verifying-transactions-modal.try-again-button"
					/>
				}
				altButtonText={
					<FormattedMessage
						defaultMessage="Contact support"
						id="awaiting-transactions.error-verifying-transactions-modal.contact-support-button"
					/>
				}
				altButtonHandler={triggerChatWidget}
			/>
		);
	}
	return (
		<WarningModal
			headerText={
				<FormattedMessage
					defaultMessage="There was an issue validating your transactions through instant bank connection"
					id="awaiting-transactions.error-verifying-transactions-modal.header"
				/>
			}
			contentText={
				<FormattedMessage
					defaultMessage="Would you like to try connecting manually?"
					id="awaiting-transactions.error-verifying-transactions-modal.try-again"
				/>
			}
			buttonHandler={connectManuallyHandler}
			buttonText={<FormattedMessage defaultMessage="Yes" id="common.yes" />}
			altButtonText={
				<FormattedMessage
					defaultMessage="No, I’ll try instantly connecting my bank later"
					id="awaiting-transactions.error-verifying-transactions-modal.no"
				/>
			}
			altButtonHandler={connectInstantlyAgainHandler}
		/>
	);
};

export const AwaitingTransactions = ({ titleText, connectManuallyLink, connectAgainLink }: Props) => {
	const { user } = useUserData();
	const { setModal, closeModal } = useInterface();
	const [isPending, setLoading] = useState(false);
	const { handleBankOnboardingCompleted, variant } = useBankOnboarding();
	const [status, setStatus] = useState<'pending' | 'retry-exhausted' | 'finished'>('pending');
	const [retryCount, setRetryCount] = useState(0);
	const navigate = useNavigate();

	const hasPendingItem = trpc.user.hasPendingItem.useQuery();

	useEffect(() => {
		if (!hasPendingItem.data) {
			setStatus('finished');
		} else if (retryCount >= publicConfig.instantBankConnection.awaitingTransactionsRetryCount[getEnvironment()]) {
			setStatus('retry-exhausted');
			setModal(
				<ErrorVerifyingTransactionsModal
					variant={variant}
					connectManuallyHandler={() => {
						navigate(connectManuallyLink);
						closeModal();
					}}
					connectInstantlyAgainHandler={() => {
						navigate(connectAgainLink);
						closeModal();
					}}
				/>,
			);
		} else if (hasPendingItem.data) {
			new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
				setRetryCount((r) => r + 1);
				hasPendingItem.refetch();
			});
		}
	}, [hasPendingItem.data]);

	const handleClickContinue = async () => {
		setLoading(true);
		try {
			await handleBankOnboardingCompleted();
		} catch {
			// if there was no error, user is redirected away
			setLoading(false);
		}
	};

	return (
		<>
			<Image src={awaitingTransaction} />
			<Text type="title-extra-large-primary">
				<FormattedMessage
					defaultMessage="Hi {firstName}!"
					id="awaiting-transactions.greeting"
					values={{ firstName: user?.firstName }}
				/>
			</Text>
			<Text type="title-large-secondary">{titleText}</Text>
			<Text type="title-ternary">
				<FormattedMessage defaultMessage="Set up in progress" id="awaiting-transactions.set-up-in-progress" />
			</Text>

			<StatusPanel finished={status === 'finished'} type="awaiting-transactions" />
			<Spacing $size="l" />
			<AwaitingTransactionSteps status={status} />
			<Spacing $size="xl" />
			{status === 'finished' ? (
				<Footer>
					<Button
						disabled={isPending}
						onClick={handleClickContinue}
						testId="awaiting-transaction-button-continue"
					>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			) : (
				<>
					<Text color="highlighted">
						<FormattedMessage defaultMessage="You're almost done!" id="awaiting-transactions.almost-done" />
					</Text>
					<Spacing $size="s" />
					<Text type="small" color="muted">
						<FormattedMessage
							defaultMessage="As soon as we verify your bank account transactions you should be all set to enjoy rent day bliss on the 1st of the month!"
							id="awaiting-transactions.verification-message"
						/>
					</Text>
				</>
			)}
		</>
	);
};
