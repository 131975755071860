import React from 'react';

import { BankConnectionRoutes } from '../../router/bank-connection-routes';
import { links, paths } from '../../router/paths';
import { PageVariant, usePageLinks } from '../use-page-links.hook';

export function CreditBoostConnectBankAccountRoutes({ variant }: { variant: PageVariant }) {
	const pageLinks = usePageLinks('credit-boost.connect-bank-account');
	return (
		<BankConnectionRoutes
			links={links.REGISTRATION.CREDIT_BOOST.BANK}
			paths={paths.REGISTRATION.CREDIT_BOOST.BANK}
			variant="credit-boost"
			onCompletedNavigateParams={[pageLinks.onCompleted({ variant }), {}]}
		/>
	);
}
