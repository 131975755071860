import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Spacing } from '../../base-ui/components';
import { CloseCircle, Document } from '../../base-ui/components/icon/icons';
import { colors } from '../../base-ui/variables';

import { UploadWithProgressOptions, useUploadFilesWithProgress } from '../use-upload-with-progress';
import UploadProgressBar from './upload-progress-bar';

const isValidFile = (file: File) => {
	const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
	const maxSize = 15 * 1024 * 1024;

	return file && validTypes.includes(file.type) && file.size <= maxSize;
};

const StyledFilename = styled.div`
	margin-left: 10px;
	text-overflow: ellipsis;
	color: ${colors.green};
	white-space: nowrap;
	font-weight: 500;
	overflow: hidden;
	max-width: 200px;
	@media (max-width: 597px) {
		max-width: 120px;
	}
`;

type UploadProps = {
	header: React.ReactNode;
	buttonComp: React.ElementType;
	buttonCompProps?: React.ComponentProps<React.ElementType>;
	backButton?: React.ReactNode;
	dragAndDropText?: React.ReactNode;
	maxWidth?: string;
	uploadOnDrop?: boolean;
	onContinue?: () => void;
	customMetadata?: Record<string, string>;
} & Pick<UploadWithProgressOptions, 'storage' | 'pathPrefix' | 'metadata'>;

export function Upload(props: UploadProps) {
	const { storage, pathPrefix, metadata, maxWidth, uploadOnDrop, onContinue } = props;
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [dragging, setDragging] = useState(false);

	const { progresses, uploadFiles, errors } = useUploadFilesWithProgress({
		files: selectedFiles,
		storage,
		pathPrefix,
		metadata,
	});

	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const files: File[] = [];
			for (let i = 0; i < e.target.files.length; i++) {
				const file = e.target.files.item(i);
				if (!file || !isValidFile(file)) continue;
				files.push(file);
			}
			setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
		}
	};

	const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDragging(true);
	}, []);

	const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDragging(false);
	}, []);

	const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDragging(false);

		if (e.dataTransfer.files) {
			const files: File[] = [];
			for (let i = 0; i < e.dataTransfer.files.length; i++) {
				const file = e.dataTransfer.files.item(i);
				if (!file || !isValidFile(file)) continue;
				files.push(file);
			}
			setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
		}
	}, []);

	const handleDropAreaClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const dropAreaStyles: React.CSSProperties = {
		border: dragging ? '2px dashed #0a6375' : '2px dashed #121111FF',
		borderRadius: '20px',
		padding: '15px 20px',
		textAlign: 'center',
		cursor: 'pointer',
		backgroundColor: dragging ? '#f8f9fa' : 'white',
		margin: '5px 55px',
	};
	const removeFile = (index: number) => {
		setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};

	useEffect(() => {
		if (uploadOnDrop) uploadFiles({ customMetadata: props.customMetadata });
	}, [uploadOnDrop, selectedFiles]);

	const ButtonComponent = props.buttonComp;
	const dragAndDropText = props.dragAndDropText ?? (
		<p>
			<FormattedMessage
				defaultMessage="Drag and drop files here or click to select files"
				id="upload.drag-and-drop-files"
			/>
		</p>
	);
	return (
		<>
			{props.header}
			<Spacing $size="s" />
			<ul style={{ listStyleType: 'none', padding: 0 }}>
				{selectedFiles.map((file, index) => (
					<li
						key={index}
						style={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: '5px',
							padding: '6px',
							borderRadius: '5px',
							backgroundColor: '#f5f5f5',
							justifyContent: 'space-between',
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Document />
								<StyledFilename>{file.name}</StyledFilename>
							</div>
							{errors[index] && (
								<p style={{ color: 'red', maxWidth: '250px' }}>
									<FormattedMessage
										defaultMessage="An error occurred during the upload, please try again or contact support."
										id="upload.upload-error"
									/>
								</p>
							)}
						</div>
						<div
							style={{
								width: maxWidth ?? '120px',
								marginLeft: '10px',
								display: 'flex',
							}}
						>
							<UploadProgressBar progress={progresses[index]} error={Boolean(errors[index])} />
							{progresses[index] === undefined ? (
								<button
									onClick={() => removeFile(index)}
									style={{
										backgroundColor: 'transparent',
										border: 'none',
										cursor: 'pointer',
									}}
								>
									<span style={{ alignSelf: 'center', display: 'flex' }}>
										<CloseCircle fill={colors.green} />
									</span>
								</button>
							) : (
								<div style={{ width: '15px' }}></div>
							)}
						</div>
					</li>
				))}
			</ul>
			<div
				style={dropAreaStyles}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				onClick={handleDropAreaClick}
			>
				{dragAndDropText}
				<input
					type="file"
					multiple
					onChange={handleFileChange}
					style={{ display: 'none' }}
					ref={fileInputRef}
					accept=".pdf, .jpg, .jpeg"
					data-testid="upload-file-input"
				/>
			</div>
			<Spacing $size="s" />

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					marginTop: '10px',
				}}
			>
				<ButtonComponent
					testId="upload-continue-button"
					data-testid="upload-continue-button"
					onClick={() => {
						if (uploadOnDrop && selectedFiles.length && onContinue) {
							onContinue();
						} else if (uploadOnDrop && !selectedFiles.length) {
							handleDropAreaClick();
						} else if (!uploadOnDrop) {
							uploadFiles({ customMetadata: props.customMetadata });
						}
					}}
					{...props.buttonCompProps}
				>
					{uploadOnDrop && selectedFiles.length ? (
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					) : (
						<FormattedMessage defaultMessage="Upload" id="upload.upload" />
					)}
				</ButtonComponent>
				{props.backButton ? props.backButton : <></>}
			</div>
		</>
	);
}
