import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import onboarding1 from 'url:../static/shapes/onboarding-1.png';
import payToLandlord from 'url:../static/shapes/pay-to-landlord.png';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';

import { RouterInput } from '../../shared/trpc/client';

import { useUserData } from '../contexts/user-data-context';
import { OnboardingSuccess } from '../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';
import { useCreditBoost } from './credit-boost/use-credit-boost.hook';
import { usePageLinks } from './use-page-links.hook';

export function CustomRentOrCreditBoost() {
	const { customRentSupported } = useUserData();
	const trackOnboardingEvent = useTrackOnboardingEvent();
	const { canUse: canUseCreditBoost } = useCreditBoost();
	const navigate = useNavigate();
	const pageLinks = usePageLinks('customrent-or-creditboost');

	return (
		<OnboardingSuccess
			imageSrc={onboarding1}
			title={
				<FormattedMessage
					defaultMessage="The most flexible rent payments ever"
					id="credit-builder-payment-completed-flexible-rent-payments-title"
				/>
			}
			subtitle={
				<FormattedMessage
					defaultMessage="Smart rent payments that let you split your rent if you'd like and improve your cashflow."
					id="credit-builder-payment-completed-flexible-rent-payments-subtitle"
				/>
			}
			ctaTestId="onboarding-success-cb-step6-continue-button"
			onNext={() => {
				const events: RouterInput['user']['trackOnboardingEvent']['type'] = [
					BusinessEventType.OnboardingCreditBuilderRentalEnd,
				];
				if (!customRentSupported) {
					events.push(BusinessEventType.OnboardingCustomRentWaitlistStart);
				} else {
					events.push(BusinessEventType.OnboardingCustomRentStart);
				}

				trackOnboardingEvent.mutate({
					type: events,
				});
			}}
			ctaDisabled={trackOnboardingEvent.isPending}
			cta={
				<FormattedMessage
					defaultMessage="Set up flexible rent payments"
					id="credit-builder-payment-completed-flexible-rent-payments-button"
				/>
			}
			secondaryCta={
				<FormattedMessage defaultMessage="Not now" id="credit-builder-payment-completed-not-now-button" />
			}
			onSecondaryCta={() => {
				if (!canUseCreditBoost) {
					navigate(pageLinks.onAccessToCreditBoostForbidden());
					return;
				}

				trackOnboardingEvent.mutate(
					{
						type: [
							BusinessEventType.OnboardingCreditBuilderRentalEnd,
							BusinessEventType.OnboardingCreditBoostStart,
						],
					},
					{
						onSuccess: () => {
							navigate(pageLinks.onCreditBoostStarted());
						},
					},
				);
			}}
		/>
	);
}

export function PayToLandlord() {
	const trackOnboardingEvent = useTrackOnboardingEvent();
	const pageLinks = usePageLinks('custom-rent.decline-pay-to-landlord');

	const navigate = useNavigate();

	return (
		<OnboardingSuccess
			imageSrc={payToLandlord}
			title={
				<FormattedMessage
					defaultMessage="Make rent payments directly to your landlord"
					id="credit-builder-payment-completed-make-rent-payments-title"
				/>
			}
			ctaTestId="onboarding-success-cb-step7-continue-button"
			subtitle={
				<FormattedMessage
					defaultMessage="Please make your payments directly to your landlord with your preferred method of payment. You can always enable CustomRent on your Zenbase dashboard."
					id="credit-builder-payment-completed-make-rent-payments-subtitle"
				/>
			}
			onNext={() => {
				navigate(pageLinks.onNext());
				trackOnboardingEvent.mutate({
					type: BusinessEventType.OnboardingCreditBuilderRentalEnd,
				});
			}}
			ctaDisabled={trackOnboardingEvent.isPending}
			cta={<FormattedMessage defaultMessage="Ok, I Understand" id="credit-builder-payment-completed-ok-button" />}
		/>
	);
}
