import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import connectBank from 'url:../../static/shapes/connect-bank.png';

import { RentMonth } from '../../../../../functions/src/shared/rent-month';
import { FeatureType } from '../../../../../functions/src/shared/user';

import { FormattedRentMonth } from '../../../shared/components/formatted-rent-month';
import { trpc } from '../../../shared/trpc/client';

import { Button, Center, Footer, Spacing, Text } from '../../../base-ui/components';
import { UnexpectedErrorAlert } from '../../../base-ui/components/alert/alert';

import { BackButton } from '../../components/back-button';
import { links } from '../../router/paths';

export const EnableCustomRent = () => {
	const navigate = useNavigate();
	const { data: config, isError } = trpc.user.customRent.getConfig.useQuery({
		featureId: FeatureType.CustomRent,
	});

	const handleEnableCustomRent = () => {
		if (!config) {
			navigate(links.ACCOUNT.CONFIGURE.SELECT_RENT_AMOUNT);
		} else {
			navigate(links.ACCOUNT.CONFIGURE.SELECT_SCHEDULE);
		}
	};

	if (isError) {
		return <UnexpectedErrorAlert />;
	}

	return (
		<>
			<BackButton />

			<Text type="title">
				<FormattedMessage defaultMessage="Would you like to enable CustomRent?" id="enable-custom-rent-title" />
			</Text>
			<Spacing $size="xl" />

			<Center>
				<img alt="Connect bank" src={connectBank} width="295px" height="177px" />
			</Center>
			<Spacing $size="xl" />

			{config && (
				<Text>
					<FormattedMessage
						defaultMessage="By enabling CustomRent you can start splitting your rent payments in {rentMonth}."
						id="enable-custom-rent-description"
						values={{
							rentMonth: (
								<FormattedRentMonth rentMonth={config?.rentMonth ?? RentMonth.current().next()} />
							),
						}}
					/>
				</Text>
			)}
			<Spacing $size="xl" />

			<Footer>
				<Button onClick={() => handleEnableCustomRent()}>
					<FormattedMessage defaultMessage="Enable" id="enable-custom-rent-button" />
				</Button>
			</Footer>
		</>
	);
};
