import React from 'react';
import styled from 'styled-components';

import { RouterOutput } from '../../../../../functions/src/api/trpc/types';

import { PartnerConnectedLogo } from '../../../shared/components/landlord-logo';

import { Spacing } from '../../../base-ui/components';

import { EquifaxBubble } from '../../components/icons/equifax-bubble';

const EquifaxBubbleCustom = styled(EquifaxBubble)`
	font-size: 10rem;
`;
const LogosWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	align-items: center;
`;

export const CreditBuilderBeforeCta = ({
	landlord,
	referralPartnerId,
}: {
	landlord: RouterOutput['user']['home']['landlord'] | null | undefined;
	referralPartnerId?: string;
}) => {
	const propertyManagerNameOrReferralPartnerId = referralPartnerId || landlord?.propertyManagerName;
	return (
		<>
			<LogosWrapper>
				{propertyManagerNameOrReferralPartnerId && (
					<PartnerConnectedLogo propertyManagerName={propertyManagerNameOrReferralPartnerId} />
				)}
				<EquifaxBubbleCustom />
			</LogosWrapper>
			<Spacing $size="xl" />
		</>
	);
};
