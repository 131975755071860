import { z } from 'zod';

import { FeatureType } from './user';

export const userRentFeatureTypeSchema = z.literal(FeatureType.CustomRent).or(z.literal(FeatureType.CreditBoost));
export type UserRentFeatureType = z.infer<typeof userRentFeatureTypeSchema>;

export const userRentFeatureTypes = [FeatureType.CustomRent, FeatureType.CreditBoost];

export const isUserRentFeatureType = (featureId: FeatureType): featureId is UserRentFeatureType => {
	return userRentFeatureTypes.includes(featureId);
};
