import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { FormattedMessage } from 'react-intl';
import { Navigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { publicConfig } from '../../../../functions/src/shared/config';

import { getEnvironment } from '../../shared/environment';
import { queryClient, trpc } from '../../shared/trpc/client';

import { Loader, Spacing, Text } from '../../base-ui/components';

import { BackButton } from '../components/back-button';
import { links } from '../router/paths';

export const StripeAccountStatus = () => {
	const [search] = useSearchParams();
	const successPath = search.get('success');

	const [stripePromise] = useState(() => loadStripe(publicConfig.stripe[getEnvironment()].publicKey));
	const [result, setResult] = useState<'success' | 'processing' | 'error'>();
	//
	const [accountStatusRefetchLimit, setAccountStatusRefetchLimit] = useState(false);
	const accountStatus = trpc.user.paymentCards.getStripeAccount.useQuery(undefined, {
		enabled: Boolean(result === 'success'),
		refetchInterval: (data) => {
			if (data.state.dataUpdateCount === 0) {
				return 0;
			}
			return data.state.dataUpdateCount > 10 ? false : 1000;
		},
	});
	useEffect(() => {
		if (!accountStatus.data) {
			return;
		}
		const state = queryClient.getQueryState([
			['user', 'paymentCards', 'getStripeAccount'],
			{ input: undefined, type: 'query' },
		]);
		if ((state?.dataUpdateCount ?? 0) > 10) {
			setAccountStatusRefetchLimit(true);
		}
	}, [accountStatus.data]);

	const loadIntentStatus = async () => {
		const clientSecret = search.get('setup_intent_client_secret');
		const stripe = await stripePromise;
		if (!clientSecret || !stripe) {
			Sentry.captureException(new Error('clientSecret or stripe missing'));
			return;
		}

		const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);
		switch (setupIntent?.status) {
			case 'succeeded':
				setResult('success');
				break;

			case 'processing':
				setResult('processing');
				break;

			case 'requires_payment_method':
				// Redirect your user back to your payment page to attempt collecting
				// payment again
				// 'Failed to process payment details. Please try another payment method.'
				setResult('error');
				break;

			default:
				Sentry.captureException(
					new Error(`unknown setupIntent ${clientSecret} status: ${setupIntent?.status}`),
				);
		}
	};

	useEffect(() => {
		loadIntentStatus();
	}, []);

	if (result === 'success') {
		if (successPath) {
			if (accountStatus.data?.success || accountStatusRefetchLimit) {
				return <Navigate to={{ pathname: successPath, search: `?payment=success` }} />;
			}
			return (
				<FormLoader>
					<Loader />
				</FormLoader>
			);
		}

		return (
			<>
				<BackButton to={links.ACCOUNT.DASHBOARD}></BackButton>
				<Text type="title">
					<FormattedMessage defaultMessage="Card added!" id="stripe-card-status.card-added" />
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage defaultMessage="Your card was added." id="payment-status.payment-processed" />
				</Text>
				<Spacing $size="l" />
			</>
		);
	}

	if (result === 'error') {
		return (
			<>
				<BackButton to={links.ACCOUNT.DASHBOARD}></BackButton>
				<Text type="title">
					<FormattedMessage defaultMessage="Processing error" id="payment-status.processing-error" />
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage defaultMessage="Please contact support." id="payment-status.contact-support" />
				</Text>
				<Spacing $size="l" />
			</>
		);
	}

	// this should not really happen unless we enable bank transfer payments for Stripe
	if (result === 'processing') {
		return (
			<>
				<BackButton to={links.ACCOUNT.DASHBOARD}></BackButton>
				<Text type="title">
					<FormattedMessage defaultMessage="Processing card" id="stripe-card-status.processing-payment" />
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage
						defaultMessage="Your card is still processing. For some cards, this make take up to two business days."
						id="stripe-card-status.payment-processing"
					/>
				</Text>
				<Spacing $size="l" />
			</>
		);
	}

	return (
		<FormLoader>
			<Loader />
		</FormLoader>
	);
};

const FormLoader = styled.div`
	position: absolute;
	z-index: 0;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;
