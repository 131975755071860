import React, { useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { parse } from 'date-fns';
// but in date-fns + parce = format cannot be imported as named import
import format from 'date-fns/format';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import z from 'zod';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { jsDateToIsoDate } from '../../../../functions/src/shared/iso-date';

import { useMyZenbaseLinks } from '../../shared/my-zenbase-links';
import { RouterInput, trpc } from '../../shared/trpc/client';

import { Anchor, Box, Button, Footer, FormInput, Spacing, Text } from '../../base-ui/components';

import { AlertError } from '../components/alert-error';
import { BackButton } from '../components/back-button';
import { DateOfBirthInput } from '../components/date-of-birth-input';
import { CreditReporting } from '../components/icons/credit-reporting';
import { EquifaxBubble } from '../components/icons/equifax-bubble';
import { OnboardingCloseButton } from '../components/onboarding-close-button';
import { useUserData } from '../contexts/user-data-context';
import { PageVariant, usePageLinks } from '../pages/use-page-links.hook';
import { links } from '../router/paths';
import { OnboardingSuccess } from '../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';

export const CreditReportingCustom = styled(CreditReporting)`
	font-size: 18rem;
`;
export const EquifaxBubbleCustom = styled(EquifaxBubble)`
	font-size: 10rem;
`;

type SignUpType = Extract<
	Extract<RouterInput['creditReporting']['signUp'], { allTermsAccepted: true }>['reporting']['signingUpFor'],
	'line-of-credit' | 'secured-line-of-credit'
>;

type SharedProps = {
	pageLinks: {
		onSignUpSuccess: (i: { variant: PageVariant }) => string;
	};
	variant: PageVariant;
	signingUpFor: SignUpType;
};

export function CreditReportingLineOfCreditSignUpShared({ pageLinks, variant, signingUpFor }: SharedProps) {
	const intl = useIntl();
	const navigate = useNavigate();
	const myZenbaseLinks = useMyZenbaseLinks();

	const schema = useMemo(
		() =>
			z.object({
				enabled: z.boolean().refine(
					(accepted) => accepted,
					intl.formatMessage({
						defaultMessage: 'Please consent to payment reporting by checking the box to proceed',
						id: 'credit-reporting-line-of-credit.required',
					}),
				),
				dateOfBirth: z
					.string()
					.transform((dateStr) => jsDateToIsoDate(parse(dateStr, 'MM/dd/yyyy', new Date())) as string),
			}),
		[intl],
	);
	type FormSchema = z.infer<typeof schema>;

	const utils = trpc.useUtils();
	const creditReportingSignUp = trpc.creditReporting.signUp.useMutation({
		onSuccess({ onboardingEvents }) {
			utils.user.listOnboardingEvents.setData(undefined, onboardingEvents);
		},
	});
	const { data: userCreditSubject } = trpc.user.getCreditSubject.useQuery();

	useEffect(() => {
		if (userCreditSubject) {
			form.setValue('dateOfBirth', '01/01/1970');
		}
	}, [userCreditSubject]);

	const form = useForm<FormSchema>({
		resolver: zodResolver(schema),
		defaultValues: { enabled: false },
	});

	const submit = ({ dateOfBirth }: FormSchema) => {
		creditReportingSignUp.mutate(
			{ allTermsAccepted: true, dateOfBirth, reporting: { signingUpFor } },
			{
				onSuccess() {
					navigate(pageLinks.onSignUpSuccess({ variant }));
				},
			},
		);
	};
	return (
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(submit)}>
					<BackButton />

					<Text type="title">
						<FormattedMessage
							defaultMessage="Confirm payment reporting"
							id="custom-rent-credit-builder.confirm-payment-reporting"
						/>
					</Text>
					<Spacing $size="s" />
					<Text>
						{userCreditSubject ? (
							<>
								<FormattedMessage
									defaultMessage="Please consent terms of use to complete your set up for reporting your payments to credit bureaus."
									id="custom-rent-credit-builder.consent-terms-of-use"
								/>
							</>
						) : (
							<>
								<FormattedMessage
									defaultMessage="Please enter your date of birth to complete your set up for reporting your payments to credit bureaus."
									id="custom-rent-credit-builder.enter-date-of-birth"
								/>
							</>
						)}
					</Text>
					<Spacing $size="s" />

					{!userCreditSubject && (
						<DateOfBirthInput
							name="dateOfBirth"
							testId="credit-reporting-loc-date-of-birth-input"
							handleSelect={(d) => {
								if (!d) return;
								// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
								form.setValue('dateOfBirth', format(d, 'MM/dd/yyyy'));
							}}
						/>
					)}
					<Spacing $size="s" />

					<FormInput
						name="enabled"
						type="checkbox"
						testId="credit-reporting-loc-terms-checkbox"
						label={
							<>
								<FormattedMessage
									defaultMessage="I agree to the"
									id="custom-rent-credit-builder.i-agree-to"
								/>{' '}
								<Anchor href={myZenbaseLinks.terms} target="_blank">
									<FormattedMessage
										defaultMessage="Terms of Use"
										id="custom-rent-credit-builder.terms-of-use"
									/>
								</Anchor>
								.
							</>
						}
					/>

					{creditReportingSignUp.isError && <AlertError />}

					<Footer stickyOnMobile>
						<Button
							htmlType="submit"
							disabled={creditReportingSignUp.isPending}
							testId="credit-reporting-loc-continue-button"
						>
							<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
						</Button>
					</Footer>
				</form>
			</FormProvider>
		</>
	);
}

export function CreditReportingLineOfCreditConfirm({ variant }: { variant: PageVariant }) {
	const pageLinks = usePageLinks('custom-rent.line-of-credit');
	return (
		<CreditReportingLineOfCreditSignUpShared
			pageLinks={pageLinks}
			variant={variant}
			signingUpFor="line-of-credit"
		/>
	);
}

export function CreditReportingLineOfCredit({ variant }: { variant: PageVariant }) {
	const trackOnboardingEvent = useTrackOnboardingEvent();
	const navigate = useNavigate();
	useEffect(
		() => trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingCreditBuilderLineOfCreditStart }),
		[],
	);
	const pageLinks = usePageLinks('custom-rent.line-of-credit');
	return <CreditReportingLineOfCreditContent onNext={() => navigate(pageLinks.onNext({ variant }))} />;
}

function CreditReportingLineOfCreditContent({ onNext }: { onNext: () => void }) {
	const intl = useIntl();
	const { home } = useUserData();

	const landlordName = home?.landlord?.propertyManagerName;

	return (
		<>
			<OnboardingCloseButton to={links.ACCOUNT.DASHBOARD} stopOnboardingReminders />
			<Box isColumn verticalCenter>
				<CreditReportingCustom />
				<Spacing $size="xl" />

				<OnboardingSuccess
					title={intl.formatMessage({
						defaultMessage: 'Get credit for your flexible rent payments',
						id: 'custom-rent-credit-builder.get-credit-for-rent-payments',
					})}
					subtitle={
						<>
							<FormattedMessage
								defaultMessage="Thanks to {landlordName}, you can now build credit by reporting your Zenbase payments to Equifax. We don't check credit but we help build credit."
								id="custom-rent-credit-builder.thanks-to-landlord"
								values={{ landlordName }}
							/>
						</>
					}
					beforeCta={
						<>
							<EquifaxBubbleCustom />
							<Spacing $size="xl" />
						</>
					}
					ctaTestId="credit-reporting-loc-continue-button"
					cta={intl.formatMessage({ defaultMessage: 'Continue', id: 'common.continue' })}
					onNext={onNext}
					footerStickyOnMobile
					backButton={false}
				/>
			</Box>
		</>
	);
}
