import { SecretVersion } from '../core/secrets';
import { ResidentLedgerTransactions } from '../pms/property-residents-ledger.type';
import { SaltedEncrypt } from '../utils/crypto';
import { EftBankAccountType } from './eft-bank-account-type';
import { IsoDate } from './iso-date';
import { LandlordCustomRent } from './landlords';

export enum IntegratedPms {
	Yardi = 1,
	YardiBreeze = 2,
	None = 3,
	Fortress = 4,
	RentManager = 5,
	Buildium = 6,
	HopemCogirQc = 7,
}

// ATM only relevant for IntegratedPms.Yardi, should be typed for each IntegratedPms in future
export type YardiPmsIntegrationConfiguration = {
	// yardi api URLs & credentials
	url_wsdl_common: string;
	url_wsdl_billing: string;
	parameters: {
		ServerName: string;
		Database: string;
		Platform: string;
		InterfaceEntity: string;
		InterfaceLicense: string;
	};
};

export type RentManagerPmsIntegrationConfiguration = {
	/** Properties with this UserDefinedValue will be dropped
	 * @example
	 * { name: 'Parking Project', value: 'Yes' }
	 * // drops properties with UserDefinedValues containing a `{ "Name": "Parking Project", "Value": "Yes" }`
	 */
	blacklistPropertiesByUserDefinedValue?: { name: string; value: string };
};

export type PmsPaymentParameters = Record<string, string | boolean | number> &
	(YardiPmsPaymentParameters | Record<string, never>);

export type YardiPmsPaymentParameters = {
	companyId?: string; // Company / fundingEntity id
	includePostMonth?: boolean;
	displayType?: 'Standard Receipt' | 'Intercompany';
};

export type YardiPmsIntegrationConfigurationWithoutInterfaceLicense = Omit<
	YardiPmsIntegrationConfiguration,
	'parameters'
> & {
	parameters: Omit<YardiPmsIntegrationConfiguration['parameters'], 'InterfaceLicense'>;
};

export type Landlord = {
	id: string;
	name: string;
	defaultAccountId: string | null;
	ledgerLateFeesFilter?: Partial<ResidentLedgerTransactions[0]> | null; // lodash filter
	ledgerBaseRentFilter?: Partial<ResidentLedgerTransactions[0]> | null; // lodash filter
	ledgerPreAuthorizedPaymentFilter?: Partial<ResidentLedgerTransactions[0]> | null; // lodash filter
	ledgerNsfFeesFilter?: Partial<ResidentLedgerTransactions[0]> | null; // lodash filter
	ledgerNsfPaymentFilter?: Partial<ResidentLedgerTransactions[0]> | null; // lodash filter
	customRentCutoffDay: number | null;
	newUserSignUpDeadlineDay: number | null;
	firstInstallmentMaxDay: number | null;
	upfrontPaymentDeadlineDay: number | null;
	allowAfterCutoffSignUp: boolean;
	firstPaymentPercent: number;
	propertyManagerId: string;
	apiCredentialsSecret: SecretVersion | null;
	pmsPaymentParameters?: PmsPaymentParameters | null;
	hidden: boolean;
	syncEnabled: boolean;
	customRent: LandlordCustomRent;
	saveEncryptedPersonalInfo: boolean;
	defaultEftBankAccountType: EftBankAccountType;
	earlyMoveInSupported: boolean;
} & (
	| LandlordYardiParams
	| LandlordYardiBreezeParams
	| LandlordFortressParams
	| LandlordRentManagerParams
	| LandlordBuildiumParams
	| LandlordHopemCogirParams
	| { integratedPmsId: IntegratedPms.None }
);

export type LandlordBuildiumParams = {
	integratedPmsId: IntegratedPms.Buildium;
};

export type LandlordYardiParams = {
	integratedPmsId: IntegratedPms.Yardi;
	pmsIntegrationConfiguration: YardiPmsIntegrationConfiguration;
	batchPaymentParameters?: YardiPmsPaymentParameters | null;
};

export type LandlordYardiBreezeParams = {
	integratedPmsId: IntegratedPms.YardiBreeze;
};
export type LandlordFortressParams = {
	integratedPmsId: IntegratedPms.Fortress;
};
export type LandlordRentManagerParams = {
	pmsIntegrationConfiguration?: RentManagerPmsIntegrationConfiguration;
	integratedPmsId: IntegratedPms.RentManager;
};

export type LandlordHopemCogirParams = {
	integratedPmsId: IntegratedPms.HopemCogirQc;
};

export type LandlordNone = Extract<Landlord, { integratedPmsId: IntegratedPms.None }>;
export type LandlordYardi = Extract<Landlord, { integratedPmsId: IntegratedPms.Yardi }>;
export type LandlordYardiBreeze = Extract<Landlord, { integratedPmsId: IntegratedPms.YardiBreeze }>;
export type LandlordFortress = Extract<Landlord, { integratedPmsId: IntegratedPms.Fortress }>;
export type LandlordRentManger = Extract<Landlord, { integratedPmsId: IntegratedPms.RentManager }>;
export type LandlordBuildium = Extract<Landlord, { integratedPmsId: IntegratedPms.Buildium }>;
export type LandlordDreamCogirQc = Extract<Landlord, { integratedPmsId: IntegratedPms.HopemCogirQc }>;

export enum PropertyCountry {
	CA = 'CA',
	US = 'US',
}

export enum PmsEntityStatus {
	Active = 'active',
	Deleted = 'deleted',
}

export enum PmsSyncMode {
	Enabled = 'enabled',
	Discard = 'discard',
}

export interface Property {
	id: string;
	integratedPmsId: IntegratedPms;
	pmsPropertyId: string;
	pmsPropertyCode?: string;
	name: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	city: string;
	state: string;
	postalCode: string;
	country: PropertyCountry;
	landlordId: string;
	status: PmsEntityStatus;
	updatedAt?: Date;
	earlyMoveInSupported?: boolean;
}

export interface PropertyUnit {
	id: string;
	propertyId: string;
	pmsUnitId: string;
	pmsUniqueId: string;
	name: string;
	address: string;
	city: string;
	state: string;
	postalCode: string;
	country: PropertyCountry;
	hasResident: boolean | null;
	residentPMSId: string;
	residentStatus: string;
	status: PmsEntityStatus;
	landlordName?: string;
	landlordId?: string;
	updatedAt?: Date;
	sqft: number | null;
}

export interface PropertyResident {
	id: string;
	propertyUnitId: string;
	pmsTenantId: string;
	pmsTenantHouseholdId?: string | null;
	status: PropertyResidentStatus;
	info: string | null;
	firstName: string | null;
	firstNameEncrypted: SaltedEncrypt | null;
	lastName: string | null;
	lastNameEncrypted: SaltedEncrypt | null;
	phoneNumber: string | null;
	phoneNumberEncrypted: SaltedEncrypt | null;
	email: string | null;
	emailEncrypted: SaltedEncrypt | null;
	rentAmount: number | null;
	startDate: IsoDate | null;
	endDate: IsoDate | null;
	moveInDate: IsoDate | null;
	moveOutDate: IsoDate | null;
	noticeDate: IsoDate | null;
	updatedAt: Date;
	pmsUnitId: string;
	pmsPropertyId: string;
	address: string;
	city: string;
	state: string;
	postalCode: string;
	country: PropertyCountry;
	landlord: Pick<Landlord, 'id' | 'name' | 'defaultAccountId' | 'defaultEftBankAccountType'>;
	property: Property;
	deleted: boolean;
	userPmsResidencyId: string;
	isLessee: boolean | null;
	pmsLeaseId?: string | null;
	pmsMetadata?: PropertyResidentPmsMetadata | null;
	isEmployee: boolean;
}

export type PmsResidentInsurance = {
	// policy number, does not have to be unique for a resident
	policyNumber: string;
	// identifier for the insurance in the PMS
	pmsInsuranceId: string;
	liabilityAmountCents: number;
	effectiveDate: string;
	expiryDate: string;
	cancelDate?: string;
	policyTitle: string;
	insuranceCompany: string;
};

export type PropertyResidentFromPms = Pick<
	PropertyResident,
	| 'pmsTenantId'
	| 'pmsTenantHouseholdId'
	| 'pmsLeaseId'
	| 'pmsMetadata'
	| 'firstName'
	| 'lastName'
	| 'email'
	| 'phoneNumber'
	| 'startDate'
	| 'endDate'
	| 'moveInDate'
	| 'moveOutDate'
	| 'noticeDate'
	| 'rentAmount'
	| 'status'
	| 'info'
	| 'isEmployee'
> & {
	insurances?: PmsResidentInsurance[];
};

export interface PropertyOccupant {
	firstName: string;
	lastName: string;
}

export interface PropertyResidentRoommate {
	id: string;
	propertyResidentId: PropertyResident['id'];
	pmsRoommateId: string;
	firstName: string | null;
	firstNameEncrypted: SaltedEncrypt | null;
	lastName: string | null;
	lastNameEncrypted: SaltedEncrypt | null;
	phoneNumber: string | null;
	phoneNumberEncrypted: SaltedEncrypt | null;
	email: string | null;
	emailEncrypted: SaltedEncrypt | null;
	moveInDate: IsoDate | null;
	moveOutDate: IsoDate | null;
	updatedAt: Date;
	status: PropertyResidentStatus;
	isLessee: boolean | null;
	isEmployee: boolean;
	deleted: boolean;
}

export enum PropertyResidentStatus {
	Applicant = 'Applicant',
	Future = 'Future',
	Current = 'Current',
	Notice = 'Notice',
	Eviction = 'Eviction',
	Past = 'Past',
	Canceled = 'Canceled',
	Denied = 'Denied',
	MovedOut = 'Moved Out',
	Waitlist = 'Waitlist',
}

export interface PropertyResidentPmsMetadata {
	pmsTenantDisplayId?: string;
}

export enum UserPmsResidencyType {
	Direct = 'direct',
	Indirect = 'indirect',
}

export enum ManualResidencyLeaseAgreementStatus {
	Pending = 'pending',
	Verified = 'verified',
	ReferralVerified = 'referral-verified',
	UpdateRequested = 'update-requested',
}

export enum ManualResidencyLeaseType {
	Fixed = 'fixed',
	MonthToMonth = 'month-to-month',
}

export const verifiedLeaseAgreementStatuses = [
	ManualResidencyLeaseAgreementStatus.Verified,
	ManualResidencyLeaseAgreementStatus.ReferralVerified,
] as const;

export interface PmsCreateInsurancePayload {
	pmsTenantId: number;
	liabilityAmountCents: number;
	roommates: Array<{
		firstName: string;
		lastName: string;
		pmsRoommateId: number;
	}>;
	firstName: string;
	lastName: string;
	insuranceCompany: string;
	policyTitle: string;
	policyNumber: string;
	effectiveDate: Date;
	expiryDate: Date;
	cancelDate?: Date;
	pmsInsuranceId?: number;
}
