import React from 'react';
import { FormattedMessage } from 'react-intl';
import { To, useLocation } from 'react-router-dom';

import { Box, Button, Footer } from '../../base-ui/components';

import { OnboardingCarousel } from '../components/carousel/onboarding-carousel';
import { links } from '../router/paths';

export const Home = () => {
	const { state } = useLocation();
	const { originalLocation } = (state || {}) as { originalLocation?: To };

	return (
		<Box verticalCenter isColumn>
			<OnboardingCarousel />
			<Footer>
				<Button type="large" linkTo={links.REGISTRATION.USER.SIGN_UP} testId="home-button-sign-up">
					<FormattedMessage id="common.sign-up" defaultMessage="Sign Up" />
				</Button>
				<Button
					type="link"
					linkTo={links.DEFAULT.SIGN_IN}
					linkState={{ originalLocation }}
					testId="home-button-sign-in"
				>
					<FormattedMessage id="common.sign-in" defaultMessage="Sign In" />
				</Button>
			</Footer>
		</Box>
	);
};
