import React, { FC } from 'react';
import { matchPath, Navigate, Outlet, useLocation } from 'react-router-dom';

import { UserStatus } from '../../contexts/user-data-context';
import { ADMIN_USERS_URL, links } from '../paths';

type UserStatusHandler = { to: string; match?: string } | { render: FC };

const userStatusHandlers: Record<UserStatus, UserStatusHandler> = {
	[UserStatus.Loading]: { render: () => null },
	[UserStatus.Unauthenticated]: { to: links.REGISTRATION.USER.SIGN_UP, match: links.REGISTRATION.USER.SIGN_UP },
	[UserStatus.Admin]: {
		render: () => {
			window.location.href = ADMIN_USERS_URL;
			return <></>;
		},
	},
	[UserStatus.OnboardingVerifyEmail]: { to: links.REGISTRATION.USER.VERIFY_EMAIL },
	[UserStatus.OnboardingEnrollPhone]: { to: links.REGISTRATION.USER.VERIFY_PHONE },
	[UserStatus.OnboardingAddress]: {
		to: links.REGISTRATION.RESIDENCY.SELECT_PROPERTY,
		match: links.REGISTRATION.RESIDENCY.ROOT,
	},
	[UserStatus.PendingOnboardingSurvey]: { to: links.REGISTRATION.ONBOARDING_SURVEY },
	[UserStatus.PendingLandlordPaymentMethod]: { to: links.REGISTRATION.LANDLORD_PAYMENT_METHOD },
	[UserStatus.OnboardingSelectRentAmount]: {
		to: links.REGISTRATION.CONFIGURE.SELECT_RENT_AMOUNT,
		match: links.REGISTRATION.CONFIGURE.ROOT,
	},
	[UserStatus.OnboardingRoommatesRentAmountConfirm]: {
		to: links.REGISTRATION.CONFIGURE.ROOMMATES_RENT_AMOUNT_CONFIRM,
		match: links.REGISTRATION.CONFIGURE.ROOT,
	},
	[UserStatus.OnboardingSelectDates]: {
		to: links.REGISTRATION.CONFIGURE.SELECT_SCHEDULE,
		match: links.REGISTRATION.CONFIGURE.ROOT,
	},
	[UserStatus.CountryNotSupported]: { to: links.REGISTRATION.COUNTRY_NOT_SUPPORTED },
	[UserStatus.OnboardingBank]: {
		to: links.REGISTRATION.BANK.CONNECT_BANK,
		match: links.REGISTRATION.BANK.ROOT,
	},
	[UserStatus.OnboardingLineOfCreditReporting]: {
		to: links.REGISTRATION.CONFIGURE.CUSTOM_RENT_REPORTING.ROOT,
		match: links.REGISTRATION.CONFIGURE.CUSTOM_RENT_REPORTING.ROOT,
	},
	[UserStatus.OnboardingConfirm]: {
		to: links.REGISTRATION.CONFIGURE.CONFIRM_CUSTOM_RENT,
		match: links.REGISTRATION.CONFIGURE.ROOT,
	},
	[UserStatus.SuccessSteps]: {
		to: links.REGISTRATION.ONBOARDING_SUCCESS.ROOT,
		match: links.REGISTRATION.ONBOARDING_SUCCESS.ROOT,
	},
	[UserStatus.ValidUser]: { to: links.ACCOUNT.DASHBOARD },
	[UserStatus.Partner]: { to: links.ACCOUNT.PARTNER },
	// credit builder
	[UserStatus.OnboardingCreditBuilderSetup]: {
		to: links.REGISTRATION.CREDIT_BUILDER.SETUP.ROOT,
		match: links.REGISTRATION.CREDIT_BUILDER.ROOT,
	},
	[UserStatus.OnboardingCreditBuilderHistoryUpsell]: {
		to: links.REGISTRATION.CREDIT_BUILDER.HISTORY_REPORTING.ROOT,
		match: links.REGISTRATION.CREDIT_BUILDER.HISTORY_REPORTING.ROOT,
	},
	[UserStatus.OnboardingCreditBuilderSubscriptionSelection]: {
		to: links.REGISTRATION.CREDIT_BUILDER.SUBSCRIPTION_TYPE,
		match: links.REGISTRATION.CREDIT_BUILDER.ROOT,
	},
	[UserStatus.OnboardingCreditBuilderSuccess]: {
		to: links.REGISTRATION.CREDIT_BUILDER.SUCCESS,
		match: links.REGISTRATION.CREDIT_BUILDER.ROOT,
	},
	[UserStatus.OnboardingCreditBuilderPaymentCompleted]: {
		to: links.REGISTRATION.CREDIT_BUILDER.PAYMENT_COMPLETED,
		match: links.REGISTRATION.CREDIT_BUILDER.ROOT,
	},
	[UserStatus.PromoCreditBuilderSetup]: { to: links.ACCOUNT.CREDIT_BUILDER.SETUP },
	[UserStatus.PromoCreditBuilderSuccess]: { to: links.ACCOUNT.CREDIT_BUILDER.SUCCESS },
	[UserStatus.OnboardingCustomRentWaitlist]: {
		to: links.REGISTRATION.CUSTOM_RENT_WAITLIST,
	},
	[UserStatus.OnboardingDirectDebitSetup]: {
		to: links.REGISTRATION.DIRECT_DEBIT.SETUP,
	},
	[UserStatus.OnboardingDirectDebitCardDetails]: {
		to: links.REGISTRATION.DIRECT_DEBIT.CARD_DETAILS,
	},
	[UserStatus.OnboardingDirectDebitCardSuccess]: {
		to: links.REGISTRATION.DIRECT_DEBIT.SUCCESS,
	},
	[UserStatus.OnboardingCreditBoost]: {
		to: links.REGISTRATION.CREDIT_BOOST.SETUP.ROOT,
		match: links.REGISTRATION.CREDIT_BOOST.ROOT,
	},
	[UserStatus.OnboardingCreditBoostSelectRentAmount]: {
		to: links.REGISTRATION.CREDIT_BOOST.SELECT_RENT_AMOUNT,
		match: links.REGISTRATION.CREDIT_BOOST.ROOT,
	},
};

type Props = { userStatus: UserStatus };

export const Registration = ({ userStatus }: Props) => {
	const location = useLocation();
	const userStatusHandler = userStatusHandlers[userStatus];

	if (!userStatusHandler) {
		throw new Error(`no user status handler for user status: ${userStatus}`);
	}

	if ('render' in userStatusHandler) {
		const R = userStatusHandler.render;
		return <R />;
	}

	const { to, match } = userStatusHandler;

	if (matchPath(match ? { path: match, end: false } : to, location.pathname)) {
		return <Outlet />;
	}

	return <Navigate to={to} replace />;
};
