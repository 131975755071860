export enum BusinessEventType {
	UserRentStatusChanged = 'user_rent.status_changed',
	UserMissedPayment = 'payment_transactions.missed_payment',
	MissedEtransferEftRescheduled = 'missed_etransfer_eft_rescheduled',
	MissedDirectDebitEftRescheduled = 'missed_direct_debit_eft_rescheduled',
	ReturnedPayment = 'payment_transactions.returned',
	FailedPayment = 'payment_transactions.failed',
	MissedRepaymentWindow = 'missed_repayment_window',
	PlaidAccountReconnected = 'plaid_action.reconnect',
	PlaidAccountReconnectRequired = 'plaid_action.reconnect_required',
	Random = 'random',
	UserResidencyUnverified = 'user_residency_unverified',
	RentAmountChangedByLeaseCharges = 'rent_amount_changed_by_lease_charges',
	UserMidMonthChargeAdded = 'user_mid_month_charge_added',
	PaymentReschedulingStopped = 'payment_transactions_rescheduling_stopped',
	UserRiskScoreEligibilityOverride = 'user_risk_score_eligibility_override',
	EligibilityCheckPassedForRent = 'eligibility_check_passed_for_rent',

	// Onboarding events
	OnboardingSignUpEnd = 'onboarding_sign_up_end',
	OnboardingSignUpCreditBuilder = 'onboarding_sign_up_credit_builder',
	OnboardingEmailVerificationStart = 'onboarding_email_verification_start',
	OnboardingEmailVerificationEnd = 'onboarding_email_verification_end',
	OnboardingUserResidentInviteUserCreated = 'onboarding_resident_invite_user_created',

	OnboardingSmsVerificationStart = 'onboarding_sms_verification_start',
	OnboardingSmsVerificationEnd = 'onboarding_sms_verification_end',

	OnboardingAddressSelectionStart = 'onboarding_address_selection_start',
	OnboardingAddressSelectionReminder = 'onboarding_address_selection_reminder',
	OnboardingAddressSelectionReminderSkipped = 'onboarding_address_selection_reminder_skipped',
	OnboardingAddressSelectionEnd = 'onboarding_address_selection_end',
	RecommendedUnitsStart = 'recommended_units_start',

	OnboardingBankConnectionStart = 'onboarding_bank_connection_start',
	OnboardingBankConnectionReminder = 'onboarding_bank_connection_reminder',
	OnboardingBankConnectionEnd = 'onboarding_bank_connection_end',

	OnboardingCustomRentStart = 'onboarding_custom_rent_start',
	OnboardingCustomRentAmountSet = 'onboarding_custom_rent_amount_set',
	OnboardingCustomRentOneOffChargesEnabled = 'onboarding_custom_rent_one_off_charges_enabled',
	OnboardingRoommatesRentAmountConfirmed = 'onboarding_roommates_rent_amount_confirmed',
	OnboardingCustomRentDatesConfirmed = 'onboarding_custom_rent_dates_confirmed',
	OnboardingCustomRentReminder = 'onboarding_custom_rent_reminder',
	OnboardingCustomRentConfirmed = 'onboarding_custom_rent_confirmed',
	OnboardingCustomRentEnd = 'onboarding_custom_rent_end',
	OnboardingCustomRentWaitlistStart = 'onboarding_custom_rent_waitlist_start',
	OnboardingCustomRentWaitlistEnd = 'onboarding_custom_rent_waitlist_end',

	OnboardingCustomRentEarlyMoveInPaymentInfoStart = 'onboarding_custom_rent_early_move_in_payment_info_start',
	OnboardingCustomRentEarlyMoveInPaymentInfoConfirmed = 'onboarding_custom_rent_early_move_in_payment_info_confirmed',

	OnboardingCustomRentDirectDebitStart = 'onboarding_custom_rent_direct_debit_start',
	OnboardingCustomRentDirectDebitDeclined = 'onboarding_custom_rent_direct_debit_declined',
	OnboardingCustomRentDirectDebitSuccess = 'onboarding_custom_rent_direct_debit_success',
	OnboardingCustomRentDirectDebitSuccessConfirmed = 'onboarding_custom_rent_direct_debit_success_confirmed',

	OnboardingForceStopReminders = 'onboarding_force_stop_reminders',
	// CreditBuilder - line of credit
	OnboardingCreditBuilderLineOfCreditStart = 'onboarding_credit_builder_line_of_credit_start',
	OnboardingCreditBuilderLineOfCreditEnabled = 'onboarding_credit_builder_line_of_credit_enabled',
	OnboardingCreditBuilderLineOfCreditEnd = 'onboarding_credit_builder_line_of_credit_end',
	// CreditBuilder - rental
	OnboardingCreditBuilderRentalStart = 'onboarding_credit_builder_rental_start',
	OnboardingCreditBuilderRentalSubscriptionPeriodStart = 'onboarding_credit_builder_rental_subscription_period_start',
	OnboardingCreditBuilderRentalSubscriptionPeriodEnd = 'onboarding_credit_builder_rental_subscription_period_end',
	OnboardingCreditBuilderRentalEnabled = 'onboarding_credit_builder_rental_enabled',
	OnboardingCreditBuilderRentalConfirmed = 'onboarding_credit_builder_rental_confirmed',
	OnboardingCreditBuilderRentalEnd = 'onboarding_credit_builder_rental_end',
	OnboardingCreditBuilderPaymentReminder = 'onboarding_credit_builder_payment_reminder',
	// CreditBuilder - rent history
	OnboardingCreditBuilderRentalHistoryUpsellStart = 'onboarding_credit_builder_rental_history_upsell_start',
	OnboardingCreditBuilderRentalHistoryUpsellEnd = 'onboarding_credit_builder_rental_history_upsell_end',
	OnboardingCreditBuilderRentalHistoryUpsellNotEligible = 'onboarding_credit_builder_rental_history_upsell_not_eligible',
	// Onboarding done
	OnboardingSuccessEnd = 'onboarding_success_end',
	// Unified Payments
	OnboardingUnifiedPaymentsAddressConfirmed = 'onboarding_unified_payments_address_confirmed',
	OnboardingUnifiedPaymentsChangedToCustomRent = 'onboarding_unified_payments_changed_to_custom_rent',

	// Credit Boost (aka Secured Line of Credit)
	OnboardingCreditBoostStart = 'onboarding_credit_boost_start',
	OnboardingCreditBoostEnd = 'onboarding_credit_boost_end',
	OnboardingCreditBoostSelectPaymentMethod = 'onboarding_credit_boost_select_payment_method',
	OnboardingCreditBoostSecuredLineOfCreditStart = 'onboarding_credit_boost_secured_line_of_credit_start',
	OnboardingCreditBoostSecuredLineOfCreditConfirmed = 'onboarding_credit_boost_secured_line_of_credit_confirmed',
	OnboardingCreditBoostSecuredLineOfCreditEnd = 'onboarding_credit_boost_secured_line_of_credit_end',

	// ZumRails eTransfer app modal
	OnboardingETransferZumRailsNotificationStart = 'onboarding_etransfer_zumrails_notification_start',
	OnboardingETransferZumRailsNotificationEnd = 'onboarding_etransfer_zumrails_notification_end',

	// Support
	SupportPaymentDateChangeRequestCompleted = 'support_payment_date_change_request_completed',

	// Credit Reporting
	CreditReportingFirstNameChanged = 'credit_reporting_first_name_changed',
	CreditReportingMiddleNameChanged = 'credit_reporting_middle_name_changed',
	CreditReportingLastNameChanged = 'credit_reporting_last_name_changed',

	CreditReportingSubscriptionPaymentFailed = 'credit_reporting_subscription_payment_failed',
	CreditReportingSubscriptionPaymentSuccess = 'credit_reporting_subscription_payment_success',
	CreditReportingSubscriptionChanged = 'credit_reporting_subscription_changed',
	CreditReportingSubscriptionExpired = 'credit_reporting_subscription_expired',

	CreditBuilderHistoryUpsellPaymentSuccess = 'credit_builder_history_upsell_payment_success',
	CreditBuilderHistoryUpsellPaymentFailed = 'credit_builder_history_upsell_payment_failed',
	CreditBuilderHistoryUpsellPaymentRefunded = 'credit_builder_history_upsell_payment_refunded',
	CreditBuilderHistoryMatches = 'credit_builder_history_matches',

	// Others
	InstantBankConnectionResult = 'instant_bank_connection_result',
	UserLogin = 'user_login',
	PartnerUserInvited = 'partner_user_invited',
	PartnerAutomatedInvitesConfigurationChanged = 'partner_automated_invites_configuration_changed',
	PartnerUserRoleChanged = 'partner_user_role_changed',
	PartnerUserPropertiesChanged = 'partner_user_properties_changed',
	UserNoteCreated = 'user_note_created',
	UserNotificationCreated = 'user_notification_created',
	Note = 'note',
	LateUpfrontDeadlineConfirmed = 'late_upfront_deadline_confirmed',
	CustomRentTermsAccepted = 'custom_rent_terms_accepted',
	UserResponsibleForFullRentAmountSave = 'user_responsible_for_full_rent_amount_save',

	FlinksNightlyRefreshResult = 'flinks_nightly_refresh_result',
	FlinksItemCreated = 'flinks_item_created',
	FlinksItemEnabled = 'flinks_item_enabled',
	RentAmountChangedByAdmin = 'rent_amount_changed_by_admin',
	BankAccountVerifiedAndSetAsDefault = 'bank_account_verified_and_set_as_default',
	CreditBuilderRentReportingStatusChanged = 'credit_builder_rent_reporting_status_changed',
	CreditBuilderPaymentCreated = 'credit_builder_payment_created',
	CreditBuilderTradelineAutomaticallyClosed = 'credit_builder_tradeline_automatically_closed',
	CreditBuilderSubscriptionRenewed = 'credit_builder_subscription_renewed',

	StripePaymentCreationFailed = 'stripe_payment_creation_failed',
	StripePaymentCreated = 'stripe_payment_created',
	StripePaymentFailed = 'stripe_payment_failed',
	StripePaymentRefunded = 'stripe_payment_refunded',
	StripePaymentCompleted = 'stripe_payment_completed',

	MonerisPaymentFailed = 'moneris_payment_failed',

	DidYouMoveOutStart = 'did_you_move_out_start',
	DidYouMoveOutDismissed = 'did_you_move_out_dismissed',

	// credit builder indirect model
	ResidencyManuallyVerified = 'residency_manually_verified',
	HousingProviderEntered = 'housing_provider_entered',
	OnboardingHousingProviderStart = 'onboarding_housing_provider_start',
	OnboardingHousingProviderEnd = 'onboarding_housing_provider_end',
	OnboardingPaymentPortalStart = 'onboarding_payment_portal_start',
	OnboardingPaymentPortalEnd = 'onboarding_payment_portal_end',
	OnboardingLeaseAgreementUploadStart = 'onboarding_lease_agreement_upload_start',
	OnboardingLeaseAgreementUploadEnd = 'onboarding_lease_agreement_upload_end',
	RentPaymentConfirmation = 'rent_payment_confirmation',
	OnboardingRentPaymentConfirmationStart = 'rent_payment_confirmation_start',
	OnboardingRentPaymentConfirmationEnd = 'rent_payment_confirmation_end',
	OnboardingIndirectSelectRentAmountStart = 'onboarding_indirect_select_rent_amount_start',
	OnboardingIndirectSelectRentAmountEnd = 'onboarding_indirect_select_rent_amount_end',
	OnboardingIndirectLeaseDatesStart = 'onboarding_indirect_lease_dates_start',
	OnboardingIndirectLeaseDatesEnd = 'onboarding_indirect_lease_dates_end',
	IndirectSelectRentAmount = 'indirect_select_rent_amount',
	IndirectSelectLeaseDates = 'indirect_select_lease_dates',
	IndirectLeaseAgreementUploadReminder = 'indirect_lease_agreement_upload_reminder',
	ManualResidencyForCreditBuilderHistory = 'manual_residency_for_credit_builder_history',
	/** @deprecated */
	IndirectReconnectBankReminder = 'indirect_reconnect_bank_reminder',

	// bank account verification
	BankAccountVerificationStart = 'bank_account_verification_start',
	BankAccountVerificationAttempt = 'bank_account_verification_attempt',

	ClosedBankAccountDisconnected = 'bank_account_closed_disconnected',

	RentLeaseChargeDifferenceDetectedReminder = 'rent_lease_charge_difference_detected_reminder',

	// Referral partners
	ResidencyVerifiedFromReferralPartner = 'residency_verified_from_referral_partner',
	InvalidCardAccount = 'invalid_card_account',
	CardUnsetAsInstallmentMethod = 'card_unset_as_installment_method',

	RentHistoryUpsell = 'rent_history_upsell',
}

export const reminderFlows = [
	BusinessEventType.OnboardingAddressSelectionStart,
	BusinessEventType.OnboardingBankConnectionStart,
	BusinessEventType.OnboardingCustomRentStart,
	BusinessEventType.OnboardingCreditBuilderPaymentReminder,
	BusinessEventType.IndirectReconnectBankReminder,
	BusinessEventType.IndirectLeaseAgreementUploadReminder,
	BusinessEventType.RentLeaseChargeDifferenceDetectedReminder,
	BusinessEventType.RentHistoryUpsell,
] as const;

export type GetReminders<T extends BusinessEventType> = T extends `${any}_reminder` ? T : never;

export const eventToEndToReminder = {
	[BusinessEventType.OnboardingAddressSelectionEnd]: BusinessEventType.OnboardingAddressSelectionReminder,
	[BusinessEventType.OnboardingBankConnectionEnd]: BusinessEventType.OnboardingBankConnectionReminder,
	[BusinessEventType.OnboardingCustomRentEnd]: BusinessEventType.OnboardingCustomRentReminder,
	[BusinessEventType.OnboardingCreditBuilderRentalConfirmed]:
		BusinessEventType.OnboardingCreditBuilderPaymentReminder,
} as const;
