import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import arrowsSvg from 'url:../../../static/shapes/credit-builder-card-arrows.svg';
import negativeArrowSvg from 'url:../../../static/shapes/credit-builder-card-negative-arrow.svg';
import successSvg from 'url:../../../static/shapes/credit-builder-card-success-icon.svg';

import { CreditSubjectRentReportingStatus } from '../../../../../../functions/src/shared/credit-reporting';
import { isoDateToJsDate } from '../../../../../../functions/src/shared/iso-date';
import { PropertyManagerCreditBuilder } from '../../../../../../functions/src/shared/landlords';
import { RentMonth } from '../../../../../../functions/src/shared/rent-month';
import { ensureExhaustiveCheck, typedIncludes } from '../../../../../../functions/src/shared/utils';

import { formatRentMonthIntl } from '../../../../shared/components/formatted-rent-month';
import { RouterOutput, trpc } from '../../../../shared/trpc/client';

import { Button, Center, Text } from '../../../../base-ui/components';

import { useUserData } from '../../../contexts/user-data-context';
import { links } from '../../../router/paths';
import { rentHistoryStatusesAvailableForUpsell } from '../../../types/credit-builder-history-upsell.type';
import * as S from '../dashboard-card.styles';
import { creditBuilderErrorService, CreditBuilderErrorState } from '../profile-errors/credit-builder-error.service';
import { useCreditBuilderSetupIncomplete } from '../profile-errors/credit-builder-profile-errors';
import { CreditBuilderInactiveButton } from './credit-builder-card-inactive-button';

type CardType = 'active' | 'inactive' | 'upsell' | 'not-available' | 'active-history-upsell';
type CreditSubject = RouterOutput['creditReporting']['getUserInfo'];

function getCardType(input: {
	creditSubject?: CreditSubject | null;
	reportingActive: boolean;
	creditBuilder?: PropertyManagerCreditBuilder;
	errorState: CreditBuilderErrorState | null;
}): CardType {
	const { reportingActive, creditSubject, creditBuilder, errorState } = input;

	if (creditBuilder === PropertyManagerCreditBuilder.Disabled) {
		return 'not-available';
	}
	if (reportingActive) {
		if (typedIncludes(rentHistoryStatusesAvailableForUpsell, creditSubject?.rentHistoryUpsell)) {
			return 'active-history-upsell';
		}
		return 'active';
	}
	if (creditSubject?.rentReportingStatus === null) {
		return 'upsell';
	}
	if (errorState === 'indirect-last-month-of-lease') {
		return 'active';
	}
	return 'inactive';
}

export function CreditBuilderCard() {
	const { creditBuilderSetupIncomplete } = useCreditBuilderSetupIncomplete();
	const { data: creditSubject, isPending: creditSubjectLoading } = trpc.creditReporting.getUserInfo.useQuery();
	const { home } = useUserData();

	const errorState = creditBuilderErrorService.resolveErrorState({
		home,
		creditSubject,
		setupIncomplete: creditBuilderSetupIncomplete,
	});

	const hasOpenRentTradeline = Boolean(creditSubject?.hasOpenRentTradeline);
	const indirectVerificationPending = creditBuilderErrorService.indirectVerificationPending({
		userVerified: Boolean(home?.dashboard.userVerified),
		indirectModel: Boolean(home?.dashboard.indirectModel),
		leaseAgreementUploadPending: Boolean(creditSubject?.leaseAgreementUploadPending),
	});

	const reportingActive = Boolean(
		creditSubject?.rentReportingStatus === CreditSubjectRentReportingStatus.Active &&
			hasOpenRentTradeline &&
			errorState === null &&
			!indirectVerificationPending,
	);
	const landlordCreditBuilder = home?.landlord?.creditBuilder;

	const cardType = getCardType({
		creditSubject,
		reportingActive,
		creditBuilder: landlordCreditBuilder,
		errorState,
	});

	if (creditSubjectLoading) {
		return <S.Spinner $spinnerColor="green" />;
	}

	if (cardType === 'not-available' || !creditSubject) {
		return null;
	}

	return (
		<>
			<Text type="title-ternary">Rental Reporting</Text>
			<CreditBuilderCardType
				cardType={cardType}
				errorState={errorState}
				creditSubject={creditSubject}
				indirectVerificationPending={indirectVerificationPending}
			/>
		</>
	);
}

function CreditBuilderCardType({
	cardType,
	errorState,
	indirectVerificationPending,
	creditSubject,
}: {
	cardType: CardType;
	creditSubject: CreditSubject;
	errorState: CreditBuilderErrorState | null;
	indirectVerificationPending: boolean;
}) {
	switch (cardType) {
		case 'active-history-upsell':
		case 'active':
			return <CreditBuilderActive cardType={cardType} creditSubject={creditSubject} />;
		case 'upsell':
			return <CreditBuilderUpsell cardType={cardType} />;
		case 'inactive':
			return (
				<CreditBuilderInactive
					cardType={cardType}
					errorState={errorState}
					indirectVerificationPending={indirectVerificationPending}
				/>
			);
		case 'not-available':
			return null;
		default:
			ensureExhaustiveCheck(cardType);
	}
}

function CreditBuilderInactive({
	errorState,
	creditSubject,
	indirectVerificationPending,
	cardType,
}: {
	errorState: CreditBuilderErrorState | null;
	creditSubject?: CreditSubject;
	indirectVerificationPending: boolean;
	cardType: CardType;
}) {
	const navigate = useNavigate();

	const onClickLinkHandler = creditBuilderErrorService.getOnClickLinkHandler(navigate, { errorState, creditSubject });
	const hasOpenRentTradeline = Boolean(creditSubject?.hasOpenRentTradeline);

	if (hasOpenRentTradeline && !indirectVerificationPending && onClickLinkHandler === null) {
		return null;
	}

	return (
		<S.Card type="negative" data-testid="credit-builder-card-inactive">
			<S.OverlayImage type="negative" src={negativeArrowSvg} alt="negative-arrow" />
			<S.Content>
				<CardHeader cardType={cardType} />
				<S.NotReportingText>
					<b>
						<FormattedMessage
							defaultMessage="Your successful rent payments are currently not reported"
							id="credit-builder-card.not-reported"
						/>
					</b>
				</S.NotReportingText>
				<S.ButtonBox>
					<CreditBuilderInactiveButton
						hasOpenRentTradeline={hasOpenRentTradeline}
						onClickLinkHandler={onClickLinkHandler}
						indirectVerificationPending={indirectVerificationPending}
					/>
				</S.ButtonBox>
				<S.Footer />
			</S.Content>
		</S.Card>
	);
}

function CreditBuilderActive({ cardType, creditSubject }: { cardType: CardType; creditSubject: CreditSubject }) {
	const { data, isPending } = trpc.user.activeCreditBuilderStatistics.useQuery();
	const { data: creditBuilderHistoryRelatedInfoData, isPending: creditBuilderHistoryRelatedInfoIsPending } =
		trpc.creditReporting.creditBuilderHistoryRelatedInfo.useQuery();
	const intl = useIntl();

	const positivePaymentsCount = data?.statistics?.positivePaymentsCount ?? 0;
	const showAddMoreHistoryButton =
		creditBuilderHistoryRelatedInfoData &&
		creditBuilderHistoryRelatedInfoData.canAddMoreHistory &&
		!creditBuilderHistoryRelatedInfoData.manualResidencyForHistoryPendingReview?.id;
	return (
		<S.Card type="positive" data-testid="credit-builder-card-active">
			<S.OverlayImage type="positive" src={arrowsSvg} alt="arrows" />
			<S.Content>
				<CardHeader cardType={cardType} />
				{(isPending || creditBuilderHistoryRelatedInfoIsPending) && <S.Spinner $spinnerColor="white" />}
				{!isPending && (
					<>
						<S.PaymentCountText>
							<b>
								{positivePaymentsCount > 0 && (
									<FormattedMessage
										defaultMessage="{positivePaymentsCount} positive payments"
										id="credit-builder-card.positive-payments-reported"
										values={{
											positivePaymentsCount,
										}}
									/>
								)}
								{positivePaymentsCount === 0 && creditSubject?.rentHistoryUpsell !== 'enrolled' && (
									<FormattedMessage
										defaultMessage="Ready for positive payments"
										id="credit-builder-card.ready-for-positive-payments"
									/>
								)}
								{positivePaymentsCount === 0 && creditSubject?.rentHistoryUpsell === 'enrolled' && (
									<FormattedMessage
										defaultMessage="Ready for positive payments & rent history reporting"
										id="credit-builder-card.ready-for-positive-payments-and-history-reporting"
									/>
								)}
							</b>
						</S.PaymentCountText>
						{data?.statistics?.firstReportDate && (
							<S.StartDateText>
								<u>
									<FormattedMessage
										defaultMessage="Rent payments reported to Equifax since {startMonthYear}"
										id="credit-builder-card.reported-since"
										values={{
											startMonthYear: formatRentMonthIntl(
												intl,
												RentMonth.fromIsoDate(data.statistics.firstReportDate),
											),
										}}
									/>
								</u>
							</S.StartDateText>
						)}
					</>
				)}
				<S.Footer>
					{cardType === 'active-history-upsell' && (
						<Center style={{ marginBottom: '1rem' }}>
							<Button
								size="small"
								type="large-ternary"
								$maxWidth="fit-content"
								linkTo={links.ACCOUNT.CREDIT_BUILDER.HISTORY_REPORTING_UPSELL.ROOT}
							>
								<S.PaymentHistoryUpsellText>
									<FormattedMessage
										defaultMessage="Build credit faster with your rent history!"
										id="credit-builder-card.rent-history-upsell"
									/>
								</S.PaymentHistoryUpsellText>
							</Button>
						</Center>
					)}
					{showAddMoreHistoryButton ? (
						<Center style={{ marginBottom: '1rem' }}>
							<Button
								size="small"
								type="large-ternary"
								linkTo={links.ACCOUNT.CREDIT_BUILDER.PAST_RESIDENCY_DETAILS}
							>
								<FormattedMessage
									defaultMessage="Add payments prior to {startDate}"
									id="credit-builder-card.add-past-lease-details"
									values={{
										startDate: creditBuilderHistoryRelatedInfoData?.dateOpened ? (
											<FormattedDate
												value={isoDateToJsDate(creditBuilderHistoryRelatedInfoData.dateOpened)}
												month="short"
												year="2-digit"
											/>
										) : (
											intl.formatMessage({
												defaultMessage: 'your lease start date',
												id: 'credit-builder-card.add-past-lease-details-start-date-fallback',
											})
										),
									}}
								/>
							</Button>
						</Center>
					) : null}
				</S.Footer>
			</S.Content>
		</S.Card>
	);
}

function CardHeader({ cardType }: { cardType: CardType }) {
	return (
		<S.Header>
			<S.Title>
				<b>CreditBuilder</b>
			</S.Title>
			{cardType === 'active' || cardType === 'active-history-upsell' ? (
				<S.Reporting>
					<FormattedMessage defaultMessage="Reporting" id="credit-builder-card.reporting" />
					<S.CheckIcon src={successSvg} alt="checkmark" />
				</S.Reporting>
			) : null}
		</S.Header>
	);
}

function CreditBuilderUpsell({ cardType }: { cardType: CardType }) {
	const navigate = useNavigate();
	return (
		<S.Card type="positive" data-testid="credit-builder-card-upsell">
			<S.OverlayImage type="positive" src={arrowsSvg} alt="arrows" />
			<S.Content>
				<CardHeader cardType={cardType} />
				<S.NotReportingText>
					<b>
						<FormattedMessage
							defaultMessage="Build credit by reporting the rent payments you're already making"
							id="credit-builder-card.upsell"
						/>
					</b>
				</S.NotReportingText>
				<S.ButtonBox>
					<Button type="large-ternary" onClick={() => navigate(links.ACCOUNT.CREDIT_BUILDER.SETUP)}>
						<FormattedMessage
							defaultMessage="Start building credit"
							id="credit-builder-card.start-building-credit"
						/>
					</Button>
				</S.ButtonBox>
				<S.Footer />
			</S.Content>
		</S.Card>
	);
}
