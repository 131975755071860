import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';

import { ensureExhaustiveCheck } from '../../../../functions/src/shared/utils';

import { Box, Button, Radio, RadioGroup, Spacing, Text } from '../../base-ui/components';

import { BackButton } from '../components/back-button';
import { links } from '../router/paths';

const RadioGroupColumn = styled(RadioGroup)`
	grid-template-columns: 1fr;
`;

export function CreditBuilderIndirectLastMonthOfLease() {
	const intl = useIntl();
	const navigate = useNavigate();
	const schema = z
		.object({
			selection: z.enum(['new-address', 'renewal']).or(z.null()),
		})
		.refine(
			(data) => {
				return data.selection !== null;
			},
			{
				path: ['selection'],
				message: intl.formatMessage({
					defaultMessage: 'Please choose one of the above options',
					id: 'common.choose-one-of-the-above',
				}),
			},
		);
	const form = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		defaultValues: { selection: null },
	});

	function handleSubmit(data: z.infer<typeof schema>) {
		if (!data.selection) {
			return;
		}

		switch (data.selection) {
			case 'new-address':
				return navigate(links.ACCOUNT.RESIDENCY.SELECT_PROPERTY);
			case 'renewal':
				return navigate(links.ACCOUNT.UPLOAD_LEASE_AGREEMENTS, { state: { isLeaseRenewal: true } });
			default:
				ensureExhaustiveCheck(data.selection);
		}
	}

	return (
		<>
			<BackButton />
			<Text type="title" color="title">
				<FormattedMessage defaultMessage="Confirm the following" id="indirect-last-month-of-lease.title" />
			</Text>

			<Spacing $size="m" />
			<Text type="body">
				<FormattedMessage
					defaultMessage="Please select the one that resonates the most with you."
					id="indirect-last-month-of-lease.body-text"
				/>
			</Text>

			<Spacing $size="m" />
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)}>
					<RadioGroupColumn name="selection">
						<Radio
							paper
							{...form.register('selection')}
							value="new-address"
							key="new-address"
							label={
								<FormattedMessage
									defaultMessage="I have a new address"
									id="indirect-last-month-of-lease.i-have-a-new-address"
								/>
							}
						/>
						<Radio
							paper
							{...form.register('selection')}
							value="renewal"
							key="renewal"
							label={
								<FormattedMessage
									defaultMessage="I renewed my lease at the same address"
									id="indirect-last-month-of-lease.i-renewed-my-lease-at-the-same-address"
								/>
							}
						/>
					</RadioGroupColumn>
					<Box justify="center">
						<Button htmlType="submit">
							<FormattedMessage defaultMessage="Continue" id="common.continue" />
						</Button>
					</Box>
				</form>
			</FormProvider>
		</>
	);
}
