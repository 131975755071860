import React, { HTMLInputTypeAttribute, useState } from 'react';
import styled from 'styled-components';

import { Icon } from './icon/icon';
import { Input, InputProps } from './input/input';

export const ShowPassword = styled.button`
	position: absolute;
	right: 2rem;
	top: 3rem;
	border: none;
	background: none;
	@media (max-width: 450px) {
		right: 1rem;
		top: 2.5rem;
	}
`;

export const PasswordInput = (props: InputProps) => {
	const { type = 'password' } = props;

	const [passwordShown, setPasswordShown] = useState(false);

	const passInputType = (inputType: HTMLInputTypeAttribute) => {
		if (inputType == 'password') {
			return passwordShown ? 'text' : 'password';
		}

		return inputType;
	};

	return (
		<Input {...props} type={passInputType(type)}>
			<ShowPassword aria-hidden type="button" onClick={() => setPasswordShown(!passwordShown)}>
				<Icon icon={passwordShown ? 'EyeOpen' : 'EyeClosed'} />
			</ShowPassword>
		</Input>
	);
};
