import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Box, Spacing } from '../../../base-ui/components';

import { OnboardingCloseButton } from '../../components/onboarding-close-button';
import { useUserData } from '../../contexts/user-data-context';
import { links } from '../../router/paths';
import {
	CreditReportingCustom,
	CreditReportingLineOfCreditSignUpShared,
	EquifaxBubbleCustom,
} from '../../templates/credit-reporting-line-of-credit';
import { OnboardingSuccess } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { PageVariant, usePageLinks } from '../use-page-links.hook';

export function CreditBoostSecuredLineOfCreditWrapper({ variant }: { variant: PageVariant }) {
	const { hasCreditBoostSecuredLineOfCreditTermsAccepted } = useUserData();
	const pageLinks = usePageLinks('credit-boost.secured-line-of-credit');
	if (hasCreditBoostSecuredLineOfCreditTermsAccepted) {
		return <Navigate to={pageLinks.onSecuredLineOfCreditAlreadyAccepted({ variant })} />;
	}
	return <Outlet />;
}

export function CreditBoostSecuredLineOfCreditConfirm({ variant }: { variant: PageVariant }) {
	const pageLinks = usePageLinks('credit-boost.secured-line-of-credit');
	return (
		<CreditReportingLineOfCreditSignUpShared
			pageLinks={pageLinks}
			variant={variant}
			signingUpFor="secured-line-of-credit"
		/>
	);
}

export function CreditBoostSecuredLineOfCredit({ variant }: { variant: PageVariant }) {
	const trackOnboardingEvent = useTrackOnboardingEvent();
	const navigate = useNavigate();
	// TODO: creditboost onboarding wrapper
	useEffect(
		() => trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingCreditBoostSecuredLineOfCreditStart }),
		[],
	);
	const pageLinks = usePageLinks('credit-boost.secured-line-of-credit');

	const intl = useIntl();
	const { home } = useUserData();

	const landlordName = home?.landlord?.propertyManagerName;

	return (
		<>
			<OnboardingCloseButton to={links.ACCOUNT.DASHBOARD} stopOnboardingReminders />
			<Box isColumn verticalCenter>
				<CreditReportingCustom />
				<Spacing $size="xl" />

				<OnboardingSuccess
					title={intl.formatMessage({
						defaultMessage: 'Get credit for your rent payments',
						id: 'credit-boost.get-credit-for-rent-payments',
					})}
					subtitle={
						<FormattedMessage
							defaultMessage="Thanks to {landlordName}, you can now build credit by reporting your Zenbase payments to Equifax. We don't check credit but we help build credit."
							id="credit-boost.thanks-to-landlord"
							values={{ landlordName }}
						/>
					}
					beforeCta={
						<>
							<EquifaxBubbleCustom />
							<Spacing $size="xl" />
						</>
					}
					ctaTestId="credit-reporting-loc-continue-button"
					cta={intl.formatMessage({ defaultMessage: 'Continue', id: 'common.continue' })}
					onNext={() => navigate(pageLinks.onNext({ variant }))}
					footerStickyOnMobile
					backButton={false}
				/>
			</Box>
		</>
	);
}
