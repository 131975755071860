import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import payToZenbase from 'url:../../static/shapes/pay-to-zenbase.png';

import { HighlightTeal } from '../../components/highlighted';
import { links } from '../../router/paths';
import { OnboardingSuccess } from '../../templates/onboarding-success';

export const CustomRentOnboardingSuccess2 = () => {
	const navigate = useNavigate();

	const handleNext = () => {
		navigate(links.REGISTRATION.ONBOARDING_SUCCESS.RESCHEDULE);
	};

	return (
		<OnboardingSuccess
			imageSrc={payToZenbase}
			title={
				<FormattedMessage
					defaultMessage="Don't need to make any payments directly to your landlord"
					id="custom-rent-onboarding-success2.title"
				/>
			}
			subtitle={
				<FormattedMessage
					defaultMessage="Your landlord will be <color>paid in full by Zenbase</color> so you don't need to worry about making any payments to your landlord."
					id="custom-rent-onboarding-success2.subtitle"
					values={{ HighlightTeal }}
				/>
			}
			ctaTestId="onboarding-success-cr-step2-continue-button"
			onNext={handleNext}
		/>
	);
};
