import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router-dom';

import { Variant } from '../contexts/bank-onboarding-context/bank-onboarding-context';
import {
	CustomRentOnboardingAwaitingTransactions,
	CustomRentOnboardingCannotValidateEarnings,
	CustomRentOnboardingConnectBank,
	EnterBankDetails,
	StartVerification,
} from '../pages';
import { MicroDepositPending } from '../pages/manual-bank-onboarding/micro-deposit-pending';
import { NavigateParams } from '../utils/react-router';
import { links, paths } from './paths';
import { BankOnboardingWrapper } from './route-wrappers';

export const BankConnectionRoutes = ({
	links: l,
	paths: p,
	onCompletedNavigateParams,
	variant,
}: {
	links: typeof links.REGISTRATION.BANK;
	paths: typeof paths.REGISTRATION.BANK;
	onCompletedNavigateParams?: NavigateParams;
	variant: Variant;
}) => {
	return (
		<Routes>
			<Route
				element={
					<BankOnboardingWrapper
						links={l}
						onCompletedNavigateParams={onCompletedNavigateParams}
						variant={variant}
					/>
				}
			>
				<Route path={p.CONNECT_BANK} element={<CustomRentOnboardingConnectBank />} />
				<Route
					path={p.AWAITING_TRANSACTIONS}
					element={
						<CustomRentOnboardingAwaitingTransactions
							titleText={
								<FormattedMessage
									defaultMessage="Your account is almost set up"
									id="awaiting-transactions-title"
								/>
							}
							connectAgainLink={l.CONNECT_BANK}
							connectManuallyLink={l.MANUAL_ENTER_BANK_DETAILS}
						/>
					}
				/>
				<Route
					path={p.CANNOT_VALIDATE_EARNINGS}
					element={<CustomRentOnboardingCannotValidateEarnings reconnectBankLink={l.CONNECT_BANK} />}
				/>

				<Route path={p.MANUAL_ENTER_BANK_DETAILS} element={<EnterBankDetails />} />
				<Route path={p.MANUAL_START_VERIFICATION} element={<StartVerification />} />
				<Route path={p.MANUAL_PENDING_VERIFICATION} element={<MicroDepositPending />} />
			</Route>
		</Routes>
	);
};
