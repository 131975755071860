import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { CreditSubjectRentReportingStatus } from '../../../../functions/src/shared/credit-reporting';
import { ensureExhaustiveCheck } from '../../../../functions/src/shared/utils';

import { Anchor, Modal } from '../../base-ui/components';

import { useInterface } from '../contexts/hooks';
import { useUserData } from '../contexts/user-data-context';
import { links } from '../router/paths';

const CreditBuilderRentalDialog = ({
	onClose,
	rentReportingStatus,
}: {
	onClose: () => void;
	rentReportingStatus: CreditSubjectRentReportingStatus | null;
}) => {
	const navigate = useNavigate();
	const { formatMessage } = useIntl();

	let content: {
		headerText: React.ReactNode;
		contentText: React.ReactNode;
		canEnable: boolean;
		buttonText: React.ReactNode;
	};
	switch (rentReportingStatus) {
		// consider enabled
		case CreditSubjectRentReportingStatus.Active:
		case CreditSubjectRentReportingStatus.Unpaid:
			content = {
				canEnable: false,
				headerText: formatMessage({
					id: 'credit-builder-rental-dialog.deactivate-header',
					defaultMessage: 'Would you like to deactivate CreditBuilder?',
				}),
				contentText: (
					<FormattedMessage
						id="credit-builder-rental-dialog.deactivate-content"
						defaultMessage="Please email {supportEmail} to deactivate CreditBuilder."
						values={{
							supportEmail: <Anchor href="mailto:support@myzenbase.com">support@myzenbase.com</Anchor>,
						}}
					/>
				),
				buttonText: formatMessage({ id: 'common.ok', defaultMessage: 'Ok' }),
			};
			break;
		// consider not enabled
		case CreditSubjectRentReportingStatus.NotConfirmed:
		case null:
			content = {
				canEnable: true,
				headerText: formatMessage({
					id: 'credit-builder-rental-dialog.activate-header',
					defaultMessage: 'Would you like to activate CreditBuilder?',
				}),
				contentText: (
					<FormattedMessage
						id="credit-builder-rental-dialog.activate-content"
						defaultMessage="We will be automatically reporting your rental payments to Equifax to build credit."
					/>
				),
				buttonText: formatMessage({ id: 'common.yes', defaultMessage: 'Yes' }),
			};
			break;

		// consider deactived
		case CreditSubjectRentReportingStatus.DisabledByUser:
		case CreditSubjectRentReportingStatus.Disabled:
			content = {
				canEnable: false,
				headerText: formatMessage({
					id: 'credit-bulder-rental-dialog.credit-builder-deactivated-header',
					defaultMessage: 'CreditBuilder is deactivated',
				}),
				contentText: (
					<FormattedMessage
						id="credit-builder-rental-dialog.credit-builder-deactivated-content"
						defaultMessage="Unfortunately because you canceled CreditBuilder you will not be able to reactivate the service until your lease renewal date."
					/>
				),
				buttonText: formatMessage({ id: 'common.ok', defaultMessage: 'Ok' }),
			};
			break;

		default:
			ensureExhaustiveCheck(rentReportingStatus);
	}

	return (
		<Modal
			type="configuration"
			headerText={content.headerText}
			contentText={content.contentText}
			buttonText={content.buttonText}
			buttonHandler={() => {
				onClose();

				if (content.canEnable) {
					navigate(links.ACCOUNT.CREDIT_BUILDER.SETUP);
				}
			}}
			altButtonText={formatMessage({
				id: 'common.cancel',
				defaultMessage: 'Cancel',
			})}
			altButtonHandler={onClose}
		/>
	);
};

export const useCreditBuilderRentalDialog = () => {
	const { home } = useUserData();
	const { setModal, closeModal } = useInterface();

	const creditBuilderRental = home?.creditSubject?.rentReportingStatus;

	const creditBuilderRentalDialog = () => {
		setModal(
			<CreditBuilderRentalDialog
				onClose={() => closeModal()}
				rentReportingStatus={creditBuilderRental ?? null}
			/>,
		);
	};

	return creditBuilderRentalDialog;
};
