import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { trpc } from '../../../shared/trpc/client';

import { Modal } from '../../../base-ui/components';

import { useInterface } from '../../contexts/hooks';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { PageVariant, usePageLinks } from '../use-page-links.hook';

export const CustomRentDirectDebitCardCancelModal = () => {
	const pageLinks = usePageLinks('direct-debit.card-cancel-modal');
	const navigate = useNavigate();
	const { closeModal } = useInterface();

	const trackEvent = useTrackOnboardingEvent();

	const utils = trpc.useUtils();

	const handleDeclineDebitCardPayments = () => {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitDeclined },
			{
				onSuccess() {
					utils.user.home.invalidate();
					utils.user.listOnboardingEvents.invalidate();
					closeModal();
					navigate(pageLinks.onCancelSuccess());
				},
			},
		);
	};

	return <DirectDebitCardCancelModal handleDeclineDebitCardPayments={handleDeclineDebitCardPayments} />;
};

export const CreditBoostDirectDebitCardCancelModal = ({ variant }: { variant: PageVariant }) => {
	const pageLinks = usePageLinks('credit-boost.direct-debit.cancel-modal');
	const navigate = useNavigate();
	const { closeModal } = useInterface();

	const handleDeclineDebitCardPayments = () => {
		closeModal();
		navigate(pageLinks.onDeclineDebitCardPayments({ variant }));
	};

	return <DirectDebitCardCancelModal handleDeclineDebitCardPayments={handleDeclineDebitCardPayments} />;
};

type Props = {
	handleDeclineDebitCardPayments: () => void;
};
const DirectDebitCardCancelModal = ({ handleDeclineDebitCardPayments }: Props) => {
	const trackEvent = useTrackOnboardingEvent();
	const intl = useIntl();
	const { closeModal } = useInterface();

	return (
		<Modal
			type="warning"
			headerText={intl.formatMessage({
				defaultMessage: 'Are you sure that you don’t need to make debit card payments?',
				id: 'debit-card-cancel-modal.are-you-sure',
			})}
			contentText={intl.formatMessage({
				defaultMessage:
					'Debit cards offer instant and convenient transactions, which can avoid late fees and NSF fees charged by banks.',
				id: 'debit-card-cancel-modal.content',
			})}
			buttonText={intl.formatMessage({
				defaultMessage: 'Set up debit card payments',
				id: 'debit-card-cancel-modal.ok',
			})}
			buttonHandler={closeModal}
			buttonTestId="custom-rent-setup-modal-cancel-button"
			altButtonTestId="custom-rent-setup-modal-decline-button"
			altButtonText={intl.formatMessage({
				defaultMessage: 'No, don’t set up debit card payments',
				id: 'debit-card-cancel-modal.cancel',
			})}
			altButtonDisabled={trackEvent.isPending}
			altButtonHandler={handleDeclineDebitCardPayments}
		/>
	);
};
