import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import z from 'zod';

import { triggerChatWidget } from '../../../shared/zendesk';

import {
	Alert,
	AlertText,
	AlertTitle,
	Anchor,
	Button,
	Footer,
	FormInput,
	Spacing,
	Text,
} from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { SignInMethods, useFirebase } from '../../contexts/firebase';
import { firebaseErrors } from '../../contexts/firebase/firebase-api';
import { links } from '../../router/paths';
import { useAsyncHandler } from '../../utils/hooks/use-async-handler';

const schema = z.object({
	email: z.string().max(50).trim().email(),
	password: z.string().min(6).max(30),
});

type Schema = z.infer<typeof schema>;

export const SignIn = () => {
	const { state } = useLocation();

	const { handlers } = useFirebase();
	const intl = useIntl();
	const [error, setError] = useState<unknown>();

	const [handleSignIn, { loading }] = useAsyncHandler(handlers.handleSignIn);

	const form = useForm<Schema>({
		resolver: zodResolver(schema),
	});

	const handleSubmit = ({ email, password }: Schema) => {
		setError(undefined);

		handleSignIn({ method: SignInMethods.email, email, password }).catch((pError) => {
			if (pError?.code === firebaseErrors.ERROR_CODE_EMAIL_NOT_FOUND) {
				return form.setError('email', {
					message: intl.formatMessage({
						defaultMessage: 'Email not found',
						id: 'sign-in.email-not-found',
					}),
				});
			}

			if (pError?.code === firebaseErrors.ERROR_CODE_INVALID_PASSWORD) {
				return form.setError('password', {
					message: intl.formatMessage({
						defaultMessage: 'Invalid password',
						id: 'sign-in.invalid-password',
					}),
				});
			}

			setError(pError?.code || pError);
		});
	};

	const { originalLocation } = (state || {}) as { originalLocation?: Location };
	const passwordResetSuccess = originalLocation?.pathname === links.DEFAULT.RESET_PASSWORD;

	return (
		<>
			<BackButton testId="sign-in-back-button" to={links.DEFAULT.ROOT} />
			<Text type="title" testId="sign-in-title">
				<FormattedMessage defaultMessage="Sign In" id="common.sign-in" />
			</Text>

			<Spacing $size="s" />
			<Text>
				<FormattedMessage
					defaultMessage="Please enter your email & password."
					id="sign-in.enter-email-password"
				/>
			</Text>
			<Spacing $size="m" />

			{passwordResetSuccess && (
				<Text>
					<FormattedMessage
						defaultMessage="Password reset was successful. You can sign in with your new password."
						id="sign-in.password-reset-success"
					/>
				</Text>
			)}

			{!!error && (
				<Alert>
					<AlertTitle>
						<FormattedMessage defaultMessage="An error ocurred" id="alert-error.an-error-occurred" />
					</AlertTitle>
					<AlertText>
						{error === firebaseErrors.ERROR_CODE_TOO_MANY_REQUEST ? (
							<>
								<FormattedMessage
									defaultMessage="Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
									id="sign-in.too-many-requests"
								/>
							</>
						) : error === firebaseErrors.ERROR_CODE_USER_DISABLED ? (
							<>
								<FormattedMessage
									defaultMessage="Your account has been deactivated. Please {contactLink} to reactivate your account."
									id="sign-in.user-disabled"
									values={{
										contactLink: (
											<Anchor onClick={triggerChatWidget}>
												<FormattedMessage
													defaultMessage="contact Zenbase support"
													id="sign-in.contact-support"
												/>
											</Anchor>
										),
									}}
								/>
							</>
						) : (
							<FormattedMessage
								defaultMessage="Something went wrong at our end. Don't worry, we'll fix soon."
								id="forgot-password.error-text"
							/>
						)}
					</AlertText>
				</Alert>
			)}

			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)}>
					<FormInput
						name="email"
						placeholder={intl.formatMessage({
							defaultMessage: 'Email',
							id: 'forgot-password.email-placeholder',
						})}
						testId="sign-in-input-email"
					/>
					<FormInput
						name="password"
						placeholder={intl.formatMessage({
							defaultMessage: 'Password',
							id: 'payment-portal-connect-password-placeholder',
						})}
						type="password"
						testId="sign-in-input-password"
					/>

					<Footer>
						<Button htmlType="submit" testId="sign-in-button-continue" disabled={loading}>
							<FormattedMessage defaultMessage="Sign In" id="common.sign-in" />
						</Button>
						<Button type="link" linkTo="/forgot-password" testId="sign-in-button-forgot-password">
							<FormattedMessage
								defaultMessage="Forgot your password?"
								id="sign-in.button-forgot-password"
							/>
						</Button>
					</Footer>
				</form>
			</FormProvider>
		</>
	);
};
