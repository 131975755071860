import { addBusinessDays, setDate } from 'date-fns';

import { RentMonth } from '../../../../functions/src/shared/rent-month';
import {
	calculateDefaultBlockingAmountCents,
	calculateFeeCents,
	CustomRentConfigForMonth,
} from '../../../../functions/src/shared/user-rent';

export const getInstallmentData = (index: 0 | 1, config: CustomRentConfigForMonth) => {
	const dayOfMonth = config.installments[index]?.dayOfMonth;
	const date = setDate(RentMonth.fromDbParams(config.rentMonth).firstDayAsDate(), dayOfMonth);

	const isLastInstallment = index === config.installments.length - 1;
	const feeCents =
		isLastInstallment && !config.hasFreeRentPromoCode
			? calculateFeeCents({
					...config,
					blockingPaymentAmountCents: calculateDefaultBlockingAmountCents({
						rentAmountCents: config.rentAmountCents,
						featureId: config.featureId,
					}),
				})
			: 0;

	return {
		date,
		amount: (config.installments[index]?.installmentAmountCents + feeCents) / 100,
		includeFee: feeCents > 0,
	};
};

export const updateConfigInstallmentDate = (
	config: CustomRentConfigForMonth,
	installment: 0 | 1,
	selectedDay: Date,
) => {
	const updated: CustomRentConfigForMonth = JSON.parse(JSON.stringify(config));

	const installmentToUpdate = updated.installments[installment];
	if (installmentToUpdate) {
		installmentToUpdate.dayOfMonth = selectedDay.getDate();
	}

	// merge if same date on both installments
	if (
		updated.installments.length === 2 &&
		updated.installments[0].dayOfMonth === updated.installments[1].dayOfMonth
	) {
		updated.installments = [
			{
				dayOfMonth: updated.installments[0].dayOfMonth,
				installmentAmountCents: updated.rentAmountCents,
			},
		];
	}

	return updated;
};

export const isOneInstallment = (config: CustomRentConfigForMonth) => config.installments.length === 1;

export const splitInstallment = (config: CustomRentConfigForMonth, firstDate: Date): CustomRentConfigForMonth => {
	const firstInstallmentAmountCents = Math.floor(config.rentAmountCents / 2);

	return {
		...config,
		installments: [
			{
				installmentAmountCents: firstInstallmentAmountCents,
				dayOfMonth: firstDate.getDate(),
			},
			{
				installmentAmountCents: config.rentAmountCents - firstInstallmentAmountCents,
				dayOfMonth: addBusinessDays(firstDate, 10).getDate(),
			},
		],
	};
};
