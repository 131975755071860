import React, { ForwardedRef, forwardRef } from 'react';

import { Radio, RadioProps } from './radio';
import { ErrorMessage, Wrapper } from './styles';

type Props = { errorMessage?: string; testId?: string } & RadioProps;

export const RadioCheckbox = forwardRef(
	({ errorMessage, testId, ...radioProps }: Props, ref: ForwardedRef<HTMLInputElement>) => {
		return (
			<Wrapper>
				<Radio type="checkbox" ref={ref} {...radioProps} testId={testId} />
				{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
			</Wrapper>
		);
	},
);

RadioCheckbox.displayName = 'RadioCheckbox';
