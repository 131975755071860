import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Button } from '../../../base-ui/components';

import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

type Props = {
	onNotNowLink: string;
};
export function CreditBoostNotNowButton({ onNotNowLink }: Props) {
	const trackEvent = useTrackOnboardingEvent();
	const navigate = useNavigate();
	const onClick = () => {
		trackEvent.mutate(
			{
				type: BusinessEventType.OnboardingCreditBoostEnd,
			},
			{
				onSuccess() {
					navigate(onNotNowLink);
				},
			},
		);
	};
	return (
		<Button onClick={onClick} type="link" testId="credit-boost-not-now-button">
			<FormattedMessage defaultMessage="Not now" id="credit-boost-not-now-button.not-now" />
		</Button>
	);
}
