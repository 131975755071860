// "import type" here, because technically these are backend deps that should not be available in frontend code
import type { DecodedIdToken } from 'firebase-admin/lib/auth/token-verifier';
import type { ParsedToken } from 'firebase/auth';

import { createEnum } from '../utils/create-enum';
import { UserClaims } from './user';

export function partnerIsAuthorized(claims: UserClaims | ParsedToken | DecodedIdToken | undefined) {
	return Boolean(claims?.admin || claims?.partner);
}

export enum InviteType {
	Partner = 'partner',
	PartnerAutomated = 'partner_automated',
	/** @deprecated */
	PartnerUnifiedPayments = 'partner_unified_payments',
	CreditBuilderHandover = 'credit_builder_handover',
	PartnerMicrosoftSso = 'partner_microsoft_sso',
}

export type PartnerInviteType = InviteType.Partner | InviteType.PartnerAutomated | InviteType.PartnerUnifiedPayments;

export const PartnerPortalLoginType = createEnum('microsoft_sso', 'email_password');
export type PartnerPortalLoginType = createEnum<typeof PartnerPortalLoginType>;

export enum RecommendedMethods {
	New = 'new',
	Renewal = 'renewal',
	LatePayment = 'late_payment',
	NsfPayment = 'nsf_payment',
}

export const ALL_RECOMMENDED_METHODS = [
	RecommendedMethods.New,
	RecommendedMethods.Renewal,
	RecommendedMethods.LatePayment,
	RecommendedMethods.NsfPayment,
];

export type PartnerMemberInvitationFeatures = 'customRentOnly' | 'withCreditBuilder';

export const LATE_BALANCE_THRESHOLD_CENTS = 500_00;
export const LATE_BALANCE_NTH_BUSINESS_DAY = 5;
