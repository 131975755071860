import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import congrats from 'url:../../static/shapes/congrats.png';
import learning from 'url:../../static/shapes/learning.png';

import { publicConfig } from '../../../../../functions/src/shared/config';
import { RentMonth } from '../../../../../functions/src/shared/rent-month';
import { FeatureType } from '../../../../../functions/src/shared/user';
import { calculateFeeCents, CustomRentConfigForMonth } from '../../../../../functions/src/shared/user-rent';
import { notNullish } from '../../../../../functions/src/shared/utils';

import { FormattedCents } from '../../../shared/components/formatted-cents';
import { trpc } from '../../../shared/trpc/client';

import { Button, Footer, Spacing, Text } from '../../../base-ui/components';
import { UnexpectedErrorAlert } from '../../../base-ui/components/alert/alert';

import { AlertError } from '../../components/alert-error';
import { Confirm, ConfirmList } from '../../components/confirm';
import { ImgPreload } from '../../components/img-preload';
import { OnboardingCloseButton } from '../../components/onboarding-close-button';
import { useInterface } from '../../contexts/hooks';
import { links } from '../../router/paths';
import { ConfirmCreditBoostCannotEnrollForMonth } from '../../templates/confirm-custom-rent-cannot-enroll-for-month';
import { getCustomRentStartRentMonth } from '../../utils/get-custom-rent-start-month';
import { PageVariant, usePageLinks } from '../use-page-links.hook';

// NOTE: This is a lot of copy pasta from the CustomRent terms page
type CreditBoostConfirmTermsProps = {
	variant: PageVariant;
};
export function CreditBoostConfirmTerms({ variant }: CreditBoostConfirmTermsProps) {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.confirm-terms');

	const { setModal, closeModal } = useInterface();

	const utils = trpc.useUtils();
	const getConfig = trpc.user.customRent.getConfig.useQuery(
		{
			featureId: FeatureType.CreditBoost,
		},
		{
			// reuse config loaded by previous steps - mutations will cache the new one
			staleTime: Infinity,
		},
	);
	const { data: home } = trpc.user.home.useQuery(undefined);

	const confirmConfig = trpc.user.customRent.confirmConfig.useMutation({});

	// user has no config at all
	if (getConfig.data === null) {
		return <Navigate to={pageLinks.onMissingConfig({ variant })} replace />;
	}

	if (getConfig.isLoading) {
		return null;
	}
	if (getConfig.isError || getConfig.data === undefined) {
		return <UnexpectedErrorAlert />;
	}

	const creditBoostStartRentMonth = getCustomRentStartRentMonth(getConfig.data, home);
	const config = { ...getConfig.data, rentMonth: creditBoostStartRentMonth.asDbParams() };

	const onConfirmConfig = (rentMonth: RentMonth) => {
		if (!config) return;

		confirmConfig.mutate(
			{ rentMonth: rentMonth.asDbParams(), featureId: FeatureType.CreditBoost },
			{
				onSuccess: () => {
					utils.invalidate();
					navigate(pageLinks.onConfirmed({ variant }), { replace: true });
				},
				onError(err) {
					if (err.shape?.message.includes(publicConfig.errorMessages.cannotEnrollForMonth)) {
						setModal(
							<ConfirmCreditBoostCannotEnrollForMonth
								rentMonth={rentMonth}
								onContinue={() => {
									closeModal();
									onConfirmConfig(rentMonth.next());
								}}
							/>,
						);
					}
				},
			},
		);
	};

	return (
		<>
			{config && <ConfirmCreditBoostContent config={config} />}
			<Spacing $size="xl" />

			{confirmConfig.isError &&
				!confirmConfig.error.shape?.message.includes(publicConfig.errorMessages.cannotEnrollForMonth) && (
					<AlertError />
				)}

			<Footer stickyOnMobile>
				<Button
					disabled={!config || confirmConfig.isPending}
					onClick={() => onConfirmConfig(creditBoostStartRentMonth)}
					testId="confirm-credit-boost-confirm-button"
				>
					<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
				</Button>
			</Footer>

			<ImgPreload src={congrats} />
			<ImgPreload src={learning} />
		</>
	);
}

type Props = {
	config: CustomRentConfigForMonth;
};

function ConfirmCreditBoostContent({ config }: Props) {
	return (
		<>
			<OnboardingCloseButton to={links.ACCOUNT.DASHBOARD} stopOnboardingReminders />
			<Text type="title">
				<FormattedMessage id="credit-boost.payment-terms-title" defaultMessage="Confirm your payment terms" />
			</Text>
			<Spacing $size="l" />

			<Text>
				<FormattedMessage
					id="credit-boost.confirm-details"
					defaultMessage="Almost set! Please confirm the details below."
				/>
			</Text>
			<Spacing $size="l" />

			<Confirm />
			<Spacing $size="l" />

			<ConfirmList
				items={[
					{
						label: (
							<>
								<FormattedMessage
									id="credit-boost.schedule-start"
									defaultMessage="Your rent payment is due on the 1st of the month."
								/>
							</>
						),
					},
					{
						label: (
							<FormattedMessage
								id="credit-boost.covered-by-sloc"
								defaultMessage="Your payment will be covered with the secured line of credit."
							/>
						),
					},
					{
						label: (
							<FormattedMessage
								id="credit-boost.payment-method-fees"
								defaultMessage="Your rent payment will include any processing fees in case they apply."
							/>
						),
					},
				].filter(notNullish)}
			/>

			<ConfirmCreditBoostFooter config={config} />

			{/* TODO: creditboost add proper links */}
			{/* <Text style={{ marginTop: '3rem' }} color="title" type="small"> */}
			{/* 	<FormattedMessage */}
			{/* 		id="confirm-rent.agreement" */}
			{/* 		defaultMessage="By confirming you agree to the {padAgreementLink} and the {paymentTermsLink}." */}
			{/* 		values={{ */}
			{/* 			padAgreementLink: ( */}
			{/* 				<strong> */}
			{/* 					<a href={links.DEFAULT.AGREEMENT.PAD} target="_blank" rel="noreferrer"> */}
			{/* 						<FormattedMessage */}
			{/* 							id="confirm-rent.pad-agreement" */}
			{/* 							defaultMessage="Pre-authorized Debit(PAD) Agreement" */}
			{/* 						/> */}
			{/* 					</a> */}
			{/* 				</strong> */}
			{/* 			), */}
			{/* 			paymentTermsLink: ( */}
			{/* 				<strong> */}
			{/* 					<a */}
			{/* 						href={buildLineOfCreditAgreementLink({ */}
			{/* 							rentAmountCents: config.rentAmountCents.toString(), */}
			{/* 							feeCents: config.hasFreeRentPromoCode ? '0' : getFeeCents(config).toString(), */}
			{/* 							signDate: isoToday(), */}
			{/* 							fullName: user?.fullName ?? '', */}
			{/* 							userEmail: user?.email ?? '', */}
			{/* 							address: getAddressFromResidency(home?.residency ?? undefined), */}
			{/* 							version: publicConfig.padAgreementVersion, */}
			{/* 						})} */}
			{/* 						target="_blank" */}
			{/* 						rel="noreferrer" */}
			{/* 					> */}
			{/* 						<FormattedMessage */}
			{/* 							id="confirm-rent.payment-terms-link" */}
			{/* 							defaultMessage="CustomRent payment terms" */}
			{/* 						/> */}
			{/* 					</a> */}
			{/* 				</strong> */}
			{/* 			), */}
			{/* 		}} */}
			{/* 	/> */}
			{/* </Text> */}
		</>
	);
}

// TODO: creditboost handle fees
function getFeeCents(config: CustomRentConfigForMonth) {
	return calculateFeeCents({
		rentAmountCents: config.rentAmountCents,
		featureId: FeatureType.CreditBoost,
		feeStrategy: config.feeStrategy,
		installments: [],
		blockingPaymentAmountCents: null,
	});
}

// TODO: creditboost display footer
const ConfirmCreditBoostFooter = ({ config }: { config: CustomRentConfigForMonth }) => {
	return (
		<>
			<Spacing $size="l" />

			<Text>
				<FormattedMessage
					id="credit-boost.fees"
					defaultMessage="You will be paying {defaultFeePercentage}% fees of your monthly lease charges. For example for {rentAmount} you will pay {membershipFee}."
					values={{
						defaultFeePercentage: publicConfig.creditBoost.defaultFeePercentage,
						membershipFee: <FormattedCents value={getFeeCents(config)} />,
						rentAmount: <FormattedCents value={config.rentAmountCents} />,
					}}
				/>
			</Text>
		</>
	);
};
