import React, { forwardRef, HTMLAttributes, HTMLInputTypeAttribute, ReactNode } from 'react';
import styled from 'styled-components';

import { InputElement, InputError, InputPlaceholder, InputPrefix, InputWrapper } from './input.styles';

export type InputMode = 'email' | 'search' | 'tel' | 'text' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;

export type InputProps = {
	value: string;
	onInputChange?: (value: string) => void;
	onChange?: (value: string) => void;
	disabled?: boolean;
	name?: string;
	placeholder?: string;
	errorText?: string;
	type?: HTMLInputTypeAttribute;
	children?: ReactNode;
	id?: string;
	testId?: string;
	onBlur?: () => void;
	onFocus?: () => void;
	inputMode?: InputMode;
	className?: string;
	autoFocus?: boolean;
	rawInputProps?: Partial<HTMLAttributes<HTMLInputElement>>;
	prefix?: string;
	onClick?: () => void;
	autoComplete?: 'off' | string;
};

export const InputComponent = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className,
			value,
			onInputChange,
			onChange,
			disabled = false,
			type = 'text',
			placeholder,
			errorText,
			children,
			id,
			testId,
			onFocus,
			onBlur,
			inputMode,
			autoFocus,
			rawInputProps,
			prefix,
			onClick,
			autoComplete,
		},
		ref,
	) => {
		const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
			const inputValue = e.currentTarget.value;

			if (onInputChange) onInputChange(inputValue);
			if (onChange) onChange(inputValue);
		};

		const hasValue = Boolean(value);

		return (
			<InputWrapper className={className}>
				<InputElement
					onChange={handleInputChange}
					type={type}
					disabled={disabled}
					hasError={Boolean(errorText)}
					id={id}
					onFocus={onFocus}
					onBlur={onBlur}
					inputMode={inputMode}
					data-testid={testId}
					autoFocus={autoFocus}
					{...(rawInputProps || {})}
					value={value || ''}
					onClick={onClick}
					ref={ref}
					autoComplete={autoComplete}
				/>
				<InputPlaceholder $hasValue={hasValue}>{placeholder}</InputPlaceholder>
				{prefix && <InputPrefix $hasValue={hasValue}>{prefix}</InputPrefix>}
				{errorText && (
					<InputError key={errorText} data-testid={`${testId}-error`}>
						{errorText}
					</InputError>
				)}
				{children}
			</InputWrapper>
		);
	},
);

InputComponent.displayName = 'InputComponent';

export const Input = styled(InputComponent)``;
