import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
	calculateDefaultBlockingAmountCents,
	CustomRentConfigForMonth,
	feeCentsForRentAmountCents,
} from '../../../../../functions/src/shared/user-rent';

import { FormattedCents } from '../../../shared/components/formatted-cents';

export const FirstRentPaymentInfoText = () => (
	<>
		<FormattedMessage
			defaultMessage="We require you to make your first split rent payment by the latest date indicated on the calendar by e-Transfer to {email}. You’ll receive a reminder the day before and the day of to send the e-Transfer by 12pm MT."
			id="first-rent-payment-info-text.first-payment"
			values={{
				email: <a href="mailto:payments@myzenbase.com">payments@myzenbase.com</a>,
			}}
		/>
	</>
);

export const SecondRentPaymentInfoText = ({ config }: { config: CustomRentConfigForMonth }) => {
	return (
		<>
			<FormattedMessage
				defaultMessage="Your second split rent needs to be paid by the latest date indicated on the calendar and will be automatically withdrawn from your connected bank account. A {fee} membership fee will be added to your second monthly repayment allowing us to offer flexible rent payments to your community."
				id="second-rent-payment-info-text.second-payment"
				values={{
					fee: (
						<FormattedCents
							value={feeCentsForRentAmountCents(
								config.feeStrategy,
								config.rentAmountCents,
								undefined,
								calculateDefaultBlockingAmountCents({
									rentAmountCents: config.rentAmountCents,
									featureId: config.featureId,
								}),
								config.featureId,
							)}
						/>
					),
				}}
			/>
		</>
	);
};
