import React from 'react';
import { Outlet } from 'react-router-dom';

import { NotFound404 } from '../not-found-404';
import { useCreditBoost } from './use-credit-boost.hook';

export function ProtectCreditBoost() {
	const { canUse: canUseCreditBoost } = useCreditBoost();

	if (!canUseCreditBoost) {
		return <NotFound404 />;
	}
	return <Outlet />;
}
