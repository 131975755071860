import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';

import { FeatureSwitch, FeatureType, userHasFeatureEnabled } from '../../../../functions/src/shared/user';
import { CustomRentConfigForMonth } from '../../../../functions/src/shared/user-rent';
import { UserRentFeatureType } from '../../../../functions/src/shared/user-rent-feature-type';

import { trpc } from '../../shared/trpc/client';

import { Button, Footer, Radio, RadioGroup, Spacing, Text } from '../../base-ui/components';

import { BackButton } from '../components/back-button';
import { useUserData } from '../contexts/hooks';
import { PageLinkHomeData, PageVariant, usePageLinks } from '../pages/use-page-links.hook';
import { useConnectedLandlord } from '../utils/use-connected-landlord';

const schema = z.object({
	responsibleForFullRentAmount: z.enum(['true', 'false']),
});

const ColumnRadioGroup = styled(RadioGroup)`
	grid-template-columns: 1fr;
`;

type FormData = z.infer<typeof schema>;

export const CustomRentRoommatesRentAmountConfirm = ({ variant }: { variant: PageVariant }) => {
	const pageLinks = usePageLinks('custom-rent.roommates-rent-amount-confirm');
	const navigate = useNavigate();

	return (
		<RoommatesRentAmountConfirm
			onSuccess={() => {
				navigate(pageLinks.onResponsibleForFullRentSaved({ variant }));
			}}
			featureId={FeatureType.CustomRent}
		/>
	);
};

type CreditBoostRoommatesRentAmountConfirmProps = {
	variant: PageVariant;
};
export const CreditBoostRoommatesRentAmountConfirm = ({ variant }: CreditBoostRoommatesRentAmountConfirmProps) => {
	const pageLinks = usePageLinks('credit-boost.responsible-for-full-rent');
	const navigate = useNavigate();

	return (
		<RoommatesRentAmountConfirm
			onSuccess={({ config, home }) => {
				navigate(pageLinks.onResponsibleForFullRentSaved({ config, home, variant }));
			}}
			featureId={FeatureType.CreditBoost}
		/>
	);
};

type Props = {
	onSuccess: (i: { config: CustomRentConfigForMonth; home: PageLinkHomeData }) => void;
	featureId: UserRentFeatureType;
};
const RoommatesRentAmountConfirm = ({ onSuccess, featureId }: Props) => {
	const intl = useIntl();

	const { home } = useUserData();
	const { landlordConnectedImage } = useConnectedLandlord();
	const unifiedPayments = home?.user ? userHasFeatureEnabled(home?.user, FeatureSwitch.UnifiedPayments) : false;

	const { data: config } = trpc.user.customRent.getConfig.useQuery(
		{
			featureId,
		},
		{
			staleTime: Infinity,
		},
	);

	const defaultValue =
		typeof config?.responsibleForFullRentAmount === 'undefined'
			? 'true'
			: config.responsibleForFullRentAmount
				? 'true'
				: 'false';

	const form = useForm<FormData>({
		resolver: zodResolver(schema),
		defaultValues: { responsibleForFullRentAmount: defaultValue },
	});

	const utils = trpc.useUtils();
	const save = trpc.user.customRent.saveResponsibleForFullRentAmountToConfig.useMutation({
		onSuccess: ({ config: newConfig, onboardingEvents }) => {
			utils.user.customRent.getConfig.setData(
				{
					featureId,
				},
				newConfig,
			);
			utils.user.listOnboardingEvents.setData(undefined, onboardingEvents);
			onSuccess({ config: newConfig, home });
		},
	});

	const submit = (formData: FormData) => {
		const responsibleForFullRentAmount = formData.responsibleForFullRentAmount === 'true';
		save.mutate({ responsibleForFullRentAmount, featureId });
	};

	const address = home?.user.pmsResidency
		? `${home?.user.pmsResidency.address}, ${home?.user.pmsResidency.city}`
		: undefined;

	const at = intl.formatMessage({ defaultMessage: 'at', id: 'common.at-address' });
	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<BackButton />
				<Text type="title">
					<FormattedMessage
						defaultMessage="Confirm how you'd like to set your payment amount"
						id="roommates-rent-amount-confirm.title"
					/>
				</Text>
				<Spacing $size="s" />
				<Text>
					<FormattedMessage
						defaultMessage="You are on this lease{address} with other residents. Please confirm whether you will be setting up Zenbase for the entire monthly payment or for your share only."
						id="roommates-rent-amount-confirm.description"
						values={{ address: address ? ` ${at} ${address}` : '' }}
					/>
				</Text>
				<Spacing $size="xl" />

				<ColumnRadioGroup name="fullRent">
					<Radio
						{...form.register('responsibleForFullRentAmount')}
						value="true"
						label={
							<FormattedMessage
								defaultMessage="I'm setting up Zenbase for the entire unit"
								id="roommates-rent-amount-confirm.radio1"
							/>
						}
					/>
					<Text>
						<FormattedMessage
							defaultMessage="Payments with Zenbase will cover the monthly payments for this lease."
							id="roommates-rent-amount-confirm.radio1-description"
						/>
					</Text>
					<Spacing $size="s" />

					<Radio
						{...form.register('responsibleForFullRentAmount')}
						value="false"
						label={
							<FormattedMessage
								defaultMessage="I'm setting up Zenbase only for my share"
								id="roommates-rent-amount-confirm.radio2"
							/>
						}
					/>
					<Text>
						<FormattedMessage
							defaultMessage="Payments with Zenbase will cover only my share of the monthly payments for this lease."
							id="roommates-rent-amount-confirm.radio2-description"
						/>
					</Text>
				</ColumnRadioGroup>

				{unifiedPayments && landlordConnectedImage}

				<Footer>
					<Button disabled={save.isPending} htmlType="submit" testId="select-rent-button-continue">
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
