import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { trpc } from '../../../shared/trpc/client';

import { MonerisCheckout } from '../../components/moneris/moneris-checkout';
import { useInterface } from '../../contexts/hooks';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { PageVariant, usePageLinks } from '../use-page-links.hook';
import {
	CreditBoostDirectDebitCardCancelModal,
	CustomRentDirectDebitCardCancelModal,
} from './direct-debit-card-cancel-modal';

type Props = {
	variant: PageVariant;
};
export function CustomRentDirectDebitCardVerification({ variant }: Props) {
	const pageLinks = usePageLinks('direct-debit.card-verification');
	const { setModal } = useInterface();

	function handleCancel() {
		setModal(<CustomRentDirectDebitCardCancelModal />);
	}
	return <DirectDebitCardVerificationShared variant={variant} pageLinks={pageLinks} handleCancel={handleCancel} />;
}

export function CreditBoostDirectDebitCardVerification({ variant }: Props) {
	const { setModal } = useInterface();
	const pageLinks = usePageLinks('credit-boost.direct-debit.card-verification');

	function handleCancel() {
		setModal(<CreditBoostDirectDebitCardCancelModal variant={variant} />);
	}
	return <DirectDebitCardVerificationShared variant={variant} pageLinks={pageLinks} handleCancel={handleCancel} />;
}

type SharedProps = {
	variant: PageVariant;
	pageLinks: {
		onSuccess: (i: { variant: PageVariant }) => string;
	};
	handleCancel: () => void;
};
const DirectDebitCardVerificationShared = ({ variant, pageLinks, handleCancel }: SharedProps) => {
	const trackEvent = useTrackOnboardingEvent();
	const navigate = useNavigate();
	const utils = trpc.useUtils();

	function handleSuccess() {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitSuccess },
			{
				onSuccess: () => {
					utils.user.listOnboardingEvents.invalidate();
					utils.user.home.invalidate();
					navigate(pageLinks.onSuccess({ variant }), {
						replace: true,
					});
				},
			},
		);
	}

	return <MonerisCheckout onSuccess={handleSuccess} onCancel={handleCancel} />;
};
