import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { amountToCents } from '../../../../../functions/src/shared/monetary';

import { trpc } from '../../../shared/trpc/client';
import { triggerChatWidget } from '../../../shared/zendesk';
import { preprocessFloat } from '../../../shared/zod-schemas';

import {
	Alert,
	AlertText,
	AlertTitle,
	Anchor,
	Button,
	Footer,
	FormInput,
	PositiveModal,
	Spacing,
	Text,
	WarningModal,
} from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { useInterface } from '../../contexts/hooks';
import { useUserData } from '../../contexts/user-data-context';
import { links } from '../../router/paths';

const schema = z.object({
	amount1: preprocessFloat(z.number().gte(0)),
	amount2: preprocessFloat(z.number().gte(0)),
});
type FormData = z.infer<typeof schema>;

const invalidAmountsErrorMessage = 'The entered amounts are invalid';
const tooManyAttemptsErrorMessage = 'The verification has had too many failures';
const noVerificationAttemptErrorMessage = 'There is no verification attempt for this account.';

export const MicroDepositVerification = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { home } = useUserData();
	const { setModal, closeModal } = useInterface();
	const intl = useIntl();

	const accountId = searchParams.get('ref') ?? home?.accountToVerify?.id;

	const form = useForm<FormData>({ resolver: zodResolver(schema) });

	const utils = trpc.useUtils();
	const { mutate, isPending, error } = trpc.user.verifyBankAccount.useMutation({
		onSuccess() {
			utils.user.home.invalidate(undefined);

			setModal(
				<PositiveModal
					headerText={
						<FormattedMessage
							defaultMessage="Your micro deposit amounts have been verified!"
							id="micro-deposit-verification.verified-header"
						/>
					}
					contentText={null}
					buttonText={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
					buttonHandler={() => {
						closeModal();
					}}
				/>,
				{
					onClose() {
						navigate(
							{ pathname: links.ACCOUNT.UPLOAD_STATEMENTS, search: `?ref=${accountId}` },
							{ replace: true },
						);
					},
				},
			);
		},
		onError(errorRes) {
			if (errorRes.message.includes(invalidAmountsErrorMessage)) {
				form.setError('amount1', {
					message: intl.formatMessage({
						defaultMessage: 'Amount is invalid',
						id: 'micro-deposit-verification.amount-invalid',
					}),
				});
				form.setError('amount2', {
					message: intl.formatMessage({
						defaultMessage: 'Amount is invalid',
						id: 'micro-deposit-verification.amount-invalid',
					}),
				});
			}

			if ([tooManyAttemptsErrorMessage, noVerificationAttemptErrorMessage].includes(errorRes.message)) {
				utils.user.home.invalidate(undefined);
				setModal(
					<WarningModal
						headerText={
							<FormattedMessage
								defaultMessage="Bank account verification failed"
								id="micro-deposit-verification.verification-failed-header"
							/>
						}
						contentText={
							<FormattedMessage
								defaultMessage="Please try to connect your bank account again or contact support."
								id="micro-deposit-verification.verification-failed-content"
							/>
						}
						buttonText={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
						buttonHandler={() => {
							closeModal();
						}}
						altButtonText={
							<FormattedMessage
								defaultMessage="Contact support"
								id="awaiting-transactions.error-verifying-transactions-modal.contact-support-button"
							/>
						}
						altButtonHandler={triggerChatWidget}
					/>,
					{
						onClose() {
							navigate({ pathname: links.ACCOUNT.DASHBOARD }, { replace: true });
						},
					},
				);
			}
		},
	});

	const submit = ({ amount1, amount2 }: FormData) => {
		if (accountId) {
			mutate({ amountsCents: [amountToCents(amount1), amountToCents(amount2)], accountId });
		}
	};

	if (!home) return null;
	if (!accountId) return <Navigate to={links.ACCOUNT.DASHBOARD} replace />;

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<BackButton />

				<Text type="title">
					<FormattedMessage
						defaultMessage="Enter the values from your bank account"
						id="micro-deposit-verification.enter-values-title"
					/>
				</Text>
				<Spacing $size="s" />
				<Text>
					<FormattedMessage
						defaultMessage="In order to verify this account please enter the two amounts deposited in your bank account. It could take up to 2 business days for you to see the deposits in your account."
						id="micro-deposit-verification.enter-values-description"
					/>
				</Text>

				{!!error &&
				![tooManyAttemptsErrorMessage, noVerificationAttemptErrorMessage, invalidAmountsErrorMessage].includes(
					error.message,
				) ? (
					<Alert>
						<AlertTitle>
							<FormattedMessage defaultMessage="An error ocurred" id="alert-error.an-error-occurred" />
						</AlertTitle>
						<AlertText>
							<FormattedMessage
								defaultMessage="Please try again, if errors keep happening"
								id="micro-deposit-verification.error-description"
							/>{' '}
							<Anchor onClick={triggerChatWidget}>
								<FormattedMessage defaultMessage="contact support" id="alert-error.contact-support" />
							</Anchor>
							.
						</AlertText>
					</Alert>
				) : (
					<Spacing $size="s" />
				)}

				<FormInput
					name="amount1"
					placeholder={intl.formatMessage({
						defaultMessage: 'First amount',
						id: 'micro-deposit-verification.first-amount',
					})}
					type="number"
					prefix="$"
				/>
				<FormInput
					name="amount2"
					placeholder={intl.formatMessage({
						defaultMessage: 'Second amount',
						id: 'micro-deposit-verification.second-amount',
					})}
					type="number"
					prefix="$"
				/>

				<Footer stickyOnMobile>
					<Button htmlType="submit" disabled={isPending}>
						<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
