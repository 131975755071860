import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { links as routeLinks } from '../../router/paths';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { useUserData } from '../hooks';
import { UserStatus } from '../user-data-context';

type ManualOnboardingValues = {
	accountNumber: string;
	institutionNumber: string;
	transitNumber: string;
	bankName: string;
};

type Links = typeof routeLinks.REGISTRATION.BANK;
export type Variant = 'custom-rent' | 'credit-boost';

export interface BankOnboardingState {
	manualOnboardingValues: ManualOnboardingValues | null;
	setManualOnboardingValues: (values: ManualOnboardingValues) => void;
	handleVerificationDone: () => Promise<void>;
	handleBankOnboardingCompleted: () => Promise<void>;
	onBankAccountConnected: () => void;
	links: Links;
	variant: Variant;
}

type Props = {
	children: ReactNode;
	links: Links;
	onCompleted: () => void;
	variant: Variant;
};

const initialState: BankOnboardingState = {
	manualOnboardingValues: null,
	setManualOnboardingValues: () => {
		return;
	},
	handleVerificationDone: async () => {
		return;
	},
	handleBankOnboardingCompleted: async () => {
		return;
	},
	onBankAccountConnected: async () => {
		return;
	},
	links: routeLinks.REGISTRATION.BANK,
	variant: 'custom-rent',
};

const BankOnboardingContext = React.createContext(initialState);

export const BankOnboardingProvider = ({ children, links, onCompleted, variant }: Props) => {
	const {
		userStatus,
		handlers: { forceRefetch },
	} = useUserData();
	const navigate = useNavigate();
	const [manualOnboardingValues, setManualOnboardingValues] = useState<ManualOnboardingValues | null>(null);
	const trackOnboardingEvent = useTrackOnboardingEvent();

	const handleVerificationDone = async () => {
		if (userStatus !== UserStatus.ValidUser) {
			trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingBankConnectionEnd });
		}
		navigate(links.MANUAL_PENDING_VERIFICATION);
	};

	const handleBankOnboardingCompleted = async () => {
		await forceRefetch();
		if (userStatus !== UserStatus.ValidUser) {
			await trackOnboardingEvent.mutateAsync({ type: BusinessEventType.OnboardingBankConnectionEnd });
		}
		onCompleted();
	};

	const onBankAccountConnected = () => {
		if (userStatus !== UserStatus.ValidUser) {
			trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingBankConnectionEnd });
		}
	};

	useEffect(() => {
		if (userStatus !== UserStatus.ValidUser) {
			trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingBankConnectionStart });
		}
	}, []);

	return (
		<BankOnboardingContext.Provider
			value={{
				manualOnboardingValues,
				setManualOnboardingValues,
				handleVerificationDone,
				handleBankOnboardingCompleted,
				links,
				onBankAccountConnected,
				variant,
			}}
		>
			{children}
		</BankOnboardingContext.Provider>
	);
};

export const useBankOnboarding = () => {
	const context = React.useContext(BankOnboardingContext);
	if (context === undefined) {
		throw new Error('useUser must be used within a UIProvider');
	}
	return context;
};
