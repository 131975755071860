import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Divider, Footer, Spacing, Text } from '../../base-ui/components';

import { ConnectBankList } from '../components/connect-bank-list';
import { OnboardingCloseButton } from '../components/onboarding-close-button';
import { Variant } from '../contexts/bank-onboarding-context/bank-onboarding-context';
import { links } from '../router/paths';

type Props = { buttonText: ReactNode; handleOnClick: () => void; variant: Variant } & (
	| { manuallyConnect: false }
	| { manuallyConnect: true; handleOpenModal: () => void }
);

export const ConnectBankAction = ({ buttonText, handleOnClick, variant, ...props }: Props) => {
	const intl = useIntl();

	return (
		<>
			<OnboardingCloseButton to={links.ACCOUNT.DASHBOARD} stopOnboardingReminders />
			<Spacing $size="s" />
			<Text type="title">
				{variant === 'custom-rent' && (
					<FormattedMessage
						defaultMessage="Securely connect your bank to split your rent payments"
						id="connect-bank-action.title-custom-rent"
					/>
				)}
				{variant === 'credit-boost' && (
					<FormattedMessage
						defaultMessage="Securely connect your bank"
						id="connect-bank-action.title-credit-builder"
					/>
				)}
			</Text>
			<Spacing $size="m" />
			<Text>
				<FormattedMessage
					defaultMessage="We use an industry leading bank connection tool and your data is always secure."
					id="connect-bank-action.info"
				/>
			</Text>
			<Spacing $size="s" />
			<Divider />
			<Spacing $size="s" />

			<ConnectBankList
				items={[
					{
						icon: 'automatedPayments',
						title: intl.formatMessage({
							defaultMessage: 'Automated payments',
							id: 'connect-bank-action.list-item1-title',
						}),
						subtitle: intl.formatMessage({
							defaultMessage: 'You set your personalized payment schedule and forget it.',
							id: 'connect-bank-action.list-item1-subtitle',
						}),
					},
					{
						icon: 'overdraftAlerts',
						title: intl.formatMessage({
							defaultMessage: 'Overdraft alerts',
							id: 'connect-bank-action.list-item2-title',
						}),
						subtitle: intl.formatMessage({
							defaultMessage: 'On payment days overdraft alerts are sent so that you can reschedule.',
							id: 'connect-bank-action.list-item2-subtitle',
						}),
					},
					{
						icon: 'securityAndPrivacy',
						title: intl.formatMessage({
							defaultMessage: 'Security and privacy',
							id: 'connect-bank-action.list-item3-title',
						}),
						subtitle: intl.formatMessage({
							defaultMessage: 'We never store your banking credentials.',
							id: 'connect-bank-action.list-item3-subtitle',
						}),
					},
				]}
			/>
			<Divider />
			<Spacing $size="s" />

			<Text type="small" center>
				{variant === 'custom-rent' && (
					<FormattedMessage
						defaultMessage="By connecting to your bank account we can verify your earnings to create a CustomRent payment schedule and advance money directly to your bank account whenever you need. It just takes a minute!"
						id="connect-bank-action.footer-custom-rent"
					/>
				)}
				{variant === 'credit-boost' && (
					// TODO: creditboost copy
					<FormattedMessage
						defaultMessage="By connecting to your bank account we can verify your payments automatically. It just takes a minute!"
						id="connect-bank-action.footer-credit-builder"
					/>
				)}
			</Text>
			<Spacing $size="l" />
			<Footer>
				<Button type="large" onClick={handleOnClick} testId="connect-bank-button-connect-instant">
					{buttonText}
				</Button>
				{props.manuallyConnect && (
					<Button type="link" onClick={props.handleOpenModal} testId="connect-bank-button-connect-manually">
						<FormattedMessage
							defaultMessage="Manually connect"
							id="connect-bank-action.button-manually-connect"
						/>
					</Button>
				)}
			</Footer>
		</>
	);
};
