import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';
import { amountToCents, centsToAmount } from '../../../../../functions/src/shared/monetary';
import { FeatureType } from '../../../../../functions/src/shared/user';
import { CustomRentConfigForMonth } from '../../../../../functions/src/shared/user-rent';

import { trpc } from '../../../shared/trpc/client';
import { preprocessFloat } from '../../../shared/zod-schemas';

import { Button, Footer, FormInput, Spacing } from '../../../base-ui/components';

import { AlertError } from '../../components/alert-error';
import { OnboardingCloseButton } from '../../components/onboarding-close-button';
import { SelectRentAmountContent } from '../../components/select-rent-amount-content';
import { useUserData } from '../../contexts/hooks';
import { links } from '../../router/paths';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { useConnectedLandlord } from '../../utils/use-connected-landlord';
import { PageLinkHomeData, PageVariant, usePageLinks } from '../use-page-links.hook';

const schema = z.object({
	amount: preprocessFloat(z.number().gt(0).lt(100_000)),
});

type Schema = z.infer<typeof schema>;

type Props = {
	navigateOnSuccess?: (i: { home: PageLinkHomeData; config: CustomRentConfigForMonth }) => void;
	businessEventsToTrack?: BusinessEventType.OnboardingCustomRentStart;
	featureId: FeatureType.CreditBoost | FeatureType.CustomRent;
};

const SelectRentAmountShared = (props: Props) => {
	const { home } = useUserData();
	const { landlordName } = useConnectedLandlord();
	const intl = useIntl();

	const trackOnboardingEvent = useTrackOnboardingEvent();
	useEffect(() => {
		if (props.businessEventsToTrack) {
			return trackOnboardingEvent.mutate({ type: props.businessEventsToTrack });
		}
	}, [props.businessEventsToTrack]);

	const getInitialRentAmount = trpc.user.customRent.getInitialRentAmount.useQuery(
		{
			featureId: props.featureId,
		},
		{
			staleTime: Infinity,
		},
	);

	useEffect(() => {
		if (getInitialRentAmount.data) {
			form.reset({ amount: centsToAmount(getInitialRentAmount.data.amountCents) });
		}
	}, [getInitialRentAmount.data]);
	const form = useForm<Schema>({
		resolver: zodResolver(schema),
		defaultValues: {
			amount: getInitialRentAmount.data?.amountCents
				? centsToAmount(getInitialRentAmount.data.amountCents)
				: undefined,
		},
	});

	const saveRentAmount = trpc.user.customRent.saveInitialRentAmount.useMutation({
		onSuccess: ({ config, onboardingEvents }) => {
			utils.user.customRent.getConfig.setData(
				{
					featureId: props.featureId,
				},
				config,
			);
			utils.user.customRent.getInitialRentAmount.setData(
				{
					featureId: props.featureId,
				},
				{ amountCents: config.rentAmountCents },
			);
			utils.user.listOnboardingEvents.setData(undefined, onboardingEvents);
			props.navigateOnSuccess?.({ home, config });
		},
	});
	const utils = trpc.useUtils();

	const submit = async ({ amount }: Schema) => {
		await saveRentAmount.mutateAsync({ rentAmountCents: amountToCents(amount), featureId: props.featureId });
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				{/* Ok to keep removed when it's disabled always? */}
				{/* <BackButton disabled /> */}
				<OnboardingCloseButton to={links.ACCOUNT.DASHBOARD} stopOnboardingReminders />

				{!getInitialRentAmount.isPending && (
					<SelectRentAmountContent
						hasInitialRentAmount={Boolean(getInitialRentAmount.data)}
						landlordName={landlordName}
					/>
				)}

				<Spacing $size="m" />
				<FormInput
					name="amount"
					placeholder={intl.formatMessage({
						defaultMessage: 'Rent amount',
						id: 'account-settings.rent-amount.label',
					})}
					testId="select-rent-input-amount"
					inputMode="decimal"
					type="number"
					prefix="$"
				/>

				{saveRentAmount.isError && <AlertError />}

				<Footer>
					<Button disabled={saveRentAmount.isPending} htmlType="submit" testId="select-rent-button-continue">
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};

export const SelectRentAmount = ({ variant }: { variant: PageVariant }) => {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('custom-rent.select-rent-amount');
	return (
		<SelectRentAmountShared
			navigateOnSuccess={({ home }) => {
				navigate(pageLinks.onRentAmountSubmitted({ home, variant }));
			}}
			featureId={FeatureType.CustomRent}
		/>
	);
};

type CreditBoostSelectRentAmountProps = {
	variant: PageVariant;
};
export const CreditBoostSelectRentAmount = ({ variant }: CreditBoostSelectRentAmountProps) => {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.select-rent-amount');

	return (
		<SelectRentAmountShared
			navigateOnSuccess={({ home, config }) =>
				navigate(pageLinks.onRentAmountSubmitted({ home, config, variant }))
			}
			featureId={FeatureType.CreditBoost}
		/>
	);
};
