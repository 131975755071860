import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { Alert, AlertText, AlertTitle, Loader, Text } from '../../base-ui/components';

import { BackButton } from '../components/back-button';
import { OnboardingCloseButton } from '../components/onboarding-close-button';
import { GENERIC_STRIPE_ERROR_MESSAGE } from '../components/stripe/stripe-errors';
import { FormContainer, FormLoader } from '../components/stripe/stripe.styles';
import { useStripeSetup } from '../components/stripe/use-stripe-setup';
import { links } from '../router/paths';
import { ConnectCard } from '../templates/connect-card';

export const AddStripeCard = () => {
	const [search] = useSearchParams();
	const successPath = search.get('successPath') ?? '';

	const [confirmed, setConfirmed] = useState(false);

	const { isPending, confirmationError, paymentMethodForm, clearState } = useStripeSetup({
		successPath,
	});

	function confirm() {
		clearState();
		setConfirmed(true);
	}

	if (isPending) {
		return (
			<>
				<Text center>
					<FormattedMessage
						defaultMessage="Initializing payment form..."
						id="checkout.initializing-payment-form"
					/>
				</Text>
				<FormLoader>
					<Loader />
				</FormLoader>
			</>
		);
	}

	return (
		<>
			<OnboardingCloseButton overrideCanExit to={links.ACCOUNT.DASHBOARD} testId="checkout-close-button" />
			{!confirmed && (
				<>
					{confirmationError && (
						<Alert>
							<AlertTitle>
								<FormattedMessage defaultMessage="Adding card failed" id="add-card.failed" />
							</AlertTitle>
							<AlertText>
								{!confirmationError && GENERIC_STRIPE_ERROR_MESSAGE}
								{!!confirmationError && confirmationError}
							</AlertText>
						</Alert>
					)}
					<ConnectCard onContinue={confirm} />
				</>
			)}

			<FormContainer $visible={confirmed}>
				<BackButton onClick={() => setConfirmed(false)} />
				<Text type="title">
					<FormattedMessage
						defaultMessage="Enter credit card details"
						id="checkout.enter-credit-card-details"
					/>
				</Text>
				{paymentMethodForm}
			</FormContainer>
		</>
	);
};
