import React, { CSSProperties, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button, Footer, Spacing, Text } from '../../base-ui/components';

import { BackButton as BaseBackButton } from '../components/back-button';
import { CloseButton as BaseCloseButton } from '../components/close-button';

type ImageProps = Pick<CSSProperties, 'textAlign' | 'padding' | 'maxHeight' | 'left'> & {
	$mobileMarginLeft?: string;
	$mobileWidth?: string;
	$marginBottom?: string;
};

type Props = {
	imageSrc?: string;
	title: ReactNode;
	subtitle: ReactNode;
	beforeCta?: ReactNode;
	cta?: ReactNode;
	ctaDisabled?: boolean;
	onNext: () => void;
	secondaryCta?: ReactNode;
	secondaryCtaComponent?: ReactNode;
	onSecondaryCta?: () => void;
	footerStickyOnMobile?: boolean;
	backButton?: boolean;
	imageProps?: ImageProps;
	closeButton?: boolean;
	onClose?: () => void;
	addMobileStickyMarginTop?: boolean;
	ctaTestId: string;
	secondaryCtaTestId?: string;
};

export const OnboardingSuccess = ({
	imageSrc,
	title,
	subtitle,
	beforeCta,
	cta = <FormattedMessage defaultMessage="Ok, I Understand" id="credit-builder-payment-completed-ok-button" />,
	ctaTestId,
	secondaryCtaTestId = 'onboarding-setup-cancel-button',
	ctaDisabled,
	onNext,
	secondaryCta,
	onSecondaryCta,
	footerStickyOnMobile,
	backButton = true,
	imageProps = {},
	closeButton,
	onClose,
	addMobileStickyMarginTop,
	secondaryCtaComponent,
}: Props) => {
	const content = (
		<>
			{backButton && <BackButton />}
			{closeButton && <CloseButton onClick={onClose} />}

			{imageSrc && (
				<Image {...imageProps}>
					<img src={imageSrc} style={{ borderRadius: 'inherit' }} />
				</Image>
			)}

			<Text center type="title">
				{title}
			</Text>
			<Spacing $size="m" />

			<Text center color="title">
				{subtitle}
			</Text>
			<Spacing $size="xl" />

			{beforeCta}

			<Footer stickyOnMobile={footerStickyOnMobile} addMobileStickyMarginTop={addMobileStickyMarginTop}>
				<Button onClick={onNext} disabled={ctaDisabled} testId={ctaTestId}>
					{cta}
				</Button>

				{secondaryCta && (
					<Button type="link" onClick={onSecondaryCta} testId={secondaryCtaTestId}>
						{secondaryCta}
					</Button>
				)}
				{secondaryCtaComponent ? secondaryCtaComponent : null}
			</Footer>
		</>
	);

	return content;
};

export const Image = styled.div<ImageProps>`
	margin-bottom: ${({ $marginBottom }) => $marginBottom ?? '4rem'};
	border-radius: inherit;
	position: relative;
	left: ${({ left }) => left ?? '-5rem'};
	right: -5rem;
	width: calc(100% + 10rem);
	top: -4rem;

	text-align: ${({ textAlign }) => textAlign ?? 'unset'};
	padding: ${({ padding }) => padding ?? '0'};

	@media (max-width: 450px) {
		top: 0;
		left: 0;
		right: 0;
		width: ${({ $mobileWidth }) => $mobileWidth ?? '100%'};
		padding: 0;
		${({ $mobileMarginLeft }) => $mobileMarginLeft && `margin-left: ${$mobileMarginLeft};`}
	}

	img {
		max-height: ${({ maxHeight }) => maxHeight ?? 'unset'};
	}
`;

const BackButton = styled(BaseBackButton)`
	position: absolute;
	z-index: 1;
	@media (max-width: 450px) {
		margin-left: 0.5rem;
		margin-top: 0.5rem;
	}
`;

const CloseButton = styled(BaseCloseButton)`
	position: absolute;
	z-index: 1;
	@media (max-width: 450px) {
		margin-left: 0.5rem;
		margin-top: 0.5rem;
	}
`;
