import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MonerisCheckout } from '../../components/moneris/moneris-checkout';
import { useInterface } from '../../contexts/hooks';
import { paths } from '../../router/paths';
import { DirectDebitChargeCancel } from './direct-debit-charge-cancel';

export const DirectDebitCharge = () => {
	const { setModal } = useInterface();
	const navigate = useNavigate();

	function handleSuccess() {
		navigate(`../${paths.ACCOUNT.DIRECT_DEBIT.CHARGE_SUCCESS}`, {
			replace: true,
		});
	}

	function handleCancel() {
		setModal(<DirectDebitChargeCancel />);
	}

	return <MonerisCheckout onSuccess={handleSuccess} onCancel={handleCancel} />;
};
