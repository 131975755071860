import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import congrats from 'url:../../static/shapes/congrats.png';
import learning from 'url:../../static/shapes/learning.png';
import imageStep3 from 'url:../../static/shapes/onboarding-2.png';
import imageStep2 from 'url:../../static/shapes/reschedule-2.png';

import { RouterOutput } from '../../../../../functions/src/api/trpc/types';

import { Box, Spacing } from '../../../base-ui/components';

import { CreditReporting } from '../../components/icons/credit-reporting';
import { ImgPreload } from '../../components/img-preload';
import { OnboardingSuccess } from '../../templates/onboarding-success';
import { CreditBuilderBeforeCta } from './credit-builder-before-cta';
import { CreditBuilderSetupState } from './credit-builder-onboarding.service';
import { CreditBuilderStep1Background } from './credit-builder-step1-background';

const CreditReportingCustom = styled(CreditReporting)`
	font-size: 18rem;
`;

type Props = {
	priceText: ReactNode;
	home: RouterOutput['user']['home'] | null;
};
export const CreditBuilderStep1 = ({ home, priceText }: Props) => {
	const navigate = useNavigate();
	const landlord = home?.landlord;
	const cbIsFree =
		home?.creditBuilderPricing?.monthlyPriceCents === 0 && home?.creditBuilderPricing?.yearlyPriceCents === 0;

	const referralPartnerName = home?.referralPartner?.name;
	const partnerName = referralPartnerName ?? landlord?.propertyManagerName;
	const referralPartnerId = home?.referralPartner?.id;

	return (
		<>
			<Box isColumn verticalCenter>
				<CreditReportingCustom />
				<Spacing $size="xl" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Get credit for the rent payments you are already making"
							id="credit-builder-step1.get-credit-for-rent-payments"
						/>
					}
					subtitle={
						<>
							{partnerName ? (
								<FormattedMessage
									defaultMessage="Thanks to {propertyManagerName}, you can now build credit by reporting your rent payments to Equifax"
									id="credit-builder-step1.thanks-to-property-manager"
									values={{ propertyManagerName: partnerName }}
								/>
							) : (
								<FormattedMessage
									defaultMessage="You can now build credit by reporting your rent payments to Equifax"
									id="credit-builder-step1.you-can-now-build-credit-2"
									values={{ priceText }}
								/>
							)}
							<CreditBuilderStep1Price
								cbIsFree={cbIsFree}
								priceText={priceText}
								freeCreditBuilderMonths={home?.referralPartner?.freeCreditBuilderMonths}
							/>
							.
						</>
					}
					beforeCta={<CreditBuilderBeforeCta landlord={landlord} referralPartnerId={referralPartnerId} />}
					cta={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
					onNext={() =>
						navigate('', { state: 'step-2' } satisfies {
							state: CreditBuilderSetupState;
						})
					}
					ctaTestId="onboarding-cb-setup-step1-continue-button"
					footerStickyOnMobile
					backButton={false}
				/>
			</Box>
			<CreditBuilderStep1Background />

			{/* preload next steps */}
			<ImgPreload src={imageStep2} />
			<ImgPreload src={imageStep3} />
			<ImgPreload src={congrats} />
			<ImgPreload src={learning} />
		</>
	);
};

const CreditBuilderStep1Price = (props: {
	cbIsFree: boolean;
	freeCreditBuilderMonths?: number;
	priceText: ReactNode;
}) => {
	if (props.cbIsFree) return null;
	if (props.freeCreditBuilderMonths)
		return (
			<FormattedMessage
				defaultMessage=" (FREE for the first {freeMonths} months, then for {priceText} per month)"
				id="credit-builder-step1.for-price-per-month-trial"
				values={{ priceText: props.priceText, freeMonths: props.freeCreditBuilderMonths }}
			/>
		);

	return (
		<FormattedMessage
			defaultMessage=" (for {priceText} per month)"
			id="credit-builder-step1.for-price-per-month"
			values={{ priceText: props.priceText }}
		/>
	);
};
