import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { CanadianProvincialCodes, UnitedStatesStateCodes } from '../../../../../functions/src/shared/state-codes';

import { Country } from '../../../shared/country';
import { getEnvironment } from '../../../shared/environment';
import { triggerChatWidget } from '../../../shared/zendesk';

import { Anchor, Button, Footer, FormInput, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { FormAutoSuggestInput } from '../../components/form-auto-suggest-input';
import { ManualAddressLandlordInput } from '../../components/manual-address-landlord-input';
import { useManualAddressSchema } from '../../components/use-manual-address-schema.hook';
import { useResidencyOnboarding } from '../../contexts/hooks';
import { ManualAddressOnboardingValues } from '../../contexts/residency-onboarding-context/residency-onboarding-context';
import { links } from '../../router/paths';
import { provincialCodesService } from '../../utils/provincial-codes-service';
import { CreditBuilderSetupState } from '../credit-builder-onboarding/credit-builder-onboarding.service';

export const ManualAddress = () => {
	const navigate = useNavigate();
	const intl = useIntl();
	const { schema: beforeRefineSchema, CountryLabelMap } = useManualAddressSchema(intl);

	const schema = beforeRefineSchema.refine(
		(data) => {
			if (data.country === Country.CA) {
				return !!CanadianProvincialCodes[data.state];
			}
			if (data.country === Country.US) {
				return !!UnitedStatesStateCodes[data.state];
			}
			return false;
		},
		{
			message: intl.formatMessage({
				defaultMessage: 'Please choose a valid state',
				id: 'manual-address.choose-valid-state',
			}),
			path: ['state'],
		},
	);

	const form = useForm<ManualAddressOnboardingValues>({ resolver: zodResolver(schema) });

	const country = form.watch('country');

	useEffect(() => {
		form.setValue('state', '');
	}, [country]);

	const { handleManualAddressOnboarding, isPending, variant, handleDone } = useResidencyOnboarding();

	const onSubmit = async (data: ManualAddressOnboardingValues) => {
		await handleManualAddressOnboarding(data);
		if (variant === 'account') {
			navigate(links.ACCOUNT.CREDIT_BUILDER.SETUP, { state: 'step-1' as CreditBuilderSetupState });
			return;
		}
		handleDone();
	};

	return (
		<FormProvider {...form}>
			<form
				onSubmit={form.handleSubmit(async (data) => {
					await onSubmit(data);
				})}
			>
				<BackButton testId="address-manual-button-back" />
				<Text type="title">
					<FormattedMessage
						defaultMessage="Enter your address details"
						id="manual-address.enter-address-details"
					/>
				</Text>
				<Spacing $size="m" />
				<Text>
					<FormattedMessage
						defaultMessage="Please enter your street, postal code and unit details to continue."
						id="manual-address.enter-details-to-continue"
					/>
				</Text>
				<Spacing $size="m" />

				<FormInput
					name="streetAddress"
					placeholder={intl.formatMessage({
						defaultMessage: 'Street Address',
						id: 'manual-address.street-address',
					})}
					testId="address-manual-input-street"
				/>
				<FormInput
					name="apartment"
					placeholder={intl.formatMessage({
						defaultMessage: 'Apartment number',
						id: 'manual-address.apartment-number',
					})}
					testId="address-manual-input-apartment"
				/>
				<FormInput
					name="city"
					placeholder={intl.formatMessage({ defaultMessage: 'City', id: 'address.city' })}
					testId="address-manual-input-city"
				/>
				<FormInput
					name="postalCode"
					placeholder={intl.formatMessage({
						defaultMessage: 'Postal Code',
						id: 'add-debit-card.postal-code',
					})}
					testId="address-manual-input-postal-code"
				/>

				<FormAutoSuggestInput
					name="country"
					label={intl.formatMessage({ defaultMessage: 'Country', id: 'manual-address.country' })}
					testId="address-manual-input-country"
					options={[Country.CA, Country.US]}
					getOptionLabel={(value) => (value ? CountryLabelMap[value] : '')}
				/>

				{country === Country.CA && (
					<FormAutoSuggestInput
						name="state"
						label={intl.formatMessage({ defaultMessage: 'Province', id: 'add-debit-card.province' })}
						testId="address-manual-input-state"
						options={Object.keys(CanadianProvincialCodes)}
						getOptionLabel={(value) =>
							value ? `${value} - ${provincialCodesService.translateCanadaProvince(intl, value)}` : ''
						}
					/>
				)}
				{country === Country.US && (
					<FormAutoSuggestInput
						name="state"
						label={intl.formatMessage({ defaultMessage: 'State', id: 'manual-address.state' })}
						testId="address-manual-input-state"
						options={Object.keys(UnitedStatesStateCodes)}
						getOptionLabel={(value) => (value ? `${value} - ${UnitedStatesStateCodes[value]}` : '')}
					/>
				)}
				<ManualAddressLandlordInput name="landlord" />
				<FormInput
					name="employer"
					placeholder={intl.formatMessage({ defaultMessage: 'Employer', id: 'manual-address.employer' })}
					testId="address-manual-input-employer"
				/>

				<Spacing $size="m" />

				{form.watch('country') === Country.US && (
					<Text>
						<b>
							<FormattedMessage
								defaultMessage="Currently, Zenbase is only available in Canada. If you live in the US and are interested in splitting your rent feel free to complete your sign up and we’ll notify you once our service is available in your area."
								id="manual-address.us-only-message"
							/>
						</b>
					</Text>
				)}

				<Spacing $size="m" />
				<Text type="small">
					<FormattedMessage
						defaultMessage="If you have any questions about your address details please {tapHere} to message us"
						id="country-not-supported.contact-us"
						values={{
							tapHere: (
								<Anchor onClick={triggerChatWidget}>
									<FormattedMessage defaultMessage="tap here" id="country-not-supported.tap-here" />
								</Anchor>
							),
						}}
					/>
				</Text>
				<Spacing $size="xl" />
				<Footer>
					<Button htmlType="submit" type="large" testId="address-manual-button-continue" disabled={isPending}>
						<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
					</Button>
					{getEnvironment() !== 'production' ? (
						<Button
							type="link"
							onClick={() => {
								form.setValue('streetAddress', '123 Test St');
								form.setValue('apartment', '123');
								form.setValue('postalCode', 'A1A1A1');
								form.setValue('city', 'Test City');
								form.setValue('state', 'AB');
								form.setValue('country', Country.CA);
								form.setValue('employer', 'Test Employer');
								form.setValue('landlord', 'Test landlord');
							}}
						>
							Set test data
						</Button>
					) : null}
				</Footer>
			</form>
		</FormProvider>
	);
};
