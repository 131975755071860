import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import safestWayToBuildCredit from 'url:../../static/shapes/secured-line-of-credit-safest-way-to-build-credit.png';

import { OnboardingSuccess } from '../../templates/onboarding-success';
import { PageVariant, usePageLinks } from '../use-page-links.hook';
import { CreditBoostNotNowButton } from './onboarding-not-now-button';

type CreditBoostSafestWayToBuildCreditProps = {
	variant: PageVariant;
};
export function CreditBoostSafestWayToBuildCredit({ variant }: CreditBoostSafestWayToBuildCreditProps) {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.setup.safest-way-to-build-credit');
	const title = (
		<FormattedMessage
			defaultMessage="Safest way to build credit"
			id="credit-boost-safest-way-to-build-credit.title"
		/>
	);
	// TODO: creditboost use correct pricing
	const subtitle = (
		<FormattedMessage
			defaultMessage="And there is more. Zenbase allows you to pay via e-Transfers or pre-autorized debit card payments so that you never have to worry about NSF fees.{br}{br} Only for 0.75% of your rent amount per month you get to build credit safely. You will only get charged when your rent is paid."
			id="credit-boost-safest-way-to-build-credit.subtitle"
			values={{
				br: <br />,
			}}
		/>
	);
	return (
		<OnboardingSuccess
			onNext={() => navigate(pageLinks.onContinue({ variant }))}
			imageSrc={safestWayToBuildCredit}
			imageProps={{ padding: '5rem 3rem 0', textAlign: 'center' }}
			title={title}
			subtitle={subtitle}
			ctaTestId="onboarding-credit-boost-safest-way-to-build-credit-continue-button"
			cta={
				<FormattedMessage
					defaultMessage="USE ZENBASE LINE OF CREDIT"
					id="credit-boost-safest-way-to-build-credit.use-zenbase-line-of-credit"
				/>
			}
			secondaryCtaComponent={<CreditBoostNotNowButton onNotNowLink={pageLinks.onNotNow()} />}
		/>
	);
}
