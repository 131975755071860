import React, { useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';

import { PaymentMethod } from '../../../../../functions/src/shared/payment-transaction';
import { FeatureType } from '../../../../../functions/src/shared/user';

import { trpc } from '../../../shared/trpc/client';

import { Button, Center, Footer, Radio, RadioGroup, Spacing, Text } from '../../../base-ui/components';

import { AlertError } from '../../components/alert-error';
import { BackButton } from '../../components/back-button';
import { PageVariant, usePageLinks } from '../use-page-links.hook';

type CreditBoostSelectPaymentMethod = {
	variant: PageVariant;
};
export function CreditBoostSelectPaymentMethod({ variant }: CreditBoostSelectPaymentMethod) {
	const intl = useIntl();
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.select-payment-method');
	const setPaymentMethod = trpc.user.customRent.setPaymentMethod.useMutation();

	// TODO: creditboost Nice to have: use CBo config to prefill the selection
	// const config = trpc.user.customRent.getConfig.useQuery({
	// 	featureId: FeatureType.CreditBoost,
	// });

	const { options, schema } = useMemo(() => {
		const intlOptions = [
			{
				id: 'e-transfer',
				label: intl.formatMessage({
					defaultMessage: 'I will pay via e-Transfer',
					id: 'credit-boost-payment-menthod.e-transfer-title',
				}),
				secondaryLabel: intl.formatMessage({
					defaultMessage: 'You will pay your payments to payments@myzenbase.com.',
					id: 'credit-boost-payment-menthod.e-transfer-secondary',
				}),
			},
			{
				id: 'debit-card',
				label: intl.formatMessage({
					defaultMessage: 'I will pay via debit card',
					id: 'credit-boost-select-payment-method.debit-card-title',
				}),
				secondaryLabel: intl.formatMessage({
					defaultMessage: 'Your rent payments will be charged to your debit card automatically.',
					id: 'credit-boost-select-payment-method.debit-card-secondary',
				}),
			},
		];

		const errorMessage = intl.formatMessage({
			defaultMessage: 'Please choose one of the options',
			id: 'credit-boost-select-payment-method.error',
		});

		const memoSchema = z.object({
			option: z.enum(['debit-card', 'e-transfer'], {
				required_error: errorMessage,
				invalid_type_error: errorMessage,
			}),
			code: z.string().optional(),
		});
		return { schema: memoSchema, options: intlOptions };
	}, [intl]);
	type SchemaType = z.infer<typeof schema>;

	const form = useForm<SchemaType>({
		resolver: zodResolver(schema),
	});

	function handleSubmit(data: SchemaType) {
		setPaymentMethod.mutate(
			{
				paymentMethod: data.option === 'debit-card' ? PaymentMethod.DirectDebit : PaymentMethod.ManualETransfer,
				featureId: FeatureType.CreditBoost,
			},
			{
				onSuccess() {
					navigate(pageLinks.onPaymentMethodSaved({ variant }));
				},
			},
		);
	}

	return (
		<Center>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)}>
					<BackButton />
					<Spacing $size="s" />
					<Text type="title">
						<FormattedMessage
							defaultMessage="Select your payment method"
							id="credit-boost-select-payment-method.title"
						/>
					</Text>
					<Text type="small">
						<FormattedMessage
							// TODO: creditboost use correct pricing
							defaultMessage="You can pay either via e-Transfer or debit card. With these payment methods you never have to worry about NSF fees."
							id="credit-boost-select-payment-method.subtitle"
						/>
					</Text>
					<Spacing $size="m" />
					<RadioGroupRestyled name="option">
						{options.map(({ id, label, secondaryLabel }) => (
							<Radio
								{...form.register('option')}
								value={id}
								key={id}
								label={label}
								testId={`credit-boost-select-payment-method-${id}`}
								secondaryLabel={secondaryLabel}
								paper
							/>
						))}
					</RadioGroupRestyled>

					{setPaymentMethod.isError && <AlertError />}

					<Footer stickyOnMobile>
						<Button
							htmlType="submit"
							disabled={setPaymentMethod.isPending}
							testId="credit-boost-select-payment-method-continue-button"
						>
							<FormattedMessage id="common.continue" />
						</Button>
					</Footer>
				</form>
			</FormProvider>
		</Center>
	);
}

export const RadioGroupRestyled = styled(RadioGroup)`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
