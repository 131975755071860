import React, { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { publicConfig } from '../../../../../functions/src/shared/config';
import { jsonParseSafe } from '../../../../../functions/src/shared/json-parse';
import { TransactionStatus } from '../../../../../functions/src/shared/payment-transaction';

import { getEnvironment } from '../../../shared/environment';
import { trpc } from '../../../shared/trpc/client';

import { links } from '../../router/paths';
import { Moneris, ReceiptEvent } from '../../types/moderis.type';
import { MonerisErrorCharge } from './moneris-error-charge';
import { MonerisErrorVerification } from './moneris-error-verification';
import { MonerisTestData } from './moneris-test-data';

type Props = {
	onSuccess: () => void;
	onCancel?: () => void;
};

export const MonerisCheckout = ({ onSuccess, onCancel }: Props) => {
	const initScriptRef = useRef<boolean>(false);
	const receiptCompleted = useRef<boolean>(false);
	const monerisCheckout = useRef<Moneris | null>(null);

	const ptid = useParams<{ ptid: string }>().ptid;
	const location = useLocation();
	const navigate = useNavigate();

	const preloadRequest = trpc.user.moneris.preloadRequest.useMutation({
		onSuccess: (data) => {
			monerisCheckout.current?.startCheckout(data.ticketId);
		},
		onError: (error) => {
			console.error('preloadRequest error', error);
		},
	});
	const receipt = trpc.user.moneris.processReceipt.useMutation();
	const pendingTransactions = trpc.user.listDirectDebitTransactions.useQuery(
		{
			previouslyFailedPtid: ptid,
		},
		{
			enabled: !!receipt.error || !!preloadRequest.error,
		},
	);
	const cancelExecutingTransaction = trpc.user.moneris.cancelExecutingTransaction.useMutation({
		onSettled() {
			pendingTransactions.refetch();
		},
	});

	useEffect(() => {
		if (initScriptRef.current) {
			return;
		}
		const script = document.createElement('script');

		script.src = publicConfig.moneris[getEnvironment()].scriptUrl;

		document.body.appendChild(script);
		initScriptRef.current = true;
		receipt.reset();

		function genericHandler(...args: string[]) {
			// eslint-disable-next-line no-console
			console.log('genericHandler', args);
		}

		const cancelHandler = debounce(() => {
			// eslint-disable-next-line no-console
			console.log('cancelHandler');
			onCancel?.();
		});

		const completeHandler = (arg: string) => {
			// eslint-disable-next-line no-console
			console.log('completeHandler');

			// moneris is calling us bilion times, we are doing on-and-above to avoid multiple calls to the API
			if (receipt.isPending || receipt.data || receipt.error || receiptCompleted.current) {
				return;
			}
			receiptCompleted.current = true;

			const event = jsonParseSafe(arg) as ReceiptEvent;
			receipt.mutate(
				{ ticketId: event.ticket },
				{
					onSuccess() {
						onSuccess();
						receiptCompleted.current = false;
					},
					onError() {
						receiptCompleted.current = false;
					},
				},
			);
		};

		function iframeLoaded(arg) {
			const event = jsonParseSafe(arg) as ReceiptEvent;
			// eslint-disable-next-line no-console
			console.log('Iframe loaded', event);

			/**
			 * The ticket can be invalid, in such case we need to cancel the transaction and try again if new transaction is re-created
			 */
			if (event.response_code !== '001' && !cancelExecutingTransaction.isPending && ptid) {
				// eslint-disable-next-line no-console
				console.log('canceling executing transaction', ptid);
				cancelExecutingTransaction.mutate({
					ptid,
				});
			}
		}

		function loadListener() {
			const myCheckout: Moneris = new window.monerisCheckout();
			monerisCheckout.current = myCheckout;
			myCheckout.setMode(getEnvironment() !== 'production' ? 'qa' : 'prod');
			myCheckout.setCheckoutDiv('monerisCheckout');
			myCheckout.setCallback('page_loaded', iframeLoaded);
			myCheckout.setCallback('cancel_transaction', cancelHandler);
			myCheckout.setCallback('error_event', genericHandler);
			myCheckout.setCallback('payment_receipt', genericHandler);
			myCheckout.setCallback('payment_complete', completeHandler);
			preloadRequest.mutate({
				ptid,
			});
		}

		script.addEventListener('load', loadListener);

		return () => {
			script.removeEventListener('load', loadListener);
			document.body.removeChild(script);
			initScriptRef.current = false;
		};
	}, [ptid]);

	const paymentTransactionToTryAgain = pendingTransactions.data?.find(
		(pt) => pt.status === TransactionStatus.Pending,
	);
	const canRetry = !ptid || !!paymentTransactionToTryAgain;

	function handleTryAgain() {
		if (!canRetry) {
			navigate(links.ACCOUNT.DASHBOARD);
			return;
		}

		receipt.reset();
		cancelExecutingTransaction.reset();
		receiptCompleted.current = false;
		if (ptid && paymentTransactionToTryAgain) {
			const newPath = location.pathname.replace(ptid, paymentTransactionToTryAgain?.id);
			navigate(newPath);
			return;
		}
		preloadRequest.mutate({
			ptid: paymentTransactionToTryAgain?.id,
		});
	}

	if (receipt.isPending) {
		return <CircularProgress sx={{ mx: 'auto', display: 'block' }} />;
	}

	if (
		receipt.error ||
		preloadRequest.error ||
		cancelExecutingTransaction.data ||
		cancelExecutingTransaction.isPending
	) {
		return (
			<>
				{!ptid && <MonerisErrorVerification onNext={handleTryAgain} />}
				{ptid && (
					<MonerisErrorCharge
						onNext={handleTryAgain}
						onCancel={() => navigate(links.ACCOUNT.DASHBOARD)}
						canRetry={canRetry}
						errorCode={receipt.error?.message ?? preloadRequest.error?.message}
					/>
				)}
			</>
		);
	}

	return (
		<>
			<div id="outerDiv" style={{ height: '1300px' }}>
				<CircularProgress sx={{ mx: 'auto', display: 'block' }} />
				<div id="monerisCheckout"></div>
			</div>
			{getEnvironment() !== 'production' && <MonerisTestData />}
		</>
	);
};
