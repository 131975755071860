import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { trpc } from '../../shared/trpc/client';

import { Icon } from '../../base-ui/components';

import { useUserData } from '../contexts/user-data-context';
import { links } from '../router/paths';

const LinkElement = styled.button`
	display: inline-block;
	width: 4rem;
	margin-left: -0.8rem;
	margin-bottom: 1rem;
	margin-top: -1.2rem;
	cursor: pointer;
	border: none;
	background: none;
	align-self: flex-end;
	justify-self: flex-end;
	${(props) =>
		props.disabled
			? css`
					cursor: not-allowed;
					opacity: 0.5;
				`
			: ''}
`;

const StyledClose = styled.div`
	display: flex;
	justify-content: flex-end;
`;

type Props = {
	to?: string;
	className?: string;
	onClick?: () => void;
	stopOnboardingReminders?: boolean;
	overrideCanExit?: boolean;
	testId?: string;
};

export const OnboardingCloseButton = ({
	to = links.ACCOUNT.DASHBOARD,
	className,
	stopOnboardingReminders,
	overrideCanExit,
	testId,
}: Props) => {
	const navigate = useNavigate();
	const { canExitOnboarding } = useUserData();
	const stopOnboardingRemindersMutation = trpc.user.stopOnboardingReminders.useMutation();

	const handleClick = () => {
		if (stopOnboardingReminders) {
			stopOnboardingRemindersMutation.mutate(undefined, {
				onSuccess() {
					navigate(to);
				},
			});
		} else {
			navigate(to);
		}
	};

	return overrideCanExit || canExitOnboarding ? (
		<StyledClose>
			<LinkElement data-testid={testId} onClick={handleClick} type="button" className={className}>
				<Icon icon="Close" />
			</LinkElement>
		</StyledClose>
	) : (
		<></>
	);
};
