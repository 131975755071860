import React, { useEffect } from 'react';
import { FirebaseError } from 'firebase/app';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import onboarding1 from 'url:../../static/shapes/verify-email.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Alert, AlertText, AlertTitle, Button, Center, Text } from '../../../base-ui/components';

import { firebaseErrors } from '../../contexts/firebase/firebase-api';
import { useFirebase } from '../../contexts/hooks';
import { useAsyncHandler } from '../../utils/hooks/use-async-handler';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

const ImagePanel = styled.div`
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 44.6rem;
	top: 8rem;
`;

const CustomError = (error: unknown) => {
	let title = 'An error ocurred';
	let text = "Something went wrong at our end. Don't worry, we'll fix soon.";
	if (error instanceof FirebaseError && error.code === firebaseErrors.ERROR_CODE_TOO_MANY_REQUEST) {
		title = 'Too many emails sent';
		text = 'Please try resending the email again later.';
	}
	return (
		<Alert>
			<AlertTitle>{title}</AlertTitle>
			<AlertText>{text}</AlertText>
		</Alert>
	);
};

export const RegistrationVerifyEmail = () => {
	const { handlers } = useFirebase();

	const [handleReSendEmailVerification, { loading, data, error }] = useAsyncHandler(
		handlers.handleReSendEmailVerification,
	);

	const trackOnboardingEvent = useTrackOnboardingEvent();

	useEffect(() => trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingEmailVerificationStart }), []);

	return (
		<Center>
			<ImagePanel>
				<img src={onboarding1} />
				<Text type="title" center>
					<FormattedMessage defaultMessage="Verify Email" id="registration-verify-email.verify-email" />
				</Text>
				<Text center>
					<FormattedMessage
						defaultMessage="Follow the link in your inbox to verify your email."
						id="registration-verify-email.follow-link"
					/>
				</Text>
			</ImagePanel>
			{!!error && CustomError(error)}

			<Button
				type="link"
				onClick={() => window.location.reload()}
				testId="onboarding-verify-email-confirm-button"
			>
				<FormattedMessage
					defaultMessage="Already followed link in verification email?"
					id="registration-verify-email.already-followed-link"
				/>
			</Button>

			<Button type="link" onClick={handleReSendEmailVerification} disabled={Boolean(loading || data)}>
				{data ? (
					<FormattedMessage
						defaultMessage="Email has been sent again"
						id="registration-verify-email.email-sent-again"
					/>
				) : (
					<>
						<FormattedMessage
							defaultMessage="Didn't get the mail? Send it again."
							id="registration-verify-email.send-mail-again"
						/>
					</>
				)}
			</Button>
		</Center>
	);
};
