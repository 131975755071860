import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { addYears, parse } from 'date-fns';
import format from 'date-fns/format';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { CanadianProvincialCodes } from '../../../../../functions/src/shared/state-codes';

import { Country } from '../../../shared/country';
import { trpc } from '../../../shared/trpc/client';

import { Button, Footer, FormInput, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { CloseButton } from '../../components/close-button';
import { FormAutoSuggestInput } from '../../components/form-auto-suggest-input';
import { FormattedDateInput } from '../../components/formatted-date-input';
import { landlordInputZodSchema, ManualAddressLandlordInput } from '../../components/manual-address-landlord-input';
import { useIndirectLeaseDateSchema } from '../../components/use-indirect-lease-date-schema.hook';
import { useIndirectSelectRentAmountSchema } from '../../components/use-indirect-rent-amount-schema.hook';
import { useLandlordVerificationSchema } from '../../components/use-landlord-verification-schema.hook';
import { useManualAddressSchema } from '../../components/use-manual-address-schema.hook';
import { useUserData } from '../../contexts/user-data-context';
import { links } from '../../router/paths';
import { provincialCodesService } from '../../utils/provincial-codes-service';

export function CreditBuilderAddPastHistoryMultiStepForm() {
	const intl = useIntl();
	const [step, setStep] = useState<'address-details' | 'lease-details'>('address-details');
	const navigate = useNavigate();
	const addPastLeaseForCreditBuilderHistory = trpc.user.addPastLeaseForCreditBuilderHistory.useMutation();
	const { user } = useUserData();

	const { schema: originalAddressSchema, CountryLabelMap } = useManualAddressSchema(intl, { onlyCA: true });
	const addressSchema = originalAddressSchema.omit({ landlord: true });
	const unrefinedLeaseSchema = useIndirectLeaseDateSchema(intl);
	const landlordVerificationSchema = useLandlordVerificationSchema(intl);
	const indirectLeaseAmountSchema = useIndirectSelectRentAmountSchema();
	const leaseSchema = unrefinedLeaseSchema
		.and(landlordVerificationSchema)
		.and(indirectLeaseAmountSchema)
		.and(
			z.object({
				landlord: landlordInputZodSchema,
			}),
		);

	type AddressSchema = z.infer<typeof addressSchema>;
	type LeaseSchema = z.infer<typeof leaseSchema>;

	const addressForm = useForm<AddressSchema>({
		resolver: zodResolver(addressSchema),
		defaultValues: {
			country: Country.CA,
		},
	});

	const leaseForm = useForm<LeaseSchema>({
		resolver: zodResolver(leaseSchema),
	});

	useEffect(() => {
		if (!leaseForm.getValues('userEmail')) {
			leaseForm.setValue('userEmail', user?.email ?? '');
		}
		if (!leaseForm.getValues('landlordName')) {
			leaseForm.setValue('landlordName', '[placeholder]');
		}
	}, [user, leaseForm]);

	const handleToLeaseDetailsStep = () => {
		setStep(() => 'lease-details');
	};

	const parseDateStr = (dateStr: string) => {
		try {
			return dateStr ? parse(dateStr, 'MM/dd/yyyy', new Date()) : new Date();
		} catch {
			return new Date();
		}
	};

	const handleToAddressDetailsStep = () => setStep(() => 'address-details');

	const onSubmit = (leaseData: LeaseSchema) => {
		const addressData = addressForm.getValues();
		addPastLeaseForCreditBuilderHistory.mutate(
			{
				leaseChargesAmount: leaseData.amount,
				startDate: leaseData.leaseStartDate,
				endDate: leaseData.leaseEndDate,
				streetAddress: addressData.streetAddress,
				apartment: addressData.apartment,
				postalCode: addressData.postalCode,
				city: addressData.city,
				state: addressData.state,
				landlordName: typeof leaseData.landlord === 'string' ? leaseData.landlord : leaseData.landlord.name,
				landlordEmail: leaseData.email ?? null,
			},
			{
				onSuccess(res) {
					navigate(links.ACCOUNT.UPLOAD_LEASE_AGREEMENTS, {
						state: { manualResidencyId: res.manualResidencyId },
					});
				},
			},
		);
	};

	const AddressDetailsStep = () => (
		<FormProvider {...addressForm}>
			<form onSubmit={addressForm.handleSubmit(handleToLeaseDetailsStep)}>
				<Text type="title-large-secondary" style={{ marginBottom: '0.5rem' }}>
					<FormattedMessage
						defaultMessage="Address details"
						id="credit-builder-past-lease-details.address-details"
					/>
				</Text>

				<FormInput
					name="streetAddress"
					placeholder={intl.formatMessage({
						defaultMessage: 'Street Address',
						id: 'manual-address.street-address',
					})}
					testId="address-manual-input-street"
				/>
				<FormInput
					name="apartment"
					placeholder={intl.formatMessage({
						defaultMessage: 'Apartment number',
						id: 'manual-address.apartment-number',
					})}
					testId="address-manual-input-apartment"
				/>
				<FormInput
					name="city"
					placeholder={intl.formatMessage({ defaultMessage: 'City', id: 'address.city' })}
					testId="address-manual-input-city"
				/>
				<FormInput
					name="postalCode"
					placeholder={intl.formatMessage({
						defaultMessage: 'Postal Code',
						id: 'add-debit-card.postal-code',
					})}
					testId="address-manual-input-postal-code"
				/>

				<FormAutoSuggestInput
					name="country"
					label={intl.formatMessage({ defaultMessage: 'Country', id: 'manual-address.country' })}
					testId="address-manual-input-country"
					options={[Country.CA]}
					getOptionLabel={(value) => (value ? CountryLabelMap[value] : '')}
				/>

				{addressForm.watch('country') === Country.CA && (
					<FormAutoSuggestInput
						name="state"
						label={intl.formatMessage({ defaultMessage: 'Province', id: 'add-debit-card.province' })}
						testId="address-manual-input-state"
						options={Object.keys(CanadianProvincialCodes)}
						getOptionLabel={(value) =>
							value ? `${value} - ${provincialCodesService.translateCanadaProvince(intl, value)}` : ''
						}
					/>
				)}
				<Footer>
					<Button htmlType="submit" testId="credit-builder-next-step">
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);

	const LeaseDetailsStep = () => {
		const [startDate, endDate] = leaseForm.watch(['leaseStartDate', 'leaseEndDate']);

		return (
			<FormProvider {...leaseForm}>
				<form onSubmit={leaseForm.handleSubmit(onSubmit)}>
					<Text type="title-large-secondary" style={{ marginBottom: '0.5rem' }}>
						<FormattedMessage
							defaultMessage="Lease details"
							id="credit-builder-past-lease-details.lease-details"
						/>
					</Text>
					<ManualAddressLandlordInput name="landlord" />

					<FormInput
						name="email"
						placeholder={intl.formatMessage({
							defaultMessage: 'Housing Provider E-mail',
							id: 'landlord-verification.email-placeholder',
						})}
						testId="onboarding-cb-landlord-input-email"
					/>
					<FormInput
						name="amount"
						placeholder={intl.formatMessage({
							defaultMessage: 'Rent amount',
							id: 'account-settings.rent-amount.label',
						})}
						testId="onboarding-indirect-select-rent-input-amount"
						inputMode="decimal"
						type="number"
						prefix="$"
					/>
					<FormattedDateInput
						name="leaseStartDate"
						placeholder={intl.formatMessage({
							defaultMessage: 'Lease Start Date',
							id: 'indirect-select-lease-dates.placeholder-lease-start-date',
						})}
						testId="onboarding-indirect-lease-dates-input-lease-start-date"
						changeUntil={addYears(new Date(), 2)}
						handleSelect={(d: Date | undefined) => {
							d ??= parseDateStr(startDate);
							// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
							leaseForm.setValue('leaseStartDate', format(d, 'MM/dd/yyyy'));
							if (!endDate) {
								// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
								leaseForm.setValue('leaseEndDate', format(addYears(d, 1), 'MM/dd/yyyy'));
							}
						}}
						parseDate={parseDateStr}
					/>
					<FormattedDateInput
						name="leaseEndDate"
						testId="onboarding-indirect-lease-dates-input-lease-end-date"
						placeholder={intl.formatMessage({
							defaultMessage: 'Lease End Date',
							id: 'indirect-select-lease-dates.placeholder-lease-end-date',
						})}
						changeUntil={addYears(new Date(), 10)}
						handleSelect={(d: Date | undefined) => {
							if (!d) return;
							// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
							leaseForm.setValue('leaseEndDate', format(d, 'MM/dd/yyyy'));
							leaseForm.clearErrors('leaseEndDate');
						}}
						parseDate={parseDateStr}
					/>
					<Footer>
						<Button htmlType="submit" testId="credit-builder-next-step">
							<FormattedMessage defaultMessage="Submit" id="common.submit" />
						</Button>
					</Footer>
				</form>
			</FormProvider>
		);
	};

	return (
		<div>
			<CloseButton to={links.ACCOUNT.DASHBOARD} />
			<div>
				{step === 'lease-details' && <BackButton onClick={handleToAddressDetailsStep} />}
				<Text type="title">
					<FormattedMessage
						defaultMessage="Enter your past lease details"
						id="credit-builder-past-lease-details.title"
					/>
				</Text>
				<Text type="body">
					<FormattedMessage
						defaultMessage="Please enter details of your past lease."
						id="credit-builder-past-lease-details.body"
					/>
				</Text>
				<Spacing $size="m" />

				{step === 'address-details' && <AddressDetailsStep />}
				{step === 'lease-details' && <LeaseDetailsStep />}
			</div>
		</div>
	);
}
