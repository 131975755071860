import React, { useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

import { UserLandlordPaymentMethod } from '../../../../../functions/src/shared/user';

import { trpc } from '../../../shared/trpc/client';

import { Button, Footer, FormInput, Radio, RadioGroup, Spacing, Text } from '../../../base-ui/components';

import { OnboardingCloseButton } from '../../components/onboarding-close-button';
import { useUserData } from '../../contexts/hooks';
import { links } from '../../router/paths';

const options = [
	{
		label: (
			<FormattedMessage
				defaultMessage="Pre-authorized debit"
				id="enum.landlord-payment-method.pre-authorized-debit"
			/>
		),
		value: UserLandlordPaymentMethod.PreauthorizedDebit,
	},
	{
		label: <FormattedMessage defaultMessage="Cheque payment" id="enum.landlord-payment-method.cheque-payment" />,
		value: UserLandlordPaymentMethod.Check,
	},
	{
		label: <FormattedMessage defaultMessage="e-Transfer" id="enum.landlord-payment-method.e-transfer" />,
		value: UserLandlordPaymentMethod.Etransfer,
	},
	{
		label: (
			<FormattedMessage
				defaultMessage="In person at the office"
				id="enum.landlord-payment-method.in-person-at-the-office"
			/>
		),
		value: UserLandlordPaymentMethod.InPersonAtTheOffice,
	},
	{
		label: <FormattedMessage defaultMessage="Credit card" id="enum.landlord-payment-method.credit-card" />,
		value: UserLandlordPaymentMethod.CreditCard,
	},
	{
		label: <FormattedMessage defaultMessage="Other" id="disable-custom-rent.other-reason" />,
		value: UserLandlordPaymentMethod.Other,
	},
];

export const LandlordPaymentMethod = () => {
	const {
		handlers: { forceRefetch },
	} = useUserData();
	const intl = useIntl();
	const schema = useMemo(() => {
		return z
			.object({
				selectedOption: z.nativeEnum(UserLandlordPaymentMethod, {
					invalid_type_error: intl.formatMessage({
						defaultMessage: 'Please choose one of the above options',
						id: 'common.choose-one-of-the-above',
					}),
				}),
				other: z.string().optional(),
			})
			.superRefine((data, ctx) => {
				if (data.selectedOption === UserLandlordPaymentMethod.Other && !data.other) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						path: ['other'],
						message: intl.formatMessage({
							defaultMessage: 'Required field',
							id: 'common.required-field',
						}),
					});
				}
			});
	}, [intl]);
	type FormData = z.infer<typeof schema>;

	const update = trpc.user.update.useMutation();
	const form = useForm<FormData>({ resolver: zodResolver(schema) });

	const submit = async ({ selectedOption: selected, other }: FormData) => {
		let landlordPaymentMethod: string = selected;
		if (selected === UserLandlordPaymentMethod.Other && other) {
			landlordPaymentMethod = other;
		}

		await update.mutateAsync({ landlordPaymentMethod });
		await forceRefetch();
	};

	const showOther = form.watch('selectedOption') === UserLandlordPaymentMethod.Other;

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<OnboardingCloseButton to={links.ACCOUNT.DASHBOARD} stopOnboardingReminders />
				<Spacing $size="l" />
				<Text type="title">
					<FormattedMessage
						defaultMessage="How do you currently make your rent payment to your landlord?"
						id="landlord-payment-method.title"
					/>
				</Text>
				<Spacing $size="s" />
				<Text>
					<FormattedMessage
						defaultMessage="Please select which method you used for your rent payment last month."
						id="landlord-payment-method.select-method"
					/>
				</Text>

				{update.isError && (
					<Text type="small">
						<FormattedMessage
							defaultMessage="There was an error sending payment method."
							id="landlord-payment-method.error"
						/>
					</Text>
				)}

				<Spacing $size="l" />

				<RadioGroup name="selectedOption">
					{options.map(({ label, value }) => (
						<Radio {...form.register('selectedOption')} value={value} key={value} label={label} />
					))}
				</RadioGroup>

				{showOther && (
					<FormInput
						name="other"
						placeholder={intl.formatMessage({
							defaultMessage: 'Please enter your payment method',
							id: 'landlord-payment-method.enter-payment-method',
						})}
					/>
				)}

				<Spacing $size="m" />

				<Footer>
					<Button htmlType="submit" disabled={form.formState.isSubmitting}>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
