import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors } from '../../variables';
import { Box } from '../box';

export type FooterProps = PropsWithChildren<{
	stickyOnMobile?: boolean;
	className?: string;
	addMobileStickyMarginTop?: boolean;
}>;

export const Footer = ({ children, stickyOnMobile, className, addMobileStickyMarginTop }: FooterProps) => {
	return (
		<>
			<Element $stickyOnMobile={stickyOnMobile} className={className}>
				<Box isColumn verticalCenter>
					{children}
				</Box>
			</Element>
			{stickyOnMobile && <MobileStickyPlaceholder $addMobileStickyMarginTop={addMobileStickyMarginTop} />}
		</>
	);
};

const MobileStickyPlaceholder = styled.div<{ $addMobileStickyMarginTop?: boolean }>`
	display: none;
	height: 30px;
	${({ $addMobileStickyMarginTop }) => $addMobileStickyMarginTop && `margin-top: 9rem;`}
`;

const Element = styled.div<{ $stickyOnMobile?: boolean }>`
	display: block;
	width: 100%;
	padding: 2rem;

	@media (max-width: 490px) {
		padding-bottom: 5rem;

		${({ $stickyOnMobile }) =>
			$stickyOnMobile &&
			`position: fixed;
			z-index: 10;
			bottom: 0;
			left: 0;
			background-color: ${colors.white};
			
			& + ${MobileStickyPlaceholder} {
				display: block;
			}`}
	}
`;
