import React, { useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isValid, parse } from 'date-fns';
// but in date-fns + parce = format cannot be imported as named import
import format from 'date-fns/format';
import { omit } from 'lodash';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import congrats from 'url:../../static/shapes/congrats.png';
import learning from 'url:../../static/shapes/learning.png';
import { z } from 'zod';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';
import { CreditSubjectRentReportingStatus } from '../../../../../functions/src/shared/credit-reporting';
import { isIsoDate, IsoDate, jsDateToIsoDate } from '../../../../../functions/src/shared/iso-date';

import { useMyZenbaseLinks } from '../../../shared/my-zenbase-links';
import { trpc } from '../../../shared/trpc/client';

import { Anchor, Button, Flex, Footer, FormInput, Icon, Spacing, Text } from '../../../base-ui/components';

import { AlertError } from '../../components/alert-error';
import { BackButton } from '../../components/back-button';
import { DateOfBirthInput } from '../../components/date-of-birth-input';
import { ImgPreload } from '../../components/img-preload';
import { useInterface, useUserData } from '../../contexts/hooks';
import { links } from '../../router/paths';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { CreditBuilderConfirmModal } from './credit-builder-confirm-modal';
import { CreditBuilderPrivacyModal } from './credit-builder-privacy-modal';
import { isSkylineLandlord } from './is-skyline';

type Props = {
	variant: 'onboarding' | 'account';
	indirectModel: boolean;
};

export const CreditBuilderConfirmRentReporting = ({ variant, indirectModel }: Props) => {
	const intl = useIntl();
	const myZenbaseLinks = useMyZenbaseLinks();
	const { home } = useUserData();

	// we show the checkbox only for skyline landlord
	const isLandlordWithPrivacy = isSkylineLandlord(home?.landlord?.propertyManagerName);

	const schema = useMemo(() => {
		return z.object({
			reportingConsent: z.boolean().refine(
				(accepted) => accepted,
				intl.formatMessage({
					defaultMessage: 'Please consent to rental payment reporting by checking the box to proceed',
					id: 'credit-builder-confirm-rent-reporting.error',
				}),
			),
			privacyTermsConsent: z.boolean().refine(
				(accepted) => accepted,
				intl.formatMessage({
					defaultMessage: 'Please consent to the privacy terms by checking the box to proceed',
					id: 'credit-builder-confirm-rent-reporting.error-privacy',
				}),
			),
			dateOfBirth: z
				.string()
				.transform((dateStr) => {
					const parsedDate = parse(dateStr, 'MM/dd/yyyy', new Date());
					if (!isValid(parsedDate)) {
						return '';
					}
					return jsDateToIsoDate(parsedDate);
				})
				.refine(
					(str) => isIsoDate(str),
					intl.formatMessage({
						defaultMessage: 'Please enter a valid date of birth',
						id: 'credit-builder-confirm-rent-reporting.error.date-of-birth',
					}),
				),
			reporting: z.object({
				firstName: z.string(),
				lastName: z.string(),
				middleName: z
					.string()
					.nullish()
					.transform((x) => {
						if (x === '') return null;
						return x ?? null;
					}),
				signingUpFor: z.literal('rental'),
			}),
		});
	}, [intl]);
	type SubmitData = z.infer<typeof schema>;

	const form = useForm<SubmitData>({
		resolver: zodResolver(schema),
		defaultValues: {
			reportingConsent: false,
			privacyTermsConsent: !isLandlordWithPrivacy,
			reporting: {
				signingUpFor: 'rental',
				middleName: null,
			},
		},
	});
	const trackOnboardingEvent = useTrackOnboardingEvent();
	const utils = trpc.useUtils();
	const { data: userCreditSubject } = trpc.user.getCreditSubject.useQuery(undefined);
	useEffect(() => {
		if (userCreditSubject) {
			form.setValue('dateOfBirth', '01/01/1970' as IsoDate); // placeholder value
			if (userCreditSubject.middleName) {
				form.setValue('reporting.middleName', userCreditSubject.middleName);
			}
			if (userCreditSubject.firstName) {
				form.setValue('reporting.firstName', userCreditSubject.firstName);
			}
			if (userCreditSubject.lastName) {
				form.setValue('reporting.lastName', userCreditSubject.lastName);
			}
		}
	}, [userCreditSubject]);
	const navigate = useNavigate();
	const creditReportingSignUp = trpc.creditReporting.signUp.useMutation({
		onSuccess({ onboardingEvents, home: newHome, paymentId, isEligibleForCreditBuilderHistory }) {
			const free =
				newHome.creditBuilderPricing?.yearlyPriceCents === 0 &&
				newHome.creditBuilderPricing?.monthlyPriceCents === 0;

			const freePeriod = (newHome.referralPartner?.freeCreditBuilderMonths ?? 0) > 0;

			if (!paymentId && variant === 'account') {
				if (
					free ||
					freePeriod ||
					newHome?.creditSubject?.rentReportingStatus === CreditSubjectRentReportingStatus.Active
				) {
					navigate(links.ACCOUNT.CREDIT_BUILDER.SUCCESS);
					return;
				}
				navigate(links.ACCOUNT.CREDIT_BUILDER.SUBSCRIPTION_TYPE);
				return;
			}
			if (isEligibleForCreditBuilderHistory) {
				if (variant === 'onboarding') {
					trackOnboardingEvent.mutate(
						{ type: BusinessEventType.OnboardingCreditBuilderRentalHistoryUpsellStart },
						{
							onSuccess() {
								navigate(links.REGISTRATION.CREDIT_BUILDER.HISTORY_REPORTING.ROOT);
							},
						},
					);
				} else {
					navigate(links.ACCOUNT.CREDIT_BUILDER.HISTORY_REPORTING.ROOT);
				}
				return;
			}
			if (paymentId) {
				navigate(
					variant === 'onboarding'
						? links.REGISTRATION.CREDIT_BUILDER.SUBSCRIPTION_TYPE
						: links.ACCOUNT.CREDIT_BUILDER.SUBSCRIPTION_TYPE,
				);
			} else {
				navigate(
					variant === 'onboarding'
						? links.REGISTRATION.CREDIT_BUILDER.SUCCESS
						: links.ACCOUNT.CREDIT_BUILDER.SUCCESS,
				);
			}

			utils.user.listOnboardingEvents.setData(undefined, onboardingEvents);
			utils.user.home.setData(undefined, newHome);
		},
	});

	const submit: SubmitHandler<SubmitData> = (data: SubmitData) => {
		creditReportingSignUp.mutate({
			allTermsAccepted: data.reportingConsent && data.privacyTermsConsent,
			...omit(data, 'reportingConsent', 'privacyTermsConsent'),
		});
	};
	const { setModal, closeModal } = useInterface();

	const onNotNow = () => {
		setModal(<CreditBuilderConfirmModal variant={variant} indirectModel={indirectModel} closeModal={closeModal} />);
	};
	return (
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(submit)}>
					<BackButton />

					<Text type="title">
						<FormattedMessage
							defaultMessage="Confirm rent reporting"
							id="credit-builder-confirm-rent-reporting.confirm-rent-reporting"
						/>
					</Text>
					<Spacing $size="s" />
					<Text>
						{userCreditSubject ? (
							<>
								<FormattedMessage
									defaultMessage="Please enter your first name, last name as it would match your credit report."
									id="credit-builder-confirm-rent-reporting.enter-name"
								/>
								<br />
								<br />
								<FormattedMessage
									defaultMessage="This helps us accurately report your payments to the bureaus!"
									id="credit-builder-confirm-rent-reporting.helps-report"
								/>
							</>
						) : (
							<>
								<FormattedMessage
									defaultMessage="Please enter your first name, last name and your date of birth as it would match your credit report."
									id="credit-builder-confirm-rent-reporting.enter-name-dob"
								/>
								<br />
								<br />
								<FormattedMessage
									defaultMessage="This helps us accurately report your payments to the bureaus!"
									id="credit-builder-confirm-rent-reporting.helps-report-dob"
								/>
							</>
						)}
					</Text>

					<FormInput
						type="text"
						name="reporting.firstName"
						placeholder={intl.formatMessage({
							id: 'credit-builder-confirm-rent-reporting.first-name',
							defaultMessage: 'First Name',
						})}
						inputMode="text"
						autoComplete="given-name"
						testId="onboarding-cb-details-input-first-name"
					/>
					<FormInput
						type="text"
						name="reporting.middleName"
						placeholder={intl.formatMessage({
							id: 'credit-builder-confirm-rent-reporting.middle-name',
							defaultMessage: 'Middle Name (Optional)',
						})}
						inputMode="text"
						autoComplete="additional-name"
						testId="onboarding-cb-details-input-middle-name"
					/>
					<FormInput
						type="text"
						name="reporting.lastName"
						placeholder={intl.formatMessage({
							id: 'credit-builder-confirm-rent-reporting.last-name',
							defaultMessage: 'Last Name',
						})}
						inputMode="text"
						autoComplete="family-name"
						testId="onboarding-cb-details-input-last-name"
					/>

					{!userCreditSubject && (
						<DateOfBirthInput
							name="dateOfBirth"
							handleSelect={(d) => {
								if (!d) return;
								// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
								form.setValue('dateOfBirth', format(d, 'MM/dd/yyyy') as IsoDate, {
									shouldValidate: true,
									shouldDirty: true,
									shouldTouch: true,
								});
							}}
							testId="onboarding-cb-details-input-birthdate"
							parseDate={(v) => (v ? parse(v, 'MM/dd/yyyy', new Date()) : new Date())}
						/>
					)}
					<Spacing $size="s" />
					<FormInput
						name="reportingConsent"
						type="checkbox"
						testId="onboarding-cb-details-input-terms"
						label={
							<>
								<FormattedMessage
									defaultMessage="I'm giving consent to report my rental payment to the credit bureaus and agreeing on the"
									id="credit-builder-confirm-rent-reporting.consent"
								/>{' '}
								<Anchor href={myZenbaseLinks.cbTerms} target="_blank">
									<FormattedMessage
										defaultMessage="Terms of Use"
										id="credit-builder-confirm-rent-reporting.terms-of-use"
									/>
								</Anchor>
								.
							</>
						}
					/>
					{isLandlordWithPrivacy && (
						<>
							<FormInput
								name="privacyTermsConsent"
								type="checkbox"
								testId="onboarding-cb-details-privacy-terms"
								label={
									<>
										<FormattedMessage
											defaultMessage="I consent to my landlord’s disclosure of personal information to Zenbase as required for the purpose of reporting my rental payments to credit bureaus."
											id="credit-builder-confirm-rent-reporting.privacy-consent"
										/>
									</>
								}
							/>
							<Flex alignItems="center" justifyContent="space-between">
								<Text type="small" color="muted">
									<FormattedMessage
										defaultMessage="Your personal information will be processed in accordance with Zenbase’s {privacyPolicyLink}."
										id="credit-builder-confirm-rent-reporting.privacy-footnote"
										values={{
											privacyPolicyLink: (
												<Anchor href={myZenbaseLinks.privacy} target="_blank">
													<FormattedMessage
														defaultMessage="Privacy Policy"
														id="credit-builder-confirm-rent-reporting.privacy-footnote-link"
													/>
												</Anchor>
											),
										}}
									/>
								</Text>
								<Button
									size="small"
									transparent
									noPadding
									onClick={() => setModal(<CreditBuilderPrivacyModal />)}
								>
									<Icon icon="Info" />
								</Button>
							</Flex>
						</>
					)}

					{creditReportingSignUp.isError && <AlertError />}

					<Footer stickyOnMobile addMobileStickyMarginTop>
						<Button
							htmlType="submit"
							disabled={creditReportingSignUp.isPending}
							testId="onboarding-cb-details-confirm-button"
						>
							<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
						</Button>
						<Button type="link" onClick={onNotNow} testId="onboarding-cb-details-cancel-button">
							<FormattedMessage
								defaultMessage="Not now"
								id="credit-builder-confirm-rent-reporting.not-now"
							/>
						</Button>
					</Footer>
				</form>
			</FormProvider>

			{/* preload first success step imgs */}
			<ImgPreload src={congrats} />
			<ImgPreload src={learning} />
		</>
	);
};
