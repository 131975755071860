import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	applyActionCode,
	confirmPasswordReset,
	createUserWithEmailAndPassword,
	getMultiFactorResolver,
	multiFactor,
	MultiFactorResolver,
	PhoneAuthProvider,
	PhoneMultiFactorGenerator,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
	User,
	UserCredential,
	verifyPasswordResetCode,
} from 'firebase/auth';
import { useIntl } from 'react-intl';
import { To, useLocation, useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { dataLayer } from '../../../shared/data-layer';
import { stopImpersonation } from '../../../shared/impersonation';
import { RouterInput, trpc } from '../../../shared/trpc/client';
import * as zendesk from '../../../shared/zendesk';

import { WarningModal } from '../../../base-ui/components';
import { trimWhiteSpace } from '../../../base-ui/utils';

import { ActionParams, AuthContext, AuthUser, FirebaseProviderProps, SignInMethods, SignInOptions } from '.';
import { links } from '../../router/paths';
import * as fullstory from '../../utils/fullstory';
import { clearInvite, getInvite } from '../../utils/invite-storage';
import { useInterface } from '../hooks';
import { auth, firebaseErrors, googleAuthProvider, microsoftAuthProvider } from './firebase-api';
import { useRecaptcha } from './recaptcha';

export const isUserIdValid = (userCredential: UserCredential) => {
	return userCredential.user?.uid !== undefined;
};

let multiFactorResolver: MultiFactorResolver | undefined;

export const FirebaseProvider = ({ children, paths, appName }: FirebaseProviderProps) => {
	const { state } = useLocation();

	// Core External State
	const [isUserAuthenticated, setIsUserAuthenticated] = useState(Boolean(auth.currentUser));
	const [isEmailVerified, setIsEmailVerified] = useState(Boolean(auth.currentUser?.emailVerified));
	const [isPhoneEnrolled, setIsPhoneEnrolled] = useState(
		Boolean(auth.currentUser && multiFactor(auth.currentUser).enrolledFactors?.length),
	);

	// tri-state:
	// undefined: not loaded
	// null - loaded, but no auth user
	// AuthUser - authenticated user
	const [authUser, setAuthUser] = useState<AuthUser | null | undefined>(undefined);

	const { setModal } = useInterface();
	// Recaptcha
	const { getRecaptchaVerifier, destroyRecaptcha } = useRecaptcha();

	// Internal State
	const [verificationId, setVerificationId] = useState<string | undefined>();

	const createUserPromise = useRef<Promise<void>>();

	const navigate = useNavigate();

	const setUserActive = trpc.user.setNewUserActive.useMutation();

	const signOutMutation = trpc.auth.signOut.useMutation();

	const trackEvent = trpc.user.trackEvent.useMutation();

	const handleUserIsSignedIn = () => {
		setIsUserAuthenticated(true);

		trackEvent.mutate({ type: BusinessEventType.UserLogin, app: appName });

		const { originalLocation } = (state || {}) as { originalLocation?: To };
		navigate(originalLocation || paths.onLogin);
	};

	const onAuthUserStateChange = useCallback(async (newAuthUser: User | null) => {
		if (createUserPromise.current) {
			await createUserPromise.current;
			createUserPromise.current = undefined;
		}
		if (auth.currentUser) {
			setIsPhoneEnrolled(Boolean(multiFactor(auth.currentUser).enrolledFactors?.length));
		}
		auth.currentUser?.getIdToken().then((token) => {
			sessionStorage.setItem('authToken', token);
		});

		const tokenResult = await auth.currentUser?.getIdTokenResult();
		// order is important - there is no batching in our version of React
		// Sets the authUser last, as that one control most logic
		setIsUserAuthenticated(Boolean(newAuthUser));
		setIsEmailVerified(Boolean(newAuthUser?.emailVerified));
		setAuthUser(newAuthUser ? { ...newAuthUser, tokenResult } : null);

		if (newAuthUser) {
			fullstory.identify(newAuthUser.uid, newAuthUser.displayName, newAuthUser.email);
			dataLayer.push({ event: 'login', user_id: newAuthUser.uid });
		} else {
			fullstory.anonymize();
			zendesk.anonymize();
			dataLayer.push({ event: 'login', user_id: null });
		}
	}, []);

	useEffect(() => {
		const unregisterListener = auth.onAuthStateChanged(onAuthUserStateChange, (err) => console.error(err));

		return () => {
			unregisterListener();
		};
	}, [onAuthUserStateChange]);

	const handleSignIn = async (options: SignInOptions) => {
		const signInPromise =
			options.method === SignInMethods.email
				? signInWithEmailAndPassword(auth, options.email, options.password)
				: options.method === SignInMethods.microsoft
					? signInWithPopup(auth, microsoftAuthProvider)
					: signInWithPopup(auth, googleAuthProvider);

		await signInPromise
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;
				const userHasId = user?.uid !== undefined;

				if (userHasId) {
					trackEvent.mutate({ type: BusinessEventType.UserLogin, app: appName });
				}
			})
			.catch((error) => {
				if (error.code === firebaseErrors.ERROR_CODE_MULTI_FACTOR_AUTH_REQUIRED) {
					// move to 2fa -> phone security code send and asked to verify
					multiFactorResolver = getMultiFactorResolver(auth, error);

					navigate(paths.onPhoneVerify, { state });

					signInSendSms();
				} else {
					throw error;
				}
			});
	};

	const handleSignOut = useCallback(async () => {
		stopImpersonation(); // in case admin signs out while impersonating
		await signOutMutation.mutateAsync();
		await signOut(auth);
		navigate('/');
	}, []);

	const signInSendSms = () => {
		const phoneAuthProvider = new PhoneAuthProvider(auth);

		if (multiFactorResolver) {
			const phoneInfoOptions = {
				multiFactorHint: multiFactorResolver.hints[0],
				session: multiFactorResolver.session,
			};

			phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, getRecaptchaVerifier()).then((newVerificationId) => {
				setVerificationId(newVerificationId);
				destroyRecaptcha();
			});
		} else {
			if (setModal) {
				setModal(<SignInAgainModal />);
			} else {
				window.alert('you need to sign in again');
			}
		}
	};

	const handleSignInVerifySMSCode = async (verificationCode: string) => {
		if (verificationId && multiFactorResolver) {
			const credentials = PhoneAuthProvider.credential(verificationId, verificationCode);

			const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);

			await multiFactorResolver.resolveSignIn(multiFactorAssertion).then((userCredential: UserCredential) => {
				if (isUserIdValid(userCredential)) {
					handleUserIsSignedIn();
				}
			});
		} else {
			// TODO: better store for verificationID and resolver
			throw new Error('missing verificationId OR multiFactorResolver');
		}
	};

	const createUser = trpc.user.create.useMutation();
	const handleSignUp = async (
		{ password, phone, ...registration }: Omit<RouterInput['user']['create'], 'inviteId'> & { password: string },
		sendVerificationEmailParams: ActionParams = {},
	) => {
		createUserPromise.current = createUserWithEmailAndPassword(auth, registration.email, password).then(
			async (credential) => {
				const invite = getInvite();
				let inviteId: string | undefined;
				if (invite?.inviteId && !invite?.inviteSecret) inviteId = invite.inviteId;

				await createUser.mutateAsync({
					inviteId,
					phone: phone && trimWhiteSpace(phone),
					...registration,
					timezone: Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone,
				});

				clearInvite();

				if (!credential.user?.emailVerified) {
					handleSendVerificationEmail(sendVerificationEmailParams);
				}
			},
		);

		await createUserPromise.current;
	};

	const handleSendVerificationEmail = ({ continueUrl: url }: ActionParams = {}) => {
		if (auth.currentUser) {
			sendEmailVerification(auth.currentUser, url ? { url } : undefined).then(function () {
				// Verification email sent.
			});
		}

		if (paths.onRegistrationEmailVerify) navigate(paths.onRegistrationEmailVerify);
	};

	const handleReSendSmsCode = async () => {
		return await signInSendSms();
	};

	const handleSendEnrollVerificationSms = async (phoneNumber: string) => {
		if (auth.currentUser) {
			return await multiFactor(auth.currentUser)
				.getSession()
				.then(function (multiFactorSession) {
					const phoneInfoOptions = {
						phoneNumber: trimWhiteSpace(phoneNumber),
						session: multiFactorSession,
					};

					const phoneAuthProvider = new PhoneAuthProvider(auth);

					return phoneAuthProvider
						.verifyPhoneNumber(phoneInfoOptions, getRecaptchaVerifier())
						.then(function (newVerificationId) {
							setVerificationId(newVerificationId);
							destroyRecaptcha();

							return true;
						});
				})
				.catch((error) => {
					if (error.code === firebaseErrors.ERROR_CODE_RECENT_SIGN_IN) {
						if (setModal) {
							setModal(<SignInAgainModal />);
						} else {
							window.alert('you need to sign in again');
						}
					}

					throw error;
				});
		}
		if (setModal) {
			setModal(<SignInAgainModal />);
		} else {
			window.alert('you need to sign in again');
		}
		return;
	};

	const handleEnrollVerifySMSCode = async (code: string) => {
		if (verificationId && auth.currentUser && code) {
			const cred = PhoneAuthProvider.credential(verificationId, code);
			const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

			await multiFactor(auth.currentUser)
				.enroll(multiFactorAssertion, 'phone number')
				.then(function () {
					if (paths.onRegistrationCompleted) {
						// TODO: hack
						setIsPhoneEnrolled(true);

						setUserActive.mutate(undefined, {
							onSuccess() {
								if (paths.onRegistrationCompleted) {
									navigate(paths.onRegistrationCompleted);
								}
							},
						});
					}
				});
		}
	};

	const handleVerifyEmailCode = async (code: string, continueUrl?: string) => {
		await applyActionCode(auth, code);

		if (continueUrl) {
			window.location.href = continueUrl;
			return true;
		}

		await auth.currentUser?.reload();
		await auth.currentUser?.getIdToken(true); // hard user reload

		onAuthUserStateChange(auth.currentUser);

		return true;
	};

	const handleReSendEmailVerification = async ({ continueUrl: url }: ActionParams = {}) => {
		if (auth.currentUser) {
			await sendEmailVerification(auth.currentUser, url ? { url } : undefined);
		}
		return true;
	};

	const handlePasswordResetRequest = async (email: string, { continueUrl: url }: ActionParams = {}) => {
		await sendPasswordResetEmail(auth, email, url ? { url } : undefined);
		return { email };
	};

	const handlePasswordReset = async (code: string, newPassword: string) => {
		await verifyPasswordResetCode(auth, code).then(() => {
			return confirmPasswordReset(auth, code, newPassword);
		});
	};

	const handleTokenRefresh = async () => {
		await auth.currentUser?.reload();
		await auth.currentUser?.getIdToken(true); // hard user reload

		onAuthUserStateChange(auth.currentUser);
	};

	const handleReloadUser = async () => {
		await auth.currentUser?.reload();
		onAuthUserStateChange(auth.currentUser);
	};

	return (
		<AuthContext.Provider
			value={{
				isUserAuthenticated,
				isEmailVerified,
				isPhoneEnrolled,
				authUser: authUser ?? null,
				authUserLoaded: authUser !== undefined,

				handlers: {
					handleSignIn,
					handleSignOut,
					handleSignUp,

					handleReSendEmailVerification,
					handleVerifyEmailCode,

					handleSignInVerifySMSCode,
					handleReSendSmsCode,

					handlePasswordResetRequest,
					handlePasswordReset,

					handleSendEnrollVerificationSms,
					handleEnrollVerifySMSCode,

					handleTokenRefresh,
					handleReloadUser,
				},
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

// this is purely to use in ErrorBoundary class component
// there is handleSignOut in FirebaseContext with slightly different implementation!
export const handleSignOut = async () => {
	await signOut(auth);
};

const SignInAgainModal = () => {
	const navigate = useNavigate();
	const { closeModal } = useInterface();
	const intl = useIntl();

	return (
		<WarningModal
			headerText={intl.formatMessage({
				id: 'sign-in-again-modal.header',
				defaultMessage: 'Something happened :(',
			})}
			contentText={intl.formatMessage({
				id: 'sign-in-again-modal.content',
				defaultMessage: 'It seems signing in took too long, please sign in again.',
			})}
			buttonHandler={async () => {
				closeModal();
				await handleSignOut();
				navigate(links.DEFAULT.SIGN_IN);
			}}
			buttonText={intl.formatMessage({
				id: 'common.sign-in-again',
				defaultMessage: 'Sign In Again',
			})}
		/>
	);
};
