import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FeatureType } from '../../../../functions/src/shared/user';

import { trpc } from '../../shared/trpc/client';

import { Modal } from '../../base-ui/components';

export const OneOffChargesEnabledDialog = ({ onClose }: { onClose: () => void }) => {
	const intl = useIntl();
	const utils = trpc.useUtils();
	const save = trpc.user.customRent.saveOneOffChargesEnabledToConfig.useMutation({
		onSuccess: () => {
			utils.user.home.invalidate(undefined);
			onClose();
		},
	});

	return (
		<Modal
			type="configuration"
			headerText={intl.formatMessage({
				defaultMessage: 'Would you like to add one-time charges to your payments?',
				id: 'one-off-charges-dialog.header',
			})}
			contentText={intl.formatMessage({
				defaultMessage: 'Please bear in mind that your changes will be in effect starting from now on.',
				id: 'one-off-charges-dialog.content',
			})}
			buttonText={intl.formatMessage({
				defaultMessage: 'Yes, include them',
				id: 'one-off-charges-dialog.yes-button',
			})}
			buttonHandler={() => save.mutate({ oneOffChargesEnabled: true, featureId: FeatureType.CustomRent })}
			buttonDisabled={save.isPending}
			altButtonText={
				<FormattedMessage
					defaultMessage="No, I'll pay them separately to my property manager."
					id="one-off-charges-dialog.no-button"
				/>
			}
			altButtonHandler={() => save.mutate({ oneOffChargesEnabled: false, featureId: FeatureType.CustomRent })}
			altButtonDisabled={save.isPending}
		/>
	);
};
