import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { RouterOutput, trpc } from '../../shared/trpc/client';

import { Button, Footer, Spacing, Text } from '../../base-ui/components';
import { Block, ScrollableBlockList } from '../../base-ui/components/block/block';

import { BackButton } from '../components/back-button';

type Props = {
	recommendedUnits: RouterOutput['user']['listRecommendedUnits'] | undefined;
	handleContinue: (unitId?: string) => void;
	backButtonDisabled?: boolean;
	isPending: boolean;
};

export const RecommendedUnits = ({ recommendedUnits, handleContinue, backButtonDisabled, isPending }: Props) => {
	const [selectedUnitId, setSelectedUnitId] = useState<string>();

	const { mutate } = trpc.user.startRecommendedUnits.useMutation();
	useEffect(() => {
		if (!recommendedUnits) return;
		const unitIds = recommendedUnits.map(({ unitId }) => unitId);
		mutate({ unitIds });
	}, [recommendedUnits]);

	return (
		<>
			<BackButton disabled={backButtonDisabled} />

			<Text type="title" testId="recommended-units-title">
				<FormattedMessage
					defaultMessage="Are you staying in one of these units?"
					id="recommended-units-title"
				/>
			</Text>
			<Spacing $size="s" />

			<Text>
				<FormattedMessage
					defaultMessage="Based on the information that you provided here are possible matches:"
					id="recommended-units-info"
				/>
			</Text>
			<Spacing $size="l" />

			<ScrollableBlockList>
				{(recommendedUnits || []).map(({ unitId, unitName, address, city, state, postalCode }) => {
					return (
						<Block
							key={unitId}
							title={unitName}
							line1={address || ''}
							line2={[city, state, postalCode].join(', ')}
							line3=""
							onClick={() => setSelectedUnitId(unitId)}
							selected={unitId === selectedUnitId}
							testId="recommended-units-list-item"
						/>
					);
				})}
			</ScrollableBlockList>

			<Footer>
				<Button
					type="large"
					onClick={() => handleContinue(selectedUnitId)}
					disabled={!selectedUnitId || isPending}
					testId="recommended-units-button-continue"
				>
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>

				<Button type="link" onClick={() => handleContinue()} testId="recommended-units-button-unit-not-listed">
					<FormattedMessage
						defaultMessage="My unit is not listed here"
						id="recommended-units-button-unit-not-listed"
					/>
				</Button>
			</Footer>
		</>
	);
};
