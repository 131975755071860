import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

import { publicConfig } from '../../../../../functions/src/shared/config';

import { getEnvironment } from '../../../shared/environment';

import { firebaseConfig } from './firebase-config';

export { firebaseErrors } from '../../../shared/firebase-errors';

const app = () => {
	try {
		return initializeApp(firebaseConfig);
	} catch (error) {
		console.error(error);
		return;
	}
};

export const auth = getAuth(app());
export const bankStatementStorage = getStorage(
	app(),
	`gs://${publicConfig.bankStatements.bucketName[getEnvironment()]}`,
);
export const leaseAgreementStorage = getStorage(
	app(),
	`gs://${publicConfig.leaseAgreements.bucketName[getEnvironment()]}`,
);

if (getEnvironment() !== 'production' && window.navigator.userAgent.includes('HeadlessChrome')) {
	// eslint-disable-next-line no-console
	console.log('Setting auth.settings.appVerificationDisabledForTesting to true');
	auth.settings.appVerificationDisabledForTesting = true;
}

if (getEnvironment() === 'local') {
	connectAuthEmulator(auth, process.env.FIREBASE_AUTH_EMULATOR_HOST || 'http://127.0.0.1:9099');
	connectStorageEmulator(bankStatementStorage, 'localhost', 9199);
	connectStorageEmulator(leaseAgreementStorage, 'localhost', 9199);
}

export const googleAuthProvider = new GoogleAuthProvider();
export const microsoftAuthProvider = new OAuthProvider('microsoft.com');
