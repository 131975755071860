import React from 'react';
import { useIntl } from 'react-intl';

import { ProductPaymentsHistory } from '../../components/product-payment-history';

export const AccountUserRentHistory = () => {
	const intl = useIntl();

	return (
		<ProductPaymentsHistory
			title={intl.formatMessage({
				id: 'account-rent-history.title',
				defaultMessage: 'Rent history',
			})}
			type="custom-rent"
		/>
	);
};
