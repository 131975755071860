import { useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';
import { FirebaseError } from 'firebase/app';

import { auth } from '../../contexts/firebase/firebase-api';

type Err = unknown;

type ReturnType<Args extends unknown[], Return> = [
	(...args: Args) => Promise<Return>,
	{ loading: boolean; error?: Err; data?: Return; dataArgs?: Args; clearData: () => void },
];

export const useAsyncHandler = <Args extends unknown[], Return = unknown>(
	fn: (...args: Args) => Promise<Return>,
): ReturnType<Args, Return> => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Err>();
	const [data, setData] = useState<Return>();
	const [dataArgs, setDataArgs] = useState<Args>();

	const handler = useCallback(
		async (...args: Args) => {
			setLoading(true);

			try {
				const result = await fn(...args);

				setLoading(false);
				setData(result);
				setDataArgs(args);
				if (error) {
					setError(null);
				}
				return result;
			} catch (err) {
				setError(err);
				setLoading(false);

				if (shouldCaptureException(err)) {
					Sentry.captureException(err, (scope) => {
						if (auth.currentUser) {
							scope.setContext('user', {
								id: auth.currentUser.uid,
								email: auth.currentUser.email,
								displayName: auth.currentUser.displayName,
							});
						}

						scope.setTag('useAsyncHandler-handlerName', fn.name);
						scope.setExtra('handlerArgs', args);

						return scope;
					});
				}

				throw err;
			}
		},
		[fn, error],
	);

	return [
		handler,
		{
			loading,
			error,
			data,
			dataArgs,
			clearData: () => {
				setData(undefined);
				setDataArgs(undefined);
			},
		},
	];
};

const shouldCaptureException = (error) => {
	if (whitelistFirebaseErrorCodes.includes((error as FirebaseError).code)) return false;

	return true;
};

const whitelistFirebaseErrorCodes: string[] = [];
