import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Center, Spacing, Text } from '../../base-ui/components';

import { links } from '../router/paths';

export const NotFound404 = () => {
	return (
		<Center>
			<Text type="title">
				<FormattedMessage defaultMessage="Not Found" id="not-found-404.not-found" />
			</Text>
			<Spacing $size="m" />
			<Text center>
				<FormattedMessage defaultMessage="Sorry, that page can't be found." id="not-found-404.page-not-found" />
			</Text>
			<Spacing $size="l" />
			<Button type="link" linkTo={links.DEFAULT.ROOT}>
				<FormattedMessage defaultMessage="Return home" id="auth-actions.return-home" />
			</Button>
		</Center>
	);
};
