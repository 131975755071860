import { get } from 'lodash';
import { To } from 'react-router-dom';

import { PaymentMethod } from '../../../../functions/src/shared/payment-transaction';
import { CustomRentConfigForMonth } from '../../../../functions/src/shared/user-rent';
import { ensureExhaustiveCheck } from '../../../../functions/src/shared/utils';

import { RouterOutput } from '../../shared/trpc/client';

import { links } from '../router/paths';

type Pages = ReturnType<typeof getPages>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFn = (...args: any[]) => any;

// Helper type to check if type is an object (but not a function)
type IsObject<T> = T extends object ? (T extends AnyFn ? false : true) : false;

// Helper type to join paths
type JoinPath<P extends string, K extends string> = P extends '' ? K : `${P}.${K}`;

// Main type to extract nested paths
type Paths<T, P extends string = ''> =
	T extends Record<string, unknown>
		? {
				[K in keyof T]: K extends string
					? IsObject<T[K]> extends true
						? Paths<T[K], JoinPath<P, K>>
						: T[K] extends AnyFn
							? P
							: never
					: never;
			}[keyof T]
		: never;

type PStep = Paths<Pages>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Result<Step extends string, X extends Record<string, any> = Pages> = Step extends `${infer P}.${infer Rest}`
	? P extends keyof X
		? Result<Rest, X[P]>
		: never
	: Step extends keyof X
		? X[Step]
		: never;

export type PageLinkHomeData = RouterOutput['user']['home'] | null | undefined;

export type PageVariant = 'onboarding' | 'account';

const getVariantLinks = (variant: PageVariant) => {
	if (variant === 'onboarding') {
		return links.REGISTRATION;
	}
	return links.ACCOUNT;
};

const getPages = () =>
	({
		'credit-boost': {
			'setup': {
				'intro': {
					onContinue: (i: { variant: PageVariant }) =>
						getVariantLinks(i.variant).CREDIT_BOOST.SETUP.DESIGNED_FOR_RENT,
					onNotNow: () => links.ACCOUNT.DASHBOARD,
				},
				'designed-for-rent': {
					onContinue: (i: { variant: PageVariant }) =>
						getVariantLinks(i.variant).CREDIT_BOOST.SETUP.SAFEST_WAY_TO_BUILD_CREDIT,
				},
				'safest-way-to-build-credit': {
					onContinue: (i: { variant: PageVariant }) =>
						getVariantLinks(i.variant).CREDIT_BOOST.SELECT_PAYMENT_METHOD,
					onNotNow: () => links.ACCOUNT.DASHBOARD,
				},
			},
			'select-payment-method': {
				onPaymentMethodSaved: (i: { variant: PageVariant }) =>
					getVariantLinks(i.variant).CREDIT_BOOST.SELECT_RENT_AMOUNT,
			},
			'select-rent-amount': {
				onRentAmountSubmitted: (i: {
					home: PageLinkHomeData;
					config: CustomRentConfigForMonth;
					variant: PageVariant;
				}) => {
					if (i.home?.pmsResidencyHasRoommates) {
						return getVariantLinks(i.variant).CREDIT_BOOST.ROOMMATES_RENT_AMOUNT_CONFIRM;
					}

					if (!i.home?.cardAccount && i.config.firstInstallmentPaymentMethod === PaymentMethod.DirectDebit) {
						return getVariantLinks(i.variant).CREDIT_BOOST.DIRECT_DEBIT.SETUP;
					} else if (
						!(Boolean(i.home?.account) || Boolean(i.home?.accountToVerify)) &&
						i.config.firstInstallmentPaymentMethod === PaymentMethod.ManualETransfer
					) {
						return getVariantLinks(i.variant).CREDIT_BOOST.BANK.CONNECT_BANK;
					}

					return getVariantLinks(i.variant).CREDIT_BOOST.SECURED_LINE_OF_CREDIT.ROOT;
				},
			},
			'responsible-for-full-rent': {
				onResponsibleForFullRentSaved: (i: {
					home: PageLinkHomeData;
					config: CustomRentConfigForMonth;
					variant: PageVariant;
				}) => {
					if (!i.home?.cardAccount && i.config.firstInstallmentPaymentMethod === PaymentMethod.DirectDebit) {
						return getVariantLinks(i.variant).CREDIT_BOOST.DIRECT_DEBIT.SETUP;
					}

					return getVariantLinks(i.variant).CREDIT_BOOST.SECURED_LINE_OF_CREDIT.ROOT;
				},
			},
			'confirm-terms': {
				onConfirmed: (i: { variant: PageVariant }) => getVariantLinks(i.variant).CREDIT_BOOST.SUCCESS.ROOT,
				onMissingConfig: (i: { variant: PageVariant }) =>
					getVariantLinks(i.variant).CREDIT_BOOST.SELECT_RENT_AMOUNT,
			},
			'success': {
				'congrats': {
					onNext: (i: { variant: PageVariant }) =>
						getVariantLinks(i.variant).CREDIT_BOOST.SUCCESS.DO_NOT_PAY_LANDLORD,
				},
				'do-not-pay-landlord': {
					onNext: (i: { variant: PageVariant }) =>
						getVariantLinks(i.variant).CREDIT_BOOST.SUCCESS.CHECK_YOUR_REPORTED_PAYMENTS,
				},
				'check-your-reported-payments': {
					onNext: (i: { variant: PageVariant }) =>
						getVariantLinks(i.variant).CREDIT_BOOST.SUCCESS.ALWAYS_HERE_TO_ASSIST,
				},
				'always-here-to-assist': {
					onNext: () => links.ACCOUNT.DASHBOARD,
				},
			},
			'direct-debit': {
				'setup': {
					onNext(i: { variant: PageVariant }) {
						return getVariantLinks(i.variant).CREDIT_BOOST.DIRECT_DEBIT.CARD_DETAILS;
					},
					onAccessForbidden() {
						return '/';
					},
				},
				'card-verification': {
					onSuccess(i: { variant: PageVariant }) {
						return getVariantLinks(i.variant).CREDIT_BOOST.DIRECT_DEBIT.SUCCESS;
					},
				},
				'success': {
					onSuccess(i: { variant: PageVariant }) {
						return getVariantLinks(i.variant).CREDIT_BOOST.SECURED_LINE_OF_CREDIT.ROOT;
					},
				},
				'cancel-modal': {
					onDeclineDebitCardPayments(i: { variant: PageVariant }) {
						if (i.variant === 'account') {
							return links.ACCOUNT.DASHBOARD;
						}
						return links.REGISTRATION.CREDIT_BOOST.SELECT_PAYMENT_METHOD;
					},
				},
			},
			'secured-line-of-credit': {
				onSecuredLineOfCreditAlreadyAccepted(i: { variant: PageVariant }) {
					return getVariantLinks(i.variant).CREDIT_BOOST.CONFIRM_TERMS;
				},
				onNext(i: { variant: PageVariant }) {
					return getVariantLinks(i.variant).CREDIT_BOOST.SECURED_LINE_OF_CREDIT.CONFIRM;
				},
				onSignUpSuccess(i: { variant: PageVariant }) {
					return getVariantLinks(i.variant).CREDIT_BOOST.CONFIRM_TERMS;
				},
			},
			'connect-bank-account': {
				onCompleted(i: { variant: PageVariant }) {
					return getVariantLinks(i.variant).CREDIT_BOOST.SECURED_LINE_OF_CREDIT.ROOT;
				},
			},
		},
		'direct-debit': {
			'setup': {
				onNext(i: { variant: PageVariant }) {
					if (i.variant === 'account') {
						return links.ACCOUNT.DIRECT_DEBIT.CARD_DETAILS;
					}
					return links.REGISTRATION.DIRECT_DEBIT.CARD_DETAILS;
				},
				onAccessForbidden() {
					return '/';
				},
			},
			'card-verification': {
				onSuccess(i: { variant: PageVariant }) {
					if (i.variant === 'account') {
						return links.ACCOUNT.DIRECT_DEBIT.SUCCESS;
					}
					return links.REGISTRATION.DIRECT_DEBIT.SUCCESS;
				},
			},
			'card-cancel-modal': {
				onCancelSuccess() {
					return '/';
				},
			},
		},
		'customrent-or-creditboost': {
			onAccessToCreditBoostForbidden() {
				return links.REGISTRATION.CREDIT_BUILDER.PAY_TO_LANDLORD;
			},
			onCreditBoostStarted() {
				return links.REGISTRATION.CREDIT_BOOST.SETUP.ROOT;
			},
		},
		'credit-builder': {
			'history-reporting': {
				'info': {
					onContinue: (i: { variant: PageVariant; isOnlyUpsell: boolean }) => {
						if (i.isOnlyUpsell) {
							return links.ACCOUNT.CREDIT_BUILDER.HISTORY_REPORTING_UPSELL.SIGN_UP;
						}
						switch (i.variant) {
							case 'onboarding':
								return links.REGISTRATION.CREDIT_BUILDER.HISTORY_REPORTING.SIGN_UP;
							case 'account':
								return links.ACCOUNT.CREDIT_BUILDER.HISTORY_REPORTING.SIGN_UP;
							default:
								ensureExhaustiveCheck(i.variant);
						}
					},
				},
				'sign-up-in-credit-builder-onboarding': {
					onSignUpSuccess: (i: {
						creditBuilderPaymentId: string | null;
						variant: PageVariant;
						freeTrial: boolean;
					}) => {
						const { creditBuilderPaymentId, variant, freeTrial } = i;

						if (creditBuilderPaymentId && !freeTrial) {
							return variant === 'onboarding'
								? links.REGISTRATION.CREDIT_BUILDER.SUBSCRIPTION_TYPE
								: links.ACCOUNT.CREDIT_BUILDER.SUBSCRIPTION_TYPE;
						}
						return variant === 'onboarding'
							? links.REGISTRATION.CREDIT_BUILDER.SUCCESS
							: links.ACCOUNT.CREDIT_BUILDER.SUCCESS;
					},
				},
				'standalone-sign-up': {
					onSignUpSuccess: () => {
						return links.ACCOUNT.CREDIT_BUILDER.SUCCESS;
					},
				},
			},
			'success': {
				'confirm-terms': {
					onCloseButtonClicked(i: { variant: PageVariant }) {
						if (i.variant === 'account') {
							return links.ACCOUNT.DASHBOARD;
						}
						return links.REGISTRATION.CREDIT_BUILDER.CUSTOM_RENT_SELECT;
					},
					onConfirmed(i: {
						hasStripeAccount: boolean;
						paymentCheckoutLink: To;
						addStripeCardLink: To;
						isRentHistoryUpsellDelayedPayment: boolean;
						paymentId: string | null;
						freeTrial: boolean;
					}) {
						// prefer payment checkout link if we have a payment id
						if (i.paymentId) {
							return i.paymentCheckoutLink;
						}

						if (!i.hasStripeAccount && (i.isRentHistoryUpsellDelayedPayment || i.freeTrial)) {
							return i.addStripeCardLink;
						}

						return i.paymentCheckoutLink;
					},
				},
			},
			'payment-completed': {
				onAlwaysHereToHelp(i: { variant: PageVariant }) {
					if (i.variant === 'onboarding') {
						return links.REGISTRATION.CREDIT_BUILDER.CUSTOM_RENT_SELECT;
					}
					return links.ACCOUNT.DASHBOARD;
				},
			},
			'confirm-decline-credit-builder-modal': {
				onIAmSure(i: { variant: PageVariant; indirectModel: boolean }) {
					if (i.variant === 'account' || i.indirectModel) {
						return links.ACCOUNT.DASHBOARD;
					}
					return links.REGISTRATION.CREDIT_BUILDER.CUSTOM_RENT_SELECT;
				},
			},
		},
		'custom-rent': {
			'select-rent-amount': {
				onRentAmountSubmitted: (i: { home: PageLinkHomeData; variant: PageVariant }) => {
					const link = i.home?.pmsResidencyHasRoommates
						? getVariantLinks(i.variant).CONFIGURE.ROOMMATES_RENT_AMOUNT_CONFIRM
						: getVariantLinks(i.variant).CONFIGURE.SELECT_SCHEDULE;
					return link;
				},
			},
			'roommates-rent-amount-confirm': {
				onResponsibleForFullRentSaved(i: { variant: PageVariant }) {
					return getVariantLinks(i.variant).CONFIGURE.SELECT_SCHEDULE;
				},
			},
			'line-of-credit': {
				onNext(i: { variant: PageVariant }) {
					return getVariantLinks(i.variant).CONFIGURE.CUSTOM_RENT_REPORTING.CONFIRM;
				},
				onSignUpSuccess(i: { variant: PageVariant }) {
					return getVariantLinks(i.variant).CONFIGURE.CONFIRM_CUSTOM_RENT;
				},
			},
			'decline-pay-to-landlord': {
				onNext() {
					return links.ACCOUNT.DASHBOARD;
				},
			},
		},
		'stripe-checkout': {
			onCloseButtonClicked(i: { successPath: string | null }) {
				if (i.successPath?.includes(links.REGISTRATION.CREDIT_BUILDER.PAYMENT_COMPLETED)) {
					return links.REGISTRATION.CREDIT_BUILDER.CUSTOM_RENT_SELECT;
				}
				return links.ACCOUNT.DASHBOARD;
			},
		},
	}) as const;

export const usePageLinks = <T extends PStep>(step: T): Result<T> => {
	const pages = getPages();
	return get(pages, step) as Result<T>;
};
