import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Paper } from '../../../base-ui/components/paper/paper';

import { HouseLeft, StyledHouseLeft } from './house-left';
import { HouseMobile, StyledHouseMobile } from './house-mobile';
import { HouseRight, StyledHouseRight } from './house-right';

export const StyledPaper = styled(Paper)`
	min-height: 40rem;
	max-width: 62rem;
	top: 10rem;
	padding: 4rem 5rem;
	position: relative;
	z-index: 0;
	box-shadow: 0px 0px 20px rgba(23, 23, 23, 0.05);

	flex-shrink: 0;
	width: 100%;

	${StyledHouseLeft} {
		top: 4.6rem;
		left: -37.7rem;
		z-index: -1;
	}
	${StyledHouseRight} {
		top: -6.1rem;
		left: auto;
		right: -33.3rem;
		z-index: -1;
	}
	${StyledHouseMobile} {
		display: none;
	}
	@media (max-width: 450px) {
		height: auto;
		width: 100vw;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transform: none;
		max-height: 100%;
		border-radius: 0;
		padding: 9rem 2rem;
		box-shadow: none;
		overflow: hidden;
		${StyledHouseRight} {
			display: none;
		}
		${StyledHouseLeft} {
			display: none;
		}
		${StyledHouseMobile} {
			display: block;
			left: 0;
			z-index: -1;
		}
	}
	form {
		width: 100%;
	}
`;

export const Container = (props: { children: ReactNode; center?: boolean }) => {
	return (
		<StyledPaper $borderRadius={3}>
			<HouseLeft />
			<HouseRight />
			<HouseMobile />
			{props.children}
		</StyledPaper>
	);
};
