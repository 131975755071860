import styled from 'styled-components';

export const FormContainer = styled.div<{ $visible: boolean }>`
	visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
	height: ${({ $visible }) => ($visible ? 'auto' : 0)};
	overflow: ${({ $visible }) => ($visible ? 'unset' : 'hidden')};
`;

export const FormLoader = styled.div`
	position: absolute;
	z-index: 0;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;
