import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Spacing } from '../../../base-ui/components';

import { BankLogosStripe } from '../../components/bank-logos-stripe';
import { TestimonialsCarousel } from '../../components/carousel/testimonials-carousel';
import { links } from '../../router/paths';
import { AvenueLivingListIcon1 } from './avenue-living-list-icon1';
import { AvenueLivingListIcon2 } from './avenue-living-list-icon2';
import { AvenueLivingListIcon3 } from './avenue-living-list-icon3';
import {
	BodyText,
	Content,
	GlobalStyle,
	Header,
	HeaderFooter,
	Illustration,
	List,
	ListItem,
	Title,
} from './landing-styles';

export const LandingAvenueLiving = () => {
	return (
		<>
			<GlobalStyle $hideOnMobile={false} />

			<Header>
				<Content>
					<Title>
						<span>
							<FormattedMessage defaultMessage="Flexible" id="landing-avenue-living.flexible" />
						</span>{' '}
						<FormattedMessage defaultMessage="stress free" id="landing-avenue-living.stress-free" />{' '}
						<span>
							<FormattedMessage defaultMessage="rent payments" id="landing-avenue-living.rent-payments" />
						</span>
					</Title>

					<BodyText>
						<FormattedMessage
							defaultMessage="Zenbase pays your full rent when it's due to Avenue Living and you pay half at the beginning of the month and half later in the month."
							id="landing-avenue-living.body-text"
						/>
					</BodyText>

					<List>
						<ListItem>
							<AvenueLivingListIcon1 />{' '}
							<FormattedMessage
								defaultMessage="Improved cash flow between paychecks"
								id="landing-avenue-living.improved-cash-flow"
							/>
						</ListItem>
						<ListItem>
							<AvenueLivingListIcon2 />
							<FormattedMessage
								defaultMessage="Stress free and on time rent payments"
								id="landing-avenue-living.stress-free-rent-payments"
							/>
						</ListItem>
						<ListItem>
							<AvenueLivingListIcon3 />
							<FormattedMessage
								defaultMessage="More flexibility for other expenses"
								id="landing-avenue-living.more-flexibility"
							/>
						</ListItem>
					</List>

					<Illustration mobile />

					<HeaderFooter>
						<Button type="large" linkTo={links.REGISTRATION.USER.SIGN_UP} testId="home-button-sign-up">
							<FormattedMessage defaultMessage="Sign Up Now" id="landing-avenue-living.sign-up-now" />
						</Button>
					</HeaderFooter>
				</Content>

				<div>
					<Illustration />
				</div>
			</Header>

			<Spacing $size="xl" />
			<TestimonialsCarousel />

			<Spacing $size="xl" />
			<BankLogosStripe />

			<Spacing $size="xl" />
			<Spacing $size="xl" />
		</>
	);
};
