import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import img from 'url:../../static/shapes/secured-line-of-credit-setup.png';

import { OnboardingSuccess } from '../../templates/onboarding-success';
import { PageVariant, usePageLinks } from '../use-page-links.hook';
import { CreditBoostNotNowButton } from './onboarding-not-now-button';

type CreditBoostIntroProps = {
	variant: PageVariant;
};
export function CreditBoostIntro({ variant }: CreditBoostIntroProps) {
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.setup.intro');

	const title = (
		<FormattedMessage
			defaultMessage="Now with Zenbase you can make full rent payments to build credit even faster."
			id="onboarding-credit-boost.title"
		/>
	);
	const subtitle = (
		<FormattedMessage
			defaultMessage="In addition to your existing rent reporting, Zenbase now allows you to boost your credit by using <b>Zenbase Secured Line of Credit</b> for your full rent payments.{br}{br} This can help improve your credit score by an average of 40 points in 3 months.
"
			id="onboarding-credit-boost.subtitle"
			values={{
				br: <br />,
			}}
		/>
	);

	return (
		<OnboardingSuccess
			title={title}
			backButton={false}
			onNext={() => navigate(pageLinks.onContinue({ variant }))}
			imageSrc={img}
			imageProps={{ padding: '10rem 3rem 0', textAlign: 'center', maxHeight: '28rem' }}
			subtitle={subtitle}
			ctaTestId="onboarding-credit-boost-continue-button"
			cta={<FormattedMessage defaultMessage="Learn More" id="learn-more.learn-more" />}
			secondaryCtaComponent={<CreditBoostNotNowButton onNotNowLink={pageLinks.onNotNow()} />}
		/>
	);
}
