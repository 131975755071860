import React, { PropsWithChildren, useEffect, useState } from 'react';
import { debounce, filter } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Country } from '../../../shared/country';
import { RouterOutput, trpc } from '../../../shared/trpc/client';

import { PageVariant } from '../../pages/use-page-links.hook';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { useUserData } from '../hooks';
import { UserStatus } from '../user-data-context';

interface CustomRentOnboardingState {
	propertiesList: RouterOutput['user']['searchProperties'] | undefined;
	unitsList: RouterOutput['user']['listUnits'] | undefined;
	recommendedUnits: RouterOutput['user']['listRecommendedUnits'] | undefined;
	recommendedUnitsWithFullMatch: RouterOutput['user']['listRecommendedUnits'];
	selectedLandlord: { id: string; name: string; propertyManagerName: string } | undefined;
	gotToManualAddressOrRecommended: () => void;
	gotToManualAddress: () => void;
	handleSelectedPropertyId: (propertyId: string) => void;
	handleSelectedUnit: (unitId: string) => void;
	handlePropertySearchQueryChange: (query: string) => void;
	handleManualAddressOnboarding: (values: ManualAddressOnboardingValues) => Promise<void>;
	handleDone: () => void;
	isPending: boolean;
	variant: PageVariant;
}

export type ManualAddressOnboardingValues = {
	streetAddress: string;
	apartment: string;
	postalCode: string;
	city: string;
	state: string;
	landlord:
		| string
		| { name: string; indirectLandlordId: string }
		| {
				id: string;
				name: string;
				landlordId: string;
				landlordName: string;
		  };
	employer?: string;
	country: Country;
};

const initialState: CustomRentOnboardingState = {
	variant: 'onboarding',
	propertiesList: [],
	unitsList: [],
	recommendedUnits: [],
	recommendedUnitsWithFullMatch: [],
	selectedLandlord: undefined,
	isPending: false,
	gotToManualAddressOrRecommended: () => {
		return;
	},
	gotToManualAddress: () => {
		return;
	},
	handleSelectedPropertyId: () => {
		return;
	},
	handleSelectedUnit: () => {
		return;
	},
	handlePropertySearchQueryChange: () => {
		return;
	},
	handleManualAddressOnboarding: async () => {
		return;
	},
	handleDone: () => {
		return;
	},
};

const ResidencyOnboardingContext = React.createContext(initialState);

type Props = PropsWithChildren<{
	links: {
		selectUnit: string;
		manualAddress: string;
		recommendedUnits: string;
		done: string;
		success: string;
	};
	variant: PageVariant;
}>;

export const ResidencyOnboardingProvider = ({ children, links, variant }: Props) => {
	const [propertySearchQuery, setPropertySearchQuery] = useState<string>();
	const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
	const [selectedUnitId, setSelectedUnitId] = useState<string>();
	const [selectedLandlord, setSelectedLandlord] = useState<{
		id: string;
		name: string;
		propertyManagerName: string;
	}>();

	const setPropertySearchQueryDebounced = React.useRef(
		debounce(
			(q: string) => {
				setPropertySearchQuery(q);
			},
			500,
			{ trailing: true },
		),
	).current;

	const navigate = useNavigate();
	const {
		handlers: { forceRefetch },
		userStatus,
	} = useUserData();

	const trpcUtils = trpc.useUtils();

	const { data: propertiesList, isPending: propertiesListLoading } = trpc.user.searchProperties.useQuery(
		{ query: propertySearchQuery || '' },
		{ refetchOnWindowFocus: false, enabled: !!propertySearchQuery },
	);
	const propertiesListLoadingAndEnabled = propertiesListLoading && !!propertySearchQuery;
	const { data: unitsList } = trpc.user.listUnits.useQuery(
		{ propertyId: selectedPropertyId || '' },
		{ enabled: Boolean(selectedPropertyId), refetchOnWindowFocus: false },
	);
	const { data: recommendedUnits } = trpc.user.listRecommendedUnits.useQuery(undefined, {
		refetchOnWindowFocus: false,
	});

	const recommendedUnitsWithFullMatch = filter(recommendedUnits || [], { matches: 3 });

	const { mutateAsync: userPmsSetUnit, isPending: isSetUnitLoading } = trpc.user.setUnit.useMutation();
	const { mutateAsync: userUpdate, isPending: isUserUpdateLoading } = trpc.user.update.useMutation();
	const { mutateAsync: userSetManualResidency, isPending: isSetManualResidencyLoading } =
		trpc.user.setManualResidency.useMutation({
			onSuccess() {
				trpcUtils.user.home.invalidate();
			},
		});

	const trackOnboardingEvent = useTrackOnboardingEvent();

	useEffect(() => {
		if (userStatus !== UserStatus.ValidUser) {
			trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingAddressSelectionStart });
		}
	}, []);

	useEffect(() => {
		if (selectedPropertyId) {
			navigate(links.selectUnit);
		}
	}, [selectedPropertyId]);

	useEffect(() => {
		if (selectedUnitId) {
			userPmsSetUnit({ unitId: selectedUnitId }).then(async ({ landlord }) => {
				setSelectedLandlord(landlord);
				if (userStatus !== UserStatus.ValidUser) {
					await trackOnboardingEvent
						.mutateAsync({ type: BusinessEventType.OnboardingAddressSelectionEnd })
						.catch(console.error);
				}
				navigate(links.success);
			});
		}
	}, [selectedUnitId]);

	// context handlers

	const gotToManualAddressOrRecommended = () => {
		navigate(!recommendedUnits?.length ? links.manualAddress : links.recommendedUnits);
	};

	const gotToManualAddress = () => {
		navigate(links.manualAddress);
	};

	const handleSelectedPropertyId = (propertyId: string) => {
		setSelectedPropertyId(propertyId);
	};

	const handleSelectedUnit = (unitId: string) => {
		setSelectedUnitId(unitId);
	};

	const handlePropertySearchQueryChange = (query: string) => {
		setPropertySearchQueryDebounced(query);
	};

	const handleDone = () => {
		forceRefetch().then(() => {
			navigate(links.done);
		});
	};

	const handleManualAddressOnboarding = async ({
		apartment,
		country,
		postalCode,
		city,
		streetAddress,
		landlord,
		employer,
		state,
	}: ManualAddressOnboardingValues) => {
		await userUpdate({ employer });

		await userSetManualResidency({
			streetAddress,
			apartment,
			postalCode,
			city,
			country,
			state,
			landlord:
				typeof landlord === 'string' ? landlord : 'indirectLandlordId' in landlord ? landlord.name : undefined,
			landlordId: typeof landlord !== 'string' && 'landlordId' in landlord ? landlord.landlordId : undefined,
		});

		if (userStatus !== UserStatus.ValidUser) {
			await trackOnboardingEvent
				.mutateAsync({ type: BusinessEventType.OnboardingAddressSelectionEnd })
				.catch(console.error);
		}
	};

	const isPending =
		isSetUnitLoading || isUserUpdateLoading || isSetManualResidencyLoading || propertiesListLoadingAndEnabled;

	return (
		<ResidencyOnboardingContext.Provider
			value={{
				variant,
				propertiesList,
				unitsList,
				recommendedUnits,
				recommendedUnitsWithFullMatch,
				selectedLandlord,
				gotToManualAddressOrRecommended,
				gotToManualAddress,
				handleSelectedPropertyId,
				handleSelectedUnit,
				handlePropertySearchQueryChange,
				handleManualAddressOnboarding,
				handleDone,
				isPending,
			}}
		>
			{children}
		</ResidencyOnboardingContext.Provider>
	);
};

export const useResidencyOnboarding = () => {
	const context = React.useContext(ResidencyOnboardingContext);
	if (context === undefined) {
		throw new Error('useResidencyOnboarding must be used within a UIProvider');
	}
	return context;
};
