import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import safestWayToBuildCredit from 'url:../../static/shapes/secured-line-of-credit-safest-way-to-build-credit.png';

import { Box, Button, Center, Divider, Icon, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { ConnectBankList } from '../../components/connect-bank-list';
import { ImgPreload } from '../../components/img-preload';
import { LearnMoreAboutCreditImpactModal } from '../../components/modals/learn-more-about-credit-impact-modal';
import { useInterface } from '../../contexts/hooks';
import { PageVariant, usePageLinks } from '../use-page-links.hook';

type CreditBoostDesignedForRentProps = {
	variant: PageVariant;
};
export function CreditBoostDesignedForRent({ variant }: CreditBoostDesignedForRentProps) {
	const intl = useIntl();
	const navigate = useNavigate();
	const pageLinks = usePageLinks('credit-boost.setup.designed-for-rent');

	const { setModal, closeModal } = useInterface();

	return (
		<Center>
			<BackButton />
			<Spacing $size="s" />
			<Text type="title">
				<FormattedMessage
					defaultMessage="Zenbase line of credit payment is designed for rent."
					id="credit-boost-designed-for-rent.title"
				/>
			</Text>
			<Spacing $size="m" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="You can also pay your rent in full with Zenbase and your rent would be paid via a secured credit line on the 1st of the month. This is a new entry in your credit report in addition to your existing rent reporting and improves the following credit factors. "
					id="credit-boost-designed-for-rent.subtitle"
				/>
			</Text>
			<Spacing $size="s" />
			<Divider />
			<Spacing $size="s" />
			<ConnectBankList
				items={[
					{
						icon: 'automatedPayments',
						title: intl.formatMessage({
							defaultMessage: 'Payment history',
							id: 'credit-boost.list-item-payment-history',
						}),
						subtitle: intl.formatMessage({
							defaultMessage:
								'Making regular payments helps show you’re a reliable and consistent payer .',
							id: 'credit-boost.list-item-payment-history-subtitle',
						}),
					},
					{
						icon: 'overdraftAlerts',
						title: intl.formatMessage({
							defaultMessage: 'Credit Utilization',
							id: 'credit-boost.list-item-credit-utilization',
						}),
						subtitle: intl.formatMessage({
							defaultMessage:
								'Zenbase reports 0 utilization against your credit line, to be as beneficial as possible to your score.',
							id: 'credit-boost.list-item-credit-utilization-subtitle',
						}),
					},
					{
						icon: 'securityAndPrivacy',
						title: intl.formatMessage({
							defaultMessage: 'Account age',
							id: 'credit-boost.list-item-account-age',
						}),
						subtitle: intl.formatMessage({
							defaultMessage:
								'The longer you keep your account, the better it is for this part of your score.',
							id: 'credit-boost.list-item-account-age-subtitle',
						}),
					},
				]}
			/>
			<Spacing $size="m" />
			<Button
				onClick={() => navigate(pageLinks.onContinue({ variant }))}
				testId="onboarding-credit-boost-designed-for-rent-continue-button"
			>
				<FormattedMessage defaultMessage="Continue" id="common.continue" />
			</Button>
			<Button
				onClick={() => {
					setModal(<LearnMoreAboutCreditImpactModal buttonHandler={closeModal} />);
				}}
				testId="onboarding-credit-boost-designed-for-rent-learn-more"
				type="link"
			>
				<Box>
					<FormattedMessage
						defaultMessage="Learn more on the credit impact"
						id="credit-boost.learn-more-on-the-credit-impact"
					/>
					<Icon icon="Info" />
				</Box>
			</Button>
			<ImgPreload src={safestWayToBuildCredit} />
		</Center>
	);
}
