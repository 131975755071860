import React, { ReactNode } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AccountStatus } from '../../../../../../functions/src/shared/account';
import { BusinessEventType } from '../../../../../../functions/src/shared/business-events';
import { CreditSubjectRentReportingStatus } from '../../../../../../functions/src/shared/credit-reporting';
import { EligibilityRules } from '../../../../../../functions/src/shared/eligibility';
import { RentMonth } from '../../../../../../functions/src/shared/rent-month';
import { FeatureType } from '../../../../../../functions/src/shared/user';

import { trpc } from '../../../../shared/trpc/client';
import { triggerChatWidget } from '../../../../shared/zendesk';

import { Button } from '../../../../base-ui/components';

import { useInterface, useUserData } from '../../../contexts/hooks';
import { links } from '../../../router/paths';
import { creditBuilderErrorService } from './credit-builder-error.service';
import { useCreditBuilderSetupIncomplete } from './credit-builder-profile-errors';
import { formatEligibilityResults, ProfileErrorsModalReviewingDetails } from './profile-errors-modal';
import * as S from './profile-errors.style';

export const ProfileErrors = () => {
	const navigate = useNavigate();
	const { setModal, closeModal } = useInterface();

	const { data: creditReportingUserInfo } = trpc.creditReporting.getUserInfo.useQuery(undefined, {
		staleTime: Infinity,
	});

	const { creditBuilderSetupIncomplete } = useCreditBuilderSetupIncomplete();

	const listOnboardingEvents = trpc.user.listOnboardingEvents.useQuery(undefined);
	const { home } = useUserData();
	if (!home) return null;

	const {
		dashboard: { hasErrors, eligibilityErrors, eligibilityRentMonth: eligibilityRentMonthLike, userVerified },
		accountToVerify,
		activeRentConfig,
		creditSubject,
	} = home;

	const modalErrors = [...(eligibilityErrors ?? [])];

	const creditBuilderHasErrors =
		hasErrors[FeatureType.CreditReportingRental] &&
		(creditSubject?.rentReportingStatus === CreditSubjectRentReportingStatus.Active ||
			creditSubject?.rentReportingStatus === CreditSubjectRentReportingStatus.Unpaid);

	const featuresWithErrors = [
		hasErrors[FeatureType.CustomRent] && 'CustomRent',
		hasErrors[FeatureType.CreditBoost] && 'CreditBoost',
		(creditBuilderHasErrors || creditBuilderSetupIncomplete) && 'CreditBuilder',
	].filter((v) => v);

	if (!featuresWithErrors.length) {
		return null;
	}

	if (accountToVerify) {
		return (
			<S.Wrapper
				onClick={() => {
					navigate({
						pathname:
							accountToVerify.status === AccountStatus.Unverified
								? links.ACCOUNT.MICRO_DEPOSIT_VERIFICATION
								: links.ACCOUNT.UPLOAD_STATEMENTS,
					});
				}}
			>
				<S.Title>
					<FormattedMessage
						defaultMessage="Bank account verification pending {showDetails}"
						id="profile-errors.bank-account-verification-pending"
						values={{
							showDetails: (
								<u>
									<FormattedMessage defaultMessage="Show Details" id="profile-errors.show-details" />
								</u>
							),
						}}
					/>
				</S.Title>
			</S.Wrapper>
		);
	}

	let eligibilityMonthText: ReactNode = (
		<FormattedMessage defaultMessage="next month" id="profile-errors.next-month" />
	);
	let showEligibilityErrors = Boolean(modalErrors.length);

	if (modalErrors.length && eligibilityRentMonthLike) {
		const eligibilityRentMonth = RentMonth.fromDbParams(eligibilityRentMonthLike);

		if (activeRentConfig) {
			const currentRentMonth = RentMonth.fromDbParams(activeRentConfig?.rentMonth);
			if (eligibilityRentMonth.isBefore(currentRentMonth)) {
				eligibilityMonthText = <FormattedDate value={currentRentMonth.firstDayAsDate()} month="long" />;
				showEligibilityErrors = false;
			} else {
				eligibilityMonthText = <FormattedDate value={eligibilityRentMonth.firstDayAsDate()} month="long" />;
			}
		}
	} else if (hasErrors[FeatureType.CreditReportingRental]) {
		eligibilityMonthText = null;

		const indirectModel = Boolean(home?.dashboard.indirectModel);

		if (
			creditBuilderErrorService.indirectVerificationPending({
				userVerified,
				indirectModel,
				leaseAgreementUploadPending: Boolean(creditReportingUserInfo?.leaseAgreementUploadPending),
			})
		) {
			showEligibilityErrors = true;
			if (!modalErrors.find((e) => e.rule === EligibilityRules.ResidentVerification)) {
				modalErrors.push({
					key: EligibilityRules.ResidentVerification,
					rule: EligibilityRules.ResidentVerification,
					success: false,
					details: null,
				});
			}
		}
	}

	const startedBankConnection = listOnboardingEvents.data?.[BusinessEventType.OnboardingBankConnectionStart];
	const finishedBankConnection = listOnboardingEvents.data?.[BusinessEventType.OnboardingBankConnectionEnd];

	if (startedBankConnection && !finishedBankConnection) {
		return (
			<S.Wrapper
				onClick={() => {
					closeModal();
					navigate(links.REGISTRATION.BANK.CONNECT_BANK);
				}}
			>
				<S.Title>
					<FormattedMessage
						defaultMessage="CustomRent bank account setup pending."
						id="profile-errors.custom-rent-setup-pending"
					/>{' '}
					<u style={{ cursor: 'pointer' }}>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</u>
				</S.Title>
			</S.Wrapper>
		);
	}

	return (
		<S.Wrapper
			onClick={() => {
				if (!showEligibilityErrors) return;
				const hasBankNotConnectedError = modalErrors.find((e) => e.rule === EligibilityRules.BankConnected);

				let cta = (
					<Button
						onClick={() => {
							closeModal();
							triggerChatWidget();
						}}
					>
						<FormattedMessage defaultMessage="Contact Support" id="profile.contact-support" />
					</Button>
				);
				let secondaryCta: React.JSX.Element | null = null;

				if (hasBankNotConnectedError) {
					cta = (
						<Button
							onClick={() => {
								closeModal();
								navigate(links.ACCOUNT.BANK.CONNECT_BANK);
							}}
						>
							<FormattedMessage defaultMessage="Connect Bank" id="profile-errors.connect-bank" />
						</Button>
					);

					secondaryCta = (
						<Button
							type="link"
							onClick={() => {
								closeModal();
								triggerChatWidget();
							}}
						>
							<FormattedMessage defaultMessage="Contact Support" id="profile.contact-support" />
						</Button>
					);
				}

				setModal(
					<ProfileErrorsModalReviewingDetails
						errors={formatEligibilityResults(modalErrors, Boolean(home?.accountToVerify))}
						cta={cta}
						secondaryCta={secondaryCta}
					/>,
				);
			}}
		>
			<S.Title data-testid="profile-error">
				{/*For examnple: Your CustomRent is not set up for January yet.*/}
				<FormattedMessage
					defaultMessage="Your {features} {featuresWithErrorsLength, plural, one {is} other {are}} not set up {eligibilityMonthText, select, null {} other { for {eligibilityMonthText}}} yet. {showDetails}"
					id="profile-errors.features-not-set-up"
					values={{
						features: featuresWithErrors.join(' & '),
						featuresWithErrorsLength: featuresWithErrors.length,
						eligibilityMonthText,
						showDetails: showEligibilityErrors ? (
							<u style={{ cursor: 'pointer' }}>
								<FormattedMessage defaultMessage="Show Details" id="profile-errors.show-details" />
							</u>
						) : null,
					}}
				/>
			</S.Title>
		</S.Wrapper>
	);
};
