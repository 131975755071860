import React, { useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import tipimg from 'url:../../static/shapes/tip2.svg';
import { z } from 'zod';

import { typedIncludes } from '../../../../../functions/src/shared/utils';

import { Button, Footer, FormInput, Radio, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { useInstaMoney } from '../../contexts/insta-money-context/insta-money-context';
import { links } from '../../router/paths';
import * as S from './tip.styles';

const otherOption = 'Other';
const predefinedTips = ['3', '4', '5'] as const;
const predefinedTypesOfUse = [...predefinedTips, otherOption] as const;

export const InstaMoneyTip = () => {
	const navigate = useNavigate();
	const { setTipAmount, tipAmount, use } = useInstaMoney();
	const intl = useIntl();
	const { schema, getFormData } = useMemo(() => {
		const schemaMemo = z
			.object({
				selectedOption: z.enum(predefinedTypesOfUse),
				other: z
					.string()
					.optional()
					.refine((value) => !value || parseFloat(value) < 30, {
						message: intl.formatMessage({
							defaultMessage: 'That tip is too generous!',
							id: 'insta-money-tip.that-tip-is-too-generous',
						}),
					}),
			})
			.superRefine((data, ctx) => {
				if (data.selectedOption === otherOption && !data.other) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						path: ['other'],
						message: intl.formatMessage({
							defaultMessage: 'Required field',
							id: 'common.required-field',
						}),
					});
				}
			});

		const getFormDataMemo = (newTipAmount: string | undefined) => {
			if (!newTipAmount) {
				return schemaMemo.parse({ selectedOption: '4' });
			}
			if (typedIncludes(predefinedTips, String(newTipAmount))) {
				return schemaMemo.parse({ selectedOption: String(newTipAmount) });
			}

			return schemaMemo.parse({ selectedOption: otherOption, other: newTipAmount });
		};
		return { schema: schemaMemo, getFormData: getFormDataMemo };
	}, [intl]);
	type FormData = z.infer<typeof schema>;

	const form = useForm<FormData>({
		defaultValues: getFormData(tipAmount),
		resolver: zodResolver(schema),
	});

	useEffect(() => {
		form.reset(getFormData(tipAmount));
	}, [tipAmount]);

	const showOtherInput = form.watch('selectedOption') === otherOption;

	const submit = (data: FormData) => {
		if (data.selectedOption === otherOption) {
			if (!data.other || parseFloat(data.other) < 0.01) {
				navigate(links.ACCOUNT.INSTA_MONEY.NO_TIP_REASON);
				return;
			}
			setTipAmount(data.other);
		} else {
			setTipAmount(data.selectedOption);
		}

		navigate(links.ACCOUNT.INSTA_MONEY.CONFIRM_PAYBACK_DATE);
	};

	if (!use) {
		return <Navigate to={links.ACCOUNT.INSTA_MONEY.USE} replace />;
	}

	const askWhyNoTip = () => {
		navigate(links.ACCOUNT.INSTA_MONEY.NO_TIP_REASON);
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<BackButton to={links.ACCOUNT.INSTA_MONEY.CHOOSE_PAYBACK_DATE} />

				<Spacing $size="l" />
				<Text type="title">
					<FormattedMessage
						defaultMessage="Tip whatever you think is fair"
						id="insta-money-tip.tip-whatever-you-think-is-fair"
					/>
				</Text>
				<Spacing $size="s" />
				<Text>
					<FormattedMessage
						defaultMessage="There are <highlight>no fees with InstaMoney</highlight>. Your voluntary tip helps us offer this free service to you and your community in the future."
						id="insta-money-tip.there-are-no-fees"
					/>
				</Text>

				<S.Wrap>
					<img alt="" width="296" src={tipimg} />
				</S.Wrap>

				<S.RadioGroup name="tipOptions">
					{predefinedTypesOfUse.map((amount, i) => (
						<S.RadioWrapper key={amount}>
							{i === 1 && (
								<S.Text>
									<FormattedMessage defaultMessage="Most Popular" id="insta-money-tip.most-popular" />
								</S.Text>
							)}
							<Radio
								{...form.register('selectedOption')}
								value={amount}
								key={amount}
								label={amount === otherOption ? amount : `${amount}$`}
							/>
						</S.RadioWrapper>
					))}
				</S.RadioGroup>

				{showOtherInput && (
					<FormInput
						name="other"
						placeholder={intl.formatMessage({
							defaultMessage: 'Tip Amount',
							id: 'insta-money-tip.tip-amount',
						})}
						type="number"
					/>
				)}

				<Footer>
					<Button htmlType="submit" testId="instamoney-tip-button-continue">
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
					<Button type="link" onClick={askWhyNoTip}>
						<FormattedMessage
							defaultMessage="I don't want to tip"
							id="insta-money-tip.i-dont-want-to-tip"
						/>
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
