import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { PropertyManagerCreditBuilder } from '../../../../../functions/src/shared/landlords';

import { Text } from '../../../base-ui/components';

import { useUserData } from '../../contexts/hooks';
import { useInstaMoneyState } from '../../contexts/insta-money-context/insta-money-context';
import CreditBuilderIcon from '../../static/shapes/credit-builder.svg';
import CustomRentIcon from '../../static/shapes/customrent-feature-icon.svg';
import InstaMoneyIcon from '../../static/shapes/instamoney-feature-icon.svg';

type Props = {
	userDataState: ReturnType<typeof useUserData>;
};
export const LearnMoreAboutFeaturesModalContent = ({ userDataState: { home } }: Props) => {
	const indirectModel = Boolean(home?.dashboard.indirectModel);
	const creditBuilderDisabled = home?.landlord?.creditBuilder === PropertyManagerCreditBuilder.Disabled;
	const { limitAccessToCashAdvance } = useInstaMoneyState();

	return (
		<Wrapper>
			{!creditBuilderDisabled && (
				<Feature>
					<img src={CreditBuilderIcon} width={40} />
					<div>
						<Text color="title">
							<FormattedMessage defaultMessage="CreditBuilder" id="features.credit-builder" />
						</Text>
						<Text type="tiny">
							<FormattedMessage
								defaultMessage="Effortlessly build a verified & official credit history with each rent payment that you are already making."
								id="features.build-history-description"
							/>
						</Text>
					</div>
				</Feature>
			)}
			{!indirectModel && (
				<Feature>
					<img src={CustomRentIcon} />
					<div>
						<Text color="title">
							<FormattedMessage defaultMessage="CustomRent" id="features.custom-rent" />
						</Text>
						<Text type="tiny">
							<FormattedMessage
								defaultMessage="Split your rent into two smaller payments and pick any two repayment dates. Save money by avoiding late or overdraft fees."
								id="features.custom-rent-description"
							/>
						</Text>
					</div>
				</Feature>
			)}
			{!indirectModel && !limitAccessToCashAdvance && (
				<Feature>
					<img src={InstaMoneyIcon} />
					<div>
						<Text color="title">
							<FormattedMessage defaultMessage="InstaMoney" id="features.insta-money" />
						</Text>

						<Text type="tiny">
							<FormattedMessage
								defaultMessage="Access a free $100 cash advance whenever you need it."
								id="features.insta-money-description"
							/>
						</Text>
					</div>
				</Feature>
			)}
		</Wrapper>
	);
};

const Feature = styled.div`
	padding-bottom: 3rem;
	border-bottom: 1px solid #f0f6f7; //TODO: color
	margin-bottom: 3rem;
	display: flex;
	align-items: flex-start;

	img {
		margin-right: 2rem;
	}

	@media (max-width: 450px) {
		margin-bottom: 2rem;
		padding-bottom: 2rem;
		img {
			margin-right: 1.2rem;
		}
	}

	&:last-child {
		margin-bottom: 0;
		border-bottom: none;
		padding-bottom: 0;
	}
`;
const Wrapper = styled.div`
	padding: 4rem 3rem;
	@media (max-width: 450px) {
		padding: 1rem;
	}
`;
