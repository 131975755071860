import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tipimg from 'url:../../static/shapes/tip2.svg';

import { Button, Divider, Footer, Spacing, Text } from '../../../base-ui/components';
import { DayPicker } from '../../../base-ui/components/day-picker/day-picker';

import { BackButton } from '../../components/back-button';
import { HighlightTeal } from '../../components/highlighted';
import { ImgPreload } from '../../components/img-preload';
import { useInstaMoney } from '../../contexts/insta-money-context/insta-money-context';
import { links } from '../../router/paths';

export const InstaMoneyChoosePaybackDate = () => {
	const navigate = useNavigate();

	const { amount, tipAmount, use, paybackDate, setPaybackDate, paybackDateSince, paybackDateUntil } = useInstaMoney();

	if (!use) {
		return <Navigate to={links.ACCOUNT.INSTA_MONEY.USE} replace />;
	}

	const submit = () => {
		navigate(links.ACCOUNT.INSTA_MONEY.TIP);
	};

	return (
		<>
			<BackButton to={links.ACCOUNT.INSTA_MONEY.USE} />

			<Spacing $size="l" />
			<Text type="title">
				<FormattedMessage
					defaultMessage="Confirm your payback date"
					id="insta-money-choose-payback-date.confirm-payback-date"
				/>
			</Text>

			<Divider />
			<Text type="small" center>
				<FormattedMessage
					defaultMessage="Tap onto the date for your payment"
					id="insta-money-choose-payback-date.tap-date"
				/>
			</Text>
			<Spacing $size="l" />

			<StyledDayPicker
				selectedDay={paybackDate}
				onSelect={(date) => {
					if (date) {
						setPaybackDate(date);
					}
				}}
				changeSince={paybackDateSince}
				changeUntil={paybackDateUntil}
			/>

			<Spacing $size="xl" />
			<Text type="small" center>
				<FormattedMessage
					defaultMessage="Cash Advance Amount: {amount}"
					id="insta-money-choose-payback-date.cash-advance-amount"
					values={{
						amount: (
							<HighlightTeal>
								<FormattedNumber
									value={amount + Math.abs(parseInt(tipAmount))}
									style="currency"
									currency="CAD"
									maximumFractionDigits={0}
								/>
							</HighlightTeal>
						),
					}}
				/>
			</Text>
			<Divider />

			<Text type="small">
				<FormattedMessage
					defaultMessage="Most people select the day when they expect to get paid next. So you can <color>select your paycheck date above</color>. You will automatically pay back on this date from your bank account."
					id="insta-money-choose-payback-date.select-paycheck-date"
				/>
			</Text>

			<Spacing $size="xl" />
			<Footer>
				<Button onClick={submit} htmlType="submit">
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
				<Button type="link" linkTo={links.DEFAULT.ROOT}>
					<FormattedMessage defaultMessage="Return home" id="auth-actions.return-home" />
				</Button>
			</Footer>

			<ImgPreload src={tipimg} />
		</>
	);
};

const StyledDayPicker = styled(DayPicker)`
	margin: 0 auto;
	width: 370px;
	@media (max-width: 450px) {
		width: 330px;
	}
`;
