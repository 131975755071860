import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PartnerConnectedLogo } from '../../../shared/components/landlord-logo';

import { Box, Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { useResidencyOnboarding } from '../../contexts/hooks';

export const LandlordConnected = () => {
	const { handleDone: handleLandLordConnected, selectedLandlord } = useResidencyOnboarding();

	return (
		<Box isColumn verticalCenter>
			<Text type="title">
				<FormattedMessage
					defaultMessage="Connected with your landlord"
					id="landlord-connected.connected-with-landlord"
				/>
			</Text>
			<Spacing $size="l" />
			<Text center>
				<FormattedMessage
					defaultMessage="We are connected to your landlord to automatically verify your residency."
					id="landlord-connected.verify-residency"
				/>
			</Text>
			<Spacing $size="xl" />

			{selectedLandlord && (
				<PartnerConnectedLogo propertyManagerName={selectedLandlord.propertyManagerName} showZenbase />
			)}
			<Spacing $size="xl" />

			<Footer>
				<Button type="large" onClick={handleLandLordConnected} testId="landlord-connected-button-continue">
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
			</Footer>
		</Box>
	);
};
