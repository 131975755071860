import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { CreditBuilderSubscriptionPeriodType } from '../../../../functions/src/credit-reporting/credit-reporting-subscription';
import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { publicConfig } from '../../../../functions/src/shared/config';

import { FormattedCents } from '../../shared/components/formatted-cents';
import { trpc } from '../../shared/trpc/client';

import { Block, Button, Footer, Spacing, Text } from '../../base-ui/components';
import { BlockText } from '../../base-ui/components/block/block';

import { useUserData } from '../contexts/user-data-context';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';

type Props = {
	nextPage: string;
};
export const CreditBuilderSelectSubscriptionType = ({ nextPage }: Props) => {
	const navigate = useNavigate();
	const [subscription, setSubscription] = useState<CreditBuilderSubscriptionPeriodType | undefined>();
	const trackOnboardingEvent = useTrackOnboardingEvent();
	const trpcUtils = trpc.useUtils();
	const { home } = useUserData();
	const { formatMessage } = useIntl();

	const { mutate } = trpc.creditReporting.signUpSetSubscriptionPeriod.useMutation({
		onSuccess(data) {
			trpcUtils.creditReporting.getUserInfo.setData(undefined, (cached) => {
				if (cached) {
					cached.payments = data.payments;
					return cached;
				}
				return cached;
			});
			navigate(generatePath(nextPage));
		},
	});

	useEffect(() => {
		trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingCreditBuilderRentalSubscriptionPeriodStart });
	}, []);

	function handleContinue() {
		if (!subscription) {
			throw new Error(formatMessage({ id: 'invalidFormSubmit', defaultMessage: 'Invalid form submit' }));
		}
		mutate({ subscriptionPeriod: subscription });
	}

	return (
		<div>
			<Spacing $size="s" />
			<Text type="title">
				<FormattedMessage id="selectPaymentPlan" defaultMessage="Select your payment plan" />
			</Text>
			<Spacing $size="l" />
			<Text>
				<FormattedMessage
					id="selectPaymentPlanDescription"
					defaultMessage="You can select the payment plan that works best for you to build credit."
				/>
			</Text>
			<Spacing $size="xl" />
			<Block
				title={formatMessage({ id: 'monthly', defaultMessage: 'Monthly' })}
				onClick={() => setSubscription(CreditBuilderSubscriptionPeriodType.Monthly)}
				selected={subscription === CreditBuilderSubscriptionPeriodType.Monthly}
				testId={`onboarding-cb-subscription-type-option-monthly${subscription === CreditBuilderSubscriptionPeriodType.Monthly ? '-selected' : ''}`}
			>
				<BlockText>
					<Text type="body">
						<FormattedCents
							value={
								home?.creditBuilderPricing?.monthlyPriceCents ??
								publicConfig.creditBuilder.monthlyPriceCents
							}
						/>
					</Text>
				</BlockText>
			</Block>
			<Block
				title={formatMessage({ id: 'annually', defaultMessage: 'Annually' })}
				onClick={() => setSubscription(CreditBuilderSubscriptionPeriodType.Yearly)}
				selected={subscription === CreditBuilderSubscriptionPeriodType.Yearly}
				testId="onboarding-cb-subscription-type-option-yearly"
			>
				<BlockText>
					<Text type="body">
						<FormattedCents
							value={
								home?.creditBuilderPricing?.yearlyPriceCents ??
								publicConfig.creditBuilder.yearlyPriceCents
							}
						/>
					</Text>
					<Text
						type="title-ternary"
						style={{
							position: 'absolute',
							left: '35%',
							top: '30%',
							fontSize: '2rem',
						}}
					>
						<FormattedMessage id="save30Percent" defaultMessage="Save 30%" />
					</Text>
				</BlockText>
			</Block>

			<Spacing $size="l" />
			<Footer>
				<Button
					type="large"
					onClick={() => handleContinue()}
					disabled={subscription === undefined}
					testId="onboarding-cb-subscription-type-confirm-button"
				>
					<FormattedMessage id="common.continue" defaultMessage="Continue" />
				</Button>
			</Footer>
		</div>
	);
};
