import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { links } from '../../router/paths';

export const AccountDisableCreditBoost = () => {
	const navigate = useNavigate();

	function handleContinueBoost() {
		navigate(links.ACCOUNT.DASHBOARD);
	}
	function handleCancelBoost() {
		navigate(links.ACCOUNT.DISABLE_RENT);
	}

	return (
		<>
			<BackButton to={links.ACCOUNT.DASHBOARD} />
			<Text type="title">
				<FormattedMessage defaultMessage="Stop CreditBoost?" id="account-stop-credit-boost.title" />
			</Text>

			<Spacing $size="s" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="Do you want to stop using your secured line of credit to boost your credit?"
					id="account-stop-credit-boost.text"
				/>
			</Text>
			<Spacing $size="m" />

			<Footer>
				<Button onClick={handleContinueBoost} testId="account-stop-credit-boost-continue" $maxWidth="100%">
					<FormattedMessage
						defaultMessage="Let’s continue boosting my credit"
						id="account-stop-credit-boost.continue"
					/>
				</Button>
				<Button
					type="link"
					onClick={handleCancelBoost}
					testId="account-stop-credit-boost-disable"
					$maxWidth="100%"
				>
					<FormattedMessage
						defaultMessage="Yes, I want to stop my CreditBoost"
						id="account-stop-credit-boost.disable"
					/>
				</Button>
			</Footer>
		</>
	);
};
