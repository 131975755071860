import React, { ReactNode } from 'react';
import { filter } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { CashAdvanceCannotRequestReasons } from '../../../../../../functions/src/shared/cash-advance';
import { EligibilityRuleResponse, EligibilityRules } from '../../../../../../functions/src/shared/eligibility';

import { Spacing, Text } from '../../../../base-ui/components';
import { IconError } from '../../../../base-ui/components/modal/icons/icon-error';

export const ProfileErrorsModal = ({
	title,
	errors,
	cta,
	secondaryCta,
}: {
	title: ReactNode;
	errors: { title: ReactNode; text: ReactNode }[];
	cta: ReactNode;
	secondaryCta?: ReactNode;
}) => {
	return (
		<Wrapper>
			<IconError />
			<HeaderText color="error" center>
				{title}
			</HeaderText>

			{errors.map(({ title: errorTitle, text }, index) => {
				return (
					<ErrorText key={index}>
						<ErrorTitle>{errorTitle}</ErrorTitle>
						<ErrorExplanation type="small">{text}</ErrorExplanation>
					</ErrorText>
				);
			})}

			<Spacing $size="l" />

			{cta}
			{secondaryCta ?? <></>}
		</Wrapper>
	);
};

export const ProfileErrorsModalReviewingDetails = ({
	errors,
	cta,
	secondaryCta,
}: {
	errors: { title: ReactNode; text: ReactNode }[];
	cta: ReactNode;
	secondaryCta?: ReactNode;
}) => {
	const title = (
		<FormattedMessage
			defaultMessage="We are reviewing your account details. Please bear with us."
			id="profile-errors.reviewing-account-details"
		/>
	);
	return <ProfileErrorsModal title={title} errors={errors} cta={cta} secondaryCta={secondaryCta} />;
};

export const eligibilityErrorMap: Record<EligibilityRules, (details) => { title: ReactNode; text: ReactNode }> = {
	[EligibilityRules.BankConnected]: () => ({
		title: <FormattedMessage defaultMessage="Bank Not Connected" id="profile-errors-modal.bank-not-connected" />,
		text: (
			<FormattedMessage
				defaultMessage="Your bank account isn't connected or verified yet. Please contact us if you have any questions."
				id="profile-errors-modal.bank-not-connected-text"
			/>
		),
	}),
	[EligibilityRules.ConstantIncome]: () => ({
		title: <FormattedMessage defaultMessage="Income Schedule" id="profile-errors-modal.income-schedule" />,
		text: (
			<FormattedMessage
				defaultMessage="Sometimes we don't receive updates from your bank. We will reach out if we need your help."
				id="profile-errors-modal.income-schedule-text"
			/>
		),
	}),
	[EligibilityRules.NotOutstandingPayments]: (details) => {
		let explanation = <></>;
		if (details && typeof details === 'object') {
			const features: string[] = [];
			if (details.customRent) features.push('CustomRent');
			if (details.cashAdvance) features.push('CashAdvance');
			if (features.length) {
				explanation = (
					<FormattedMessage
						defaultMessage=" due to unpaid {features} payments."
						id="profile-errors-modal.due-to-unpaid-payments"
						values={{ features: features.join(' & ') }}
					/>
				);
			}
		}

		return {
			title: <FormattedMessage defaultMessage="Zenbase Balance" id="profile-errors-modal.zenbase-balance" />,
			text: (
				<FormattedMessage
					defaultMessage="You have an outstanding balance with Zenbase{explanation}. Contact us and we can help you repay however it works for you."
					id="profile-errors-modal.zenbase-balance-text"
					values={{ explanation }}
				/>
			),
		};
	},
	[EligibilityRules.Arrears]: () => ({
		title: <FormattedMessage defaultMessage="Landlord Balance" id="profile-errors-modal.landlord-balance" />,
		text: (
			<FormattedMessage
				defaultMessage="You may have an outstanding balance with your landlord. Please contact us if it's already paid."
				id="profile-errors-modal.landlord-balance-text"
			/>
		),
	}),
	[EligibilityRules.ResidentVerification]: () => ({
		title: (
			<FormattedMessage
				defaultMessage="Residency Verification"
				id="profile-errors-modal.residency-verification"
			/>
		),
		text: (
			<FormattedMessage
				defaultMessage="Your residency details are yet to be verified."
				id="profile-errors-modal.residency-verification-text"
			/>
		),
	}),
	[EligibilityRules.SufficientIncome]: () => ({
		title: <FormattedMessage defaultMessage="Income Overview" id="profile-errors-modal.income-overview" />,
		text: (
			<FormattedMessage
				defaultMessage="Our team is reviewing your income and rent details in order to set you up."
				id="profile-errors-modal.income-overview-text"
			/>
		),
	}),
};

export const formatEligibilityResults = (
	eligibilityErrors: EligibilityRuleResponse[] | undefined,
	accountToVerify: boolean,
) => {
	return filter(eligibilityErrors, { success: false }).map(({ key, details }) => {
		if (key === EligibilityRules.BankConnected && accountToVerify) {
			return {
				title: (
					<FormattedMessage
						defaultMessage="Bank Account Verification Pending"
						id="component.bank-account-verification-pending"
					/>
				),
				text: (
					<FormattedMessage
						defaultMessage="Please send us the two deposit amounts for verification once you receive them."
						id="component.bank-account-verification-pending-text"
					/>
				),
			};
		}

		return eligibilityErrorMap[key](details);
	});
};

export const instaMoneyCannotRequestReasonsMap: Record<
	CashAdvanceCannotRequestReasons,
	{ title: ReactNode; text: ReactNode } | undefined
> = {
	[CashAdvanceCannotRequestReasons.BankAccountNotSupported]: {
		title: (
			<FormattedMessage defaultMessage="Bank Account Not Supported" id="component.bank-account-not-supported" />
		),
		text: (
			<FormattedMessage
				defaultMessage="InstaMoney is not supported for your bank account type."
				id="component.bank-account-not-supported-text"
			/>
		),
	},
	[CashAdvanceCannotRequestReasons.NoRentForCurrentMonth]: {
		title: <FormattedMessage defaultMessage="CustomRent Set up" id="component.custom-rent-setup" />,
		text: (
			<FormattedMessage
				defaultMessage="InstaMoney is only available when you are splitting your rent via CustomRent."
				id="component.custom-rent-setup-text"
			/>
		),
	},
	[CashAdvanceCannotRequestReasons.RentNotPaid]: {
		title: <FormattedMessage defaultMessage="CustomRent Payments" id="component.custom-rent-payments" />,
		text: (
			<FormattedMessage
				defaultMessage="Instamoney is only available when you complete your CustomRent payments."
				id="component.custom-rent-payments-text"
			/>
		),
	},
	[CashAdvanceCannotRequestReasons.LeaseEndsSoon]: {
		title: <FormattedMessage defaultMessage="Lease end date" id="component.lease-end-date" />,
		text: (
			<FormattedMessage
				defaultMessage="InstaMoney is not available in the last two months of your lease."
				id="component.lease-end-date-text"
			/>
		),
	},
	[CashAdvanceCannotRequestReasons.NotEligible]: undefined,
	[CashAdvanceCannotRequestReasons.ExistingCashAdvance]: undefined,
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 3rem;
	@media (max-width: 450px) {
		padding: 1rem 1.5rem;
	}
`;

const HeaderText = styled(Text)`
	font-weight: bold;
	font-size: 2rem;
	line-height: 2.7rem;
	margin: 2rem 0 4rem;
`;

const ErrorText = styled.div`
	padding-bottom: 3rem;
	border-bottom: 1px solid #f0f6f7; //TODO: color
	margin-bottom: 3rem;

	width: 100%;

	@media (max-width: 450px) {
		margin-bottom: 2rem;
		padding-bottom: 2rem;
	}
	&:last-child {
		margin-bottom: 0;
		border-bottom: none;
		padding-bottom: 0;
	}
`;
const ErrorTitle = styled(Text)`
	font-weight: bold;
	margin-bottom: 0.6rem;
`;

const ErrorExplanation = styled(Text)`
	font-size: 1.6rem;
	line-height: 2.6rem;

	@media (max-width: 450px) {
		font-size: 1.4rem;
		line-height: 1.4; // this is intentional according to design, but wrong, ~ 2.24rem
	}
`;
