import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import chequeInfo from 'url:../../static/shapes/cheque-info.png';
import z from 'zod';

import { CopyToClipboardButton } from '../../../shared/components/copy-to-clipboard-button';
import { getEnvironment } from '../../../shared/environment';
import { trpc } from '../../../shared/trpc/client';
import { useEnterAccountSchema } from '../../../shared/use-enter-account-schema';

import { Box, Button, Footer, FormInput, Spacing, Text } from '../../../base-ui/components';
import { UnexpectedErrorAlert } from '../../../base-ui/components/alert/alert';

import { BackButton } from '../../components/back-button';
import { useBankOnboarding, useInterface } from '../../contexts/hooks';

export const EnterBankDetails = () => {
	const { setManualOnboardingValues, links } = useBankOnboarding();
	const { setLoading } = useInterface();
	const navigate = useNavigate();
	const intl = useIntl();
	const { schema } = useEnterAccountSchema();
	type Schema = z.infer<typeof schema>;

	const form = useForm<Schema>({
		resolver: zodResolver(schema),
		mode: 'onTouched',
	});

	const validateRoutingNumber = trpc.user.validateRoutingNumber.useMutation();

	const handleSubmit = async (values) => {
		setLoading(true);
		validateRoutingNumber.mutate(
			{ institutionNumber: values.institutionNumber, transitNumber: values.transitNumber },
			{
				onSettled: () => {
					setLoading(false);
				},
				onSuccess: (validationResult) => {
					if (validationResult === false) {
						const errorMessage = intl.formatMessage({
							defaultMessage: 'Invalid combination of transit and institution number',
							id: 'enter-bank-details.invalid-combination-of-transit-and-institution-number',
						});
						form.setError('institutionNumber', {
							message: errorMessage,
						});
						form.setError(
							'transitNumber',
							{
								message: errorMessage,
							},
							{ shouldFocus: true },
						);
					} else {
						setManualOnboardingValues({ ...values, ...validationResult });
						navigate(links.MANUAL_START_VERIFICATION);
					}
				},
			},
		);
	};

	return (
		<Box isColumn>
			<BackButton testId="connect-bank-manually-button-back" />
			<Text type="title">
				<FormattedMessage
					defaultMessage="Enter your bank account details"
					id="enter-bank-details.enter-your-bank-account-details"
				/>
			</Text>
			<Spacing $size="s" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="Please provide all the required bank account details below. You can refer to a physical cheque or view a digital void cheque in your online banking."
					id="enter-bank-details.please-provide-all-the-required-bank-account-details-below"
				/>
			</Text>
			<Spacing $size="m" />
			<Box justify="center">
				<img alt="Cheque info" src={chequeInfo} width="520px" />
			</Box>
			<Spacing $size="m" />
			{validateRoutingNumber.isError && <UnexpectedErrorAlert />}
			<Box justify="center">
				<FormProvider {...form}>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<FormInput
							name="transitNumber"
							placeholder={intl.formatMessage({
								defaultMessage: 'Transit Number',
								id: 'enter-bank-details.transit-number',
							})}
							testId="connect-bank-manually-input-transit-number"
						/>
						<FormInput
							name="institutionNumber"
							placeholder={intl.formatMessage({
								defaultMessage: 'Institution Number',
								id: 'enter-bank-details.institution-number',
							})}
							testId="connect-bank-manually-input-institution-number"
						/>
						<FormInput
							name="accountNumber"
							placeholder={intl.formatMessage({
								defaultMessage: 'Account Number',
								id: 'enter-bank-details.account-number',
							})}
							testId="connect-bank-manually-input-account-number"
						/>
						<Spacing $size="m" />
						<Footer>
							<Button htmlType="submit" testId="connect-bank-manually-button-confirm">
								<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
							</Button>
						</Footer>
					</form>
				</FormProvider>
			</Box>
			{getEnvironment() !== 'production' && (
				<Box>
					<Text>
						Transit Number: 94480 <CopyToClipboardButton text="94480" /> <br />
						Institution Number: 004 <CopyToClipboardButton text="004" /> <br />
						Account Number: random{' '}
						<CopyToClipboardButton text={Math.floor(Math.random() * 100000000000).toString()} />
					</Text>
				</Box>
			)}
		</Box>
	);
};
