import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import avenueLivingLogo from 'url:../../static/shapes/avenue-living-logo.png';

import { Button, Icon } from '../../../base-ui/components';
import { colors } from '../../../base-ui/variables';

import { links } from '../../../app/router/paths';
import { useFirebase, useUserData } from '../../contexts/hooks';
import { UserStatus } from '../../contexts/user-data-context';
import { LogoutIcon } from '../../pages/account/logout-icon';
import { Logo } from '../logo';
import { LanguageSwitcher } from './language-switcher';

const ProfileLink = styled(Link)`
	font-size: 2.4rem;
	width: auto;
`;

export const HeaderRight = styled.div`
	display: flex;
	align-items: center;
`;

export const HeaderWrapper = styled.div<{ $hideOnMobile: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 3rem 5rem;
	z-index: 100;

	#zenbase-logo {
		width: 190px;
		height: 33px;
	}

	${ProfileLink} {
		font-size: 3.2rem;
	}

	@media (max-width: 450px) {
		padding: 1rem 2rem;
		background-color: ${colors.white};
		#zenbase-logo {
			width: 117px;
			height: 20px;
		}

		${ProfileLink} {
			font-size: 2.4rem;
		}
	}

	@media (max-width: 450px) {
		${(props) =>
			props.$hideOnMobile
				? css`
						display: none;
					`
				: css`
						display: flex;
					`}
	}
`;

const AvenueLivingLogo = styled.img.attrs({ src: avenueLivingLogo })`
	max-height: 33px;

	margin-right: auto;
	margin-left: 3rem;

	@media (max-width: 450px) {
		max-height: 20px;
	}
`;

export const Header = () => {
	const { pathname } = useLocation();
	const { userStatus } = useUserData();

	const isSettings =
		Boolean(pathname.includes('/account/settings')) || Boolean(pathname.includes('/account/history'));

	const showProfileLink = userStatus === UserStatus.ValidUser && !isSettings;

	const showALCLogo = Boolean(matchPath(links.DEFAULT.AVENUE_LIVING, pathname));

	const {
		isUserAuthenticated,
		handlers: { handleSignOut },
	} = useFirebase();

	return (
		<HeaderWrapper $hideOnMobile={isSettings}>
			<Logo linkToWebsite={showALCLogo} />

			{showALCLogo && <AvenueLivingLogo />}

			<HeaderRight>
				<LanguageSwitcher />
				{showProfileLink && (
					<ProfileLink to={links.ACCOUNT.SETTINGS}>
						<Icon icon="Profile" />
					</ProfileLink>
				)}
				{!showProfileLink && isUserAuthenticated && (
					<Button type="icon-transparent" onClick={handleSignOut} noPadding>
						<LogoutIcon icon="SignOut" />
					</Button>
				)}
			</HeaderRight>
		</HeaderWrapper>
	);
};
