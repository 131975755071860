import { addDays } from 'date-fns';
import { z } from 'zod';

import type { EncryptedMetro2BaseSegment } from '../credit-reporting/metro2.schema';
import { isIsoDate, isoDate, jsDateToIsoDate } from './iso-date';
import { RentMonth, RentMonthLike } from './rent-month';
import { TaskType } from './tasks';

export const signUpForOptions = z.enum(['rental', 'line-of-credit', 'secured-line-of-credit']);

export const portfolioTypeToMetro2Value = {
	rent: 'O',
	/**
	 * This is not a real portfolio as defined in Metro2.
	 * This portfolio type is used to be able to send two reports for Rent in the same month,
	 * where one is to delete account data (only account status DA)
	 * and the other is to send the actual data to report.
	 * Reporting history for users that are already reported requires extra report that will delete their account data.
	 **/
	rent_delete_account: 'O',
	line_of_credit: 'C',
	secured_line_of_credit: 'C',
} as const;

export type PortfolioTypeDB = keyof typeof portfolioTypeToMetro2Value;

export enum TradelineStatus {
	Open = 'open',
	Closed = 'closed',
	Disabled = 'disabled',
}

export const portfolioTypeSchema = z.enum(['rent', 'line_of_credit']);

export const metro2AccountStatus = z.enum(['11', '13', '71', '78', '80', '82', '83', '84', 'DA']);
export const metro2AccountStatusToDescription: Record<z.infer<typeof metro2AccountStatus>, string> = {
	'11': 'Good Standing',
	'13': 'Closed',
	'71': '30 days past due',
	'78': '60 days past due',
	'80': '90 days past due',
	'82': '120 days past due',
	'83': '150 days past due',
	'84': '180 days past due',
	'DA': 'Delete Entire Account - 🚨 DO NOT USE',
};

function getNextDelinquentAccountStatus(accountStatus: z.infer<typeof metro2AccountStatus>) {
	switch (accountStatus) {
		case '11':
			return '71';
		case '71':
			return '78';
		case '78':
			return '80';
		case '80':
			return '82';
		case '82':
			return '83';
		case '83':
			return '84';
		// confirmed by Equifax to repeat 84 until the account goes to collections (93 or 97)
		// once it's 97, it can't be reported as 11 again
		case '84':
			return '84';
		default:
			throw new Error(`Unexpected account status ${accountStatus}`);
	}
}

export const adminUpdateCreditSubjectSchema = z
	.object({
		firstName: z.string(),
		middleName: z.string(),
		lastName: z.string(),
		firstLineAddress: z.string(),
		secondLineAddress: z.string(),
		state: z.string(),
		city: z.string(),
		postalCode: z.string(),
	})
	.partial()
	.and(z.object({ userId: z.string() }));

// cr TODO rent & line of credit should come from some constant or something
export const portfolioTypeSchemaAll = z
	.union([z.literal('both'), z.literal('all-rent'), z.literal('all-line_of_credit'), z.literal('none')])
	.or(portfolioTypeSchema)
	.optional();

export enum CreditSubjectRentReportingStatus {
	Active = 'active',
	Unpaid = 'unpaid',
	NotConfirmed = 'not-confirmed',
	DisabledByUser = 'disabled-by-user',
	Disabled = 'disabled',
}

export const disabledRentReportingStatuses = [
	CreditSubjectRentReportingStatus.Disabled,
	CreditSubjectRentReportingStatus.DisabledByUser,
] as const;

export type TradelineRecordTaskType = Extract<TaskType, `cr_${string}`>;

export type PmsDiscrepancyTask = {
	type: TaskType.CrPmsDiscrepancy;
	payload: z.infer<(typeof tradelineRecordTaskPayloadPerType)[TaskType.CrPmsDiscrepancy]>;
};

export const tradelineRecordErrorTypes: Record<TradelineRecordTaskType, TradelineRecordTaskType> = {
	[TaskType.CrUserNotVerified]: TaskType.CrUserNotVerified,
	[TaskType.CrPmsDiscrepancy]: TaskType.CrPmsDiscrepancy,
	[TaskType.CrUnpaidBalance]: TaskType.CrUnpaidBalance,
	[TaskType.CrCloseTradeline]: TaskType.CrCloseTradeline,
	[TaskType.CrMissingLastPaymentDate]: TaskType.CrMissingLastPaymentDate,
	[TaskType.CrDifferenceFromLastMonth]: TaskType.CrDifferenceFromLastMonth,
	[TaskType.CrPropertyResidentMismatch]: TaskType.CrPropertyResidentMismatch,
	[TaskType.CrUnexpectedPropertyResidentStatus]: TaskType.CrUnexpectedPropertyResidentStatus,
	[TaskType.CrLocMissingPropertyResidentOnUserRent]: TaskType.CrLocMissingPropertyResidentOnUserRent,
	[TaskType.CrManualPms]: TaskType.CrManualPms,
	[TaskType.CrMissingDateAccountOpened]: TaskType.CrMissingDateAccountOpened,
	[TaskType.CrMissingPropertyResidentId]: TaskType.CrMissingPropertyResidentId,
	[TaskType.CrDateLastPaymentBeforeDateOpened]: TaskType.CrDateLastPaymentBeforeDateOpened,
	[TaskType.CrDuplicatePropertyResident]: TaskType.CrDuplicatePropertyResident,
	[TaskType.CrPendingCreditBuilderPayment]: TaskType.CrPendingCreditBuilderPayment,
	[TaskType.CrIndirectMissingLandlordEmail]: TaskType.CrIndirectMissingLandlordEmail,
} as const;

export const tradelineRecordTaskTypesNotInChecker: TradelineRecordTaskType[] = [TaskType.CrDuplicatePropertyResident];

const commonPayloadSchema = z.object({
	userId: z.string(),
	tradelineRecordId: z.string(),
});

export const tradelineRecordTaskPayloadPerType = {
	[TaskType.CrUserNotVerified]: commonPayloadSchema,
	[TaskType.CrPmsDiscrepancy]: z
		.object({
			differentValues: z.record(z.object({ our: z.string(), pms: z.string() })),
		})
		.merge(commonPayloadSchema),
	[TaskType.CrUnpaidBalance]: z.object({ missingBalanceCents: z.number() }).merge(commonPayloadSchema),
	[TaskType.CrCloseTradeline]: z
		.object({
			propertyResidentId: z.string().nullish(),
			propertyResidentTenantStatus: z.string().nullish(),
			moveOutDate: z.string().refine(isIsoDate).nullish(),
			reason: z.string(),
			manualResidencyId: z.string().nullish(),
		})
		.merge(commonPayloadSchema),
	[TaskType.CrMissingLastPaymentDate]: commonPayloadSchema,
	[TaskType.CrMissingDateAccountOpened]: commonPayloadSchema,
	[TaskType.CrMissingPropertyResidentId]: commonPayloadSchema,
	[TaskType.CrDifferenceFromLastMonth]: z
		.object({
			differentFields: z.array(z.string()),
		})
		.merge(commonPayloadSchema),
	[TaskType.CrPropertyResidentMismatch]: z
		.object({
			tradelinePropertyResidentId: z.string().nullish(),
			tradelinePropertyResidentRoommateId: z.string().nullish(),
			tradelineManualResidencyId: z.string().nullish(),
			propertyResidentId: z.string().nullish(),
			propertyResidentRoommateId: z.string().nullish(),
			manualResidencyId: z.string().nullish(),
		})
		.merge(commonPayloadSchema),
	[TaskType.CrUnexpectedPropertyResidentStatus]: z
		.object({
			propertyResidentStatus: z.string(),
		})
		.merge(commonPayloadSchema),
	[TaskType.CrLocMissingPropertyResidentOnUserRent]: z
		.object({
			userRentId: z.string(),
		})
		.merge(commonPayloadSchema),
	[TaskType.CrManualPms]: commonPayloadSchema,
	[TaskType.CrDateLastPaymentBeforeDateOpened]: z
		.object({
			dateLastPayment: z.string().refine(isIsoDate),
			dateOpened: z.string().refine(isIsoDate),
		})
		.merge(commonPayloadSchema),
	[TaskType.CrDuplicatePropertyResident]: z
		.object({
			duplicateUserId: z.string().nullish(),
		})
		.merge(commonPayloadSchema),
	[TaskType.CrPendingCreditBuilderPayment]: commonPayloadSchema,
	[TaskType.CrIndirectMissingLandlordEmail]: commonPayloadSchema,
} as const satisfies Record<
	TradelineRecordTaskType,
	z.ZodObject<{ userId: z.ZodType<string>; tradelineRecordId: z.ZodType<string> }>
>;

// See metro2 spec, linked from here:
// https://www.notion.so/myzenbase/Equifax-03b15adad290471d853737bd277f6ae4#c1055506896c43c1a15ed925a5874513
export const metro2SpecialComment = z.enum([
	// 'AB', 'AC', 'AH', 'AI', 'AO', 'AP', 'AS', 'AT', 'AU', 'AW', 'AZ', 'B', 'BA',
	'BC',
	// 'BF', 'BI', 'BJ', 'BK', 'BL', 'BN', 'BO', 'BP', 'BS', 'BT', 'C', 'CI', 'CS',
	// 'H', 'I', 'L', 'M', 'O', 'S', 'V', 'ZA', 'ZB', 'ZC', 'ZD',
]);
export const metro2SpecialCommentToDescription: Record<z.infer<typeof metro2SpecialComment>, string> = {
	// AB: 'Debt being paid through insurance',
	// AC: 'Paying under partial payment agreement',
	// AH: 'Purchased by another credit grantor',
	// AI: 'Active military duty',
	// AO: 'Voluntarily surrendered, then redeemed',
	// AP: 'Credit line suspended',
	// AS: 'Account closed due to refinance',
	// AT: 'Account closed due to transfer',
	// AU: 'Account paid in full for less than full balance',
	// AW: 'Affected by natural or declared disaster',
	// AZ: 'Redeemed repossession',
	// B: 'Account payments managed by Credit Counseling Service',
	// BA: 'Transferred to recovery',
	BC: 'Full termination/obligation satisfied (account paid)',
	// BF: 'Early termination/obligation satisfied',
	// BI: 'Involuntary repossession',
	// BJ: 'Involuntary repossession/ obligation satisfied',
	// BK: 'Involuntary repossession/ balance owing',
	// BL: 'Credit Card Lost or Stolen',
	// BN: 'Paid by company which originally sold the merchandise',
	// BO: 'Foreclosure proceedings started',
	// BP: 'Paid through insurance',
	// BS: 'Prepaid',
	// BT: 'Principal Deferred/ interest payment only',
	// C: 'Paid by co-maker',
	// CI: 'Account closed due to inactivity',
	// CS: 'Used by child support agencies only when reporting delinquent or collection accounts',
	// H: 'Loan assumed by another party',
	// I: 'Election of remedy',
	// L: 'Account closed',
	// M: 'Account closed at credit grantor’s request',
	// O: 'Account transferred to another lender',
	// S: 'Special handling. Contact credit grantor for additional information',
	// V: 'Adjustment pending',
	// ZA: 'Included in orderly payment of debt',
	// ZB: 'Account included in voluntary deposit',
	// ZC: 'Skip account',
	// ZD: 'Account included in consumer proposal',
};

export const metro2BaseSegmentHelpers = {
	setFieldsAsClosed(
		input: Pick<
			EncryptedMetro2BaseSegment,
			| 'dateClosed'
			| 'currentBalance'
			| 'scheduledMonthlyPaymentAmount'
			| 'actualPaymentAmount'
			| 'dateLastPayment'
		>,
		baseSegment: EncryptedMetro2BaseSegment,
	) {
		return {
			...baseSegment,
			...input,
			accountStatus: metro2AccountStatus.enum[13],
			specialComment: metro2SpecialComment.enum.BC,
		};
	},

	setFieldsAsClosedWithBalance(input: {
		baseSegment: EncryptedMetro2BaseSegment;
		prevBaseSegment: EncryptedMetro2BaseSegment | null;
		rentMonthLike: RentMonthLike;
	}) {
		return {
			...input.baseSegment,
			currentBalance: 0,
			scheduledMonthlyPaymentAmount: 0,
			actualPaymentAmount: 0,
			accountStatus: metro2AccountStatus.enum[13],
			specialComment: metro2SpecialComment.enum.BC,
			dateLastPayment: input.prevBaseSegment?.dateLastPayment ?? null,
			dateClosed: RentMonth.fromDbParams(input.rentMonthLike).firstDayAsIsoDate(),
		};
	},
	getNextDelinquentValuesForPreviousRecord(
		currentRecord: EncryptedMetro2BaseSegment,
		previousRecord: EncryptedMetro2BaseSegment & { year: number; month: number },
	) {
		const previousRentMonth = RentMonth.fromDbParams({ year: previousRecord.year, month: previousRecord.month });
		if (currentRecord.currentBalance === 0) {
			return {
				accountStatus: metro2AccountStatus.enum['11'],
				dateFirstDelinquency: null,
				amountPastDue: 0,
			};
		}
		const accountStatus: z.infer<typeof metro2AccountStatus> = getNextDelinquentAccountStatus(
			previousRecord.accountStatus,
		);
		if (previousRecord.currentBalance === 0) {
			return {
				accountStatus: previousRecord.accountStatus,
				dateFirstDelinquency: null,
				amountPastDue: 0,
			};
		}
		if (previousRecord.accountStatus === metro2AccountStatus.enum['11']) {
			return {
				accountStatus,
				dateFirstDelinquency: getFirstDelinquencyDateForRentMonth(previousRentMonth),
				amountPastDue: currentRecord.currentBalance,
			};
		}
		return {
			accountStatus,
			amountPastDue: currentRecord.currentBalance,
			dateFirstDelinquency: previousRecord.dateFirstDelinquency
				? isoDate(previousRecord.dateFirstDelinquency)
				: (previousRecord.dateFirstDelinquency as null | undefined),
		};
	},
};

/*
 * User delinquent in RentMonth will have first deliquency date 30 days after the end of the month
 * For February it's end of month.
 * */
export function getFirstDelinquencyDateForRentMonth(rentMonth: RentMonth) {
	const firstDelinquencyDate = addDays(rentMonth.lastDayAsDate(), 30);
	if (rentMonth.next().lastDayAsIsoDate() < jsDateToIsoDate(firstDelinquencyDate)) {
		return jsDateToIsoDate(rentMonth.next().lastDayAsDate());
	}
	return jsDateToIsoDate(firstDelinquencyDate);
}
