import { publicConfig } from '../../../../functions/src/shared/config';

const connectBankPaths = {
	BANK: {
		ROOT: 'bank',
		CONNECT_BANK: 'connect-bank',
		AWAITING_TRANSACTIONS: 'verify-transactions',
		AWAITING_RECONNECT_TRANSACTIONS: 'verify-reconnect-transactions',
		MANUAL_ENTER_BANK_DETAILS: 'enter-bank-details',
		MANUAL_START_VERIFICATION: 'start-verification',
		MANUAL_PENDING_VERIFICATION: 'pending-verification',
		CANNOT_VALIDATE_EARNINGS: 'cannot-validate-earnings',
		RECONNECT_BANK: 'reconnect-bank',
	},
};

const creditBoostPaths = {
	CREDIT_BOOST: {
		ROOT: 'credit-boost',
		SETUP: {
			ROOT: 'setup',
			DESIGNED_FOR_RENT: 'designed-for-rent',
			SAFEST_WAY_TO_BUILD_CREDIT: 'safest-way-to-build-credit',
		},
		SELECT_RENT_AMOUNT: 'rent-amount',
		SELECT_PAYMENT_METHOD: 'select-payment-method',
		ROOMMATES_RENT_AMOUNT_CONFIRM: 'roommates-rent-amount-confirm',
		CONFIRM_TERMS: 'confirm-terms',
		SUCCESS: {
			ROOT: 'success',
			DO_NOT_PAY_LANDLORD: 'do-not-pay-landlord',
			CHECK_YOUR_REPORTED_PAYMENTS: 'check-your-reported-payments',
			ALWAYS_HERE_TO_ASSIST: 'always-here-to-assist',
		},
		DIRECT_DEBIT: {
			ROOT: 'direct-debit',
			SETUP: 'setup',
			CARD_DETAILS: 'card-details',
			SUCCESS: 'success',
		},
		SECURED_LINE_OF_CREDIT: {
			ROOT: 'credit-reporting',
			CONFIRM: 'confirm',
		},
		...connectBankPaths,
	},
} as const;

export const paths = {
	DEFAULT: {
		ROOT: '',
		SIGN_IN: 'sign-in',
		SIGN_OUT: 'sign-out',
		VERIFY_PHONE: 'verify-phone',
		FORGOT_PASSWORD: 'forgot-password',
		AUTH_ACTIONS: 'auth-actions',
		RESET_PASSWORD: 'reset-password',
		INVITE: 'i/:inviteId',
		HANDOVER: {
			ROOT: publicConfig.creditBuilder.handoverPath.split('/')[0],
			INVITE: publicConfig.creditBuilder.handoverPath.split('/')[1],
			DATA_TRANSFER: 'data-transfer',
		},
		AVENUE_LIVING: 'avenueliving',
		CHECKOUT: 'checkout',
		ADD_STRIPE_CARD: 'add-card',
		STRIPE_ACCOUNT_STATUS: 'account-status',
		PAYMENT_STATUS: 'payment-status',
		AGREEMENT: {
			ROOT: 'agreement',
			LINE_OF_CREDIT: 'line-of-credit',
			PAD: 'pad',
		},
		DIRECT_DEBIT_PAY: 'dd/pay',
	},

	REGISTRATION: {
		ROOT: 'registration',
		USER: {
			ROOT: 'user',
			SIGN_UP: 'sign-up',
			SIGN_UP_CREDIT_BUILDER: 'sign-up/credit-builder',
			SIGN_UP_REFERRAL_PARTNER: 'sign-up/r/:referralCode',
			VERIFY_PHONE: 'verify-phone',
			VERIFY_EMAIL: 'verify-email',
		},
		RESIDENCY: {
			ROOT: 'address',
			INPUT_ZIP_CODE: 'input-zip-code',
			SELECT_PROPERTY: 'select-property',
			SELECT_UNIT: 'select-unit',
			LANDLORD_CONNECTED: 'landlord-connected',
			MANUAL_ADDRESS: 'manual-onboarding',
			RECOMMENDED_UNITS: 'recommended-units',
		},
		COUNTRY_NOT_SUPPORTED: 'country-not-supported',
		ONBOARDING_SURVEY: 'onboarding-survey',
		LANDLORD_PAYMENT_METHOD: 'landlord-payment-method',
		...connectBankPaths,
		CONFIGURE: {
			ROOT: 'configure',
			SELECT_RENT_AMOUNT: 'select-rent-amount',
			ROOMMATES_RENT_AMOUNT_CONFIRM: 'roommates-rent-amount-confirm',
			SELECT_SCHEDULE: 'select-schedule',
			CONFIRM_CUSTOM_RENT: 'confirm-custom-rent',
			CUSTOM_RENT_REPORTING: {
				ROOT: 'custom-rent-reporting',
				CONFIRM: 'confirm',
			},
		},
		ONBOARDING_SUCCESS: {
			ROOT: 'onboarding-success',
			LANDLORD: 'landlord',
			RESCHEDULE: 'reschedule',
			SUPPORT: 'support',
		},

		CUSTOM_RENT_WAITLIST: 'waitlist',

		CREDIT_BUILDER: {
			ROOT: 'credit-builder',

			SETUP: {
				ROOT: 'setup',
			},
			SUCCESS: 'success',
			HISTORY_REPORTING: {
				ROOT: 'history-reporting',
				SIGN_UP: 'sign-up',
			},
			SUBSCRIPTION_TYPE: 'subscription-type',
			PAYMENT_COMPLETED: 'payment-completed',

			CUSTOM_RENT_SELECT: 'custom-rent-select',
			PAY_TO_LANDLORD: 'pay-to-landlord',
		},
		PAYMENT_PORTAL: 'payment-portal',
		DIRECT_DEBIT: {
			ROOT: 'direct-debit',
			SETUP: 'setup',
			CARD_DETAILS: 'card-details',
			SUCCESS: 'success',
		},
		...creditBoostPaths,
	},
	DIRECT_DEBIT: {
		ROOT: 'debit',
		PAY: 'pay',
	},
	ACCOUNT: {
		ROOT: 'account',
		DASHBOARD: 'dashboard',
		SETTINGS: 'settings',
		NSF: 'nsf',
		DISABLE_CREDIT_BOOST: 'disable-credit-boost',
		DISABLE_RENT: 'disable-rent',
		CHANGE_RENT_AMOUNT: 'change-rent-amount',
		UPLOAD_STATEMENTS: 'upload',
		MICRO_DEPOSIT_VERIFICATION: 'micro-deposit-verification',
		BANK_VERIFICATION_PENDING: 'bank-verification-pending',
		PARTNER: 'partner',
		CUSTOM_RENT_CREDIT_REPORTING: 'custom-rent-credit-reporting',
		DID_YOU_MOVE_OUT: 'did-you-move-out',
		INSTA_MONEY: {
			ROOT: 'instamoney',
			REQUEST: 'request',
			NOT_ELIGIBLE: 'not-eligible',
			TIP: 'tip-us',
			NO_TIP_REASON: 'no-tip-reason',
			USE: 'how-will-you-use',
			CHOOSE_PAYBACK_DATE: 'choose-payback-dates',
			CONFIRM_PAYBACK_DATE: 'confirm-payback-dates',
		},
		HISTORY: {
			ROOT: 'history',
			RENT: 'rent',
			INSTA_MONEY: 'instamoney',
			CREDIT_BUILDER: 'credit-builder',
		},
		CONFIGURE: {
			ROOT: 'configure',
			SELECT_RENT_AMOUNT: 'select-rent-amount',
			ROOMMATES_RENT_AMOUNT_CONFIRM: 'roommates-rent-amount-confirm',
			SELECT_SCHEDULE: 'select-schedule',
			CONFIRM_CUSTOM_RENT: 'confirm-custom-rent',
			ENABLE_CUSTOM_RENT: 'enable-custom-rent',
			CUSTOM_RENT_REPORTING: {
				ROOT: 'custom-rent-reporting',
				CONFIRM: 'confirm',
			},
		},
		...connectBankPaths,
		RESIDENCY: {
			ROOT: 'residency',
			INPUT_ZIP_CODE: 'input-zip-code',
			SELECT_PROPERTY: 'select-property',
			SELECT_UNIT: 'select-unit',
			LANDLORD_CONNECTED: 'landlord-connected',
			MANUAL_ADDRESS: 'manual-onboarding',
			RECOMMENDED_UNITS: 'recommended-units',
		},
		CREDIT_BUILDER: {
			ROOT: 'credit-builder',
			SETUP: 'setup',
			SUCCESS: 'success',
			PAYMENT_COMPLETED: 'payment-completed',
			SUBSCRIPTION_TYPE: 'subscription-type',
			CONFIRM_RENT_PAYMENT: 'confirm-rent-payment',
			HISTORY_REPORTING: {
				ROOT: 'history-reporting',
				SIGN_UP: 'sign-up',
			},
			HISTORY_REPORTING_UPSELL: {
				ROOT: 'history-reporting-upsell',
				SIGN_UP: 'sign-up',
			},
			INDIRECT_LAST_MONTH_OF_LEASE: 'lease-update',
			PAST_RESIDENCY_DETAILS: 'past-residency-details',
			PAYMENT_SUMMARY: 'payment-summary',
		},
		DIRECT_DEBIT: {
			ROOT: 'direct-debit',
			SETUP: 'setup',
			CARD_DETAILS: 'card-details',
			SUCCESS: 'success',
			CHARGE: ':ptid/charge',
			CHARGE_SUCCESS: 'charge-success',
		},
		PAYMENT_PORTAL: 'payment-portal',
		UPLOAD_LEASE_AGREEMENTS: 'upload-lease-agreements',
		...creditBoostPaths,
	},
};

export const ADMIN_USERS_URL = '/admin/users';

const linksProxyHandler: (prefix: string) => ProxyHandler<typeof paths> = (prefix) => ({
	get(target, p) {
		// eslint-disable-next-line
		const value = Reflect.get(target, p);

		if (typeof value === 'object' && 'ROOT' in value) {
			// eslint-disable-next-line
			return new Proxy(value, linksProxyHandler(`${prefix}${value.ROOT}${value.ROOT ? '/' : ''}`));
		}

		if (p === 'ROOT') {
			if (prefix.length === 1) return prefix;
			return prefix.slice(0, prefix.length - 1);
		}

		return typeof value === 'string' && `${prefix}${value}`;
	},
});

export const links = new Proxy(paths, linksProxyHandler('/'));
