import { UserPmsResidencyType } from '../../../../../functions/src/shared/pms';
import { FeatureSwitch, FeatureType, userHasFeatureEnabled } from '../../../../../functions/src/shared/user';

import { getEnvironment } from '../../../shared/environment';

import { useUserData } from '../../contexts/user-data-context';

export function useCreditBoost() {
	// TODO: creditboost make visible for ALC users
	// and users who have it enabled

	const { user, home } = useUserData();
	if (!user) {
		return {
			canUse: false,
		};
	}
	const featureEnabled = userHasFeatureEnabled(user, FeatureSwitch.CreditBoost);

	const isDirect = home?.residency?.type === UserPmsResidencyType.Direct;
	// const isAlc =
	// 	!!user?.pmsResidency?.landlord?.id &&
	// 	// Avenue Living
	// 	user.pmsResidency.landlord.id === '1f9c2b6a-2b49-4167-9e99-dace7f2016a1';
	// TODO creditboost enable for launch
	const isAlc = false;
	const hasAlreadyCreditBoostConfig = home?.activeRentConfig?.featureId === FeatureType.CreditBoost;
	const isTestEnv = getEnvironment() !== 'production';

	return {
		canUse: isDirect && (isTestEnv || hasAlreadyCreditBoostConfig || isAlc || featureEnabled),
	};
}
