import ReactPhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

import 'react-phone-number-input/style.css';

import { Input } from '../input/input';

export const PhoneInput = styled(ReactPhoneInput).attrs(() => ({
	inputComponent: Input,
	countries: ['US', 'CA'],
	defaultCountry: 'CA',
}))`
	position: relative;
	display: block;

	.PhoneInputCountry {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 2rem;
		z-index: 1;
	}

	input {
		padding-left: 6.5rem;
	}
	label {
		${({ value }) => (value ? '' : 'left: 6.5rem')};
	}
`;
