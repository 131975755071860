import React, { useEffect, useState } from 'react';
import { Matcher, DayPicker as ReactDayPicker } from 'react-day-picker';

import 'react-day-picker/src/style.css';

import { Locale } from 'date-fns';
import { useIntl } from 'react-intl';

import { UserLocale } from '../../../../../functions/src/shared/user';
import { ensureExhaustiveCheck } from '../../../../../functions/src/shared/utils';

import { LoaderCentered } from '../loader/loader';
import { Wrapper } from './styles';

type CaptionLayout = React.ComponentProps<typeof ReactDayPicker>['captionLayout'];

export interface DayPickerProps {
	selectedDay: Date;
	changeSince: Date;
	changeUntil?: Date;
	onSelect: (selectedDay?: Date) => void;
	testId?: string;
	disabledDays?: Matcher[];
	captionLayout?: CaptionLayout;
	className?: string;
}

const localeCache: Record<UserLocale, Locale | undefined> = {
	[UserLocale.EnglishCa]: undefined,
	[UserLocale.FrenchCa]: undefined,
};

export const DayPicker = ({
	selectedDay,
	onSelect,
	changeSince,
	changeUntil,
	testId,
	captionLayout,
	className,
}: DayPickerProps) => {
	const intl = useIntl();
	const [locale, setLocale] = useState<Locale | undefined>();

	function saveLocale(newLocale: Locale) {
		localeCache[intl.locale as UserLocale] = newLocale;
		setLocale(newLocale);
	}

	useEffect(() => {
		const intlLocale = intl.locale as UserLocale;
		if (localeCache[intlLocale]) {
			setLocale(localeCache[intlLocale]);
			return;
		}
		switch (intlLocale) {
			case UserLocale.EnglishCa:
				/**
				 * we want to lazy load the locale for smaller bundle size,
				 * and we need cant use variable in import name so bundler includes it in the build
				 */
				import('date-fns/locale/en-CA/cdn.js').then((loadedLocale) => saveLocale(loadedLocale as Locale));
				break;
			case UserLocale.FrenchCa:
				import('date-fns/locale/fr-CA/cdn.js').then((loadedLocale) => saveLocale(loadedLocale as Locale));
				break;
			default:
				ensureExhaustiveCheck(intlLocale);
				break;
		}
	}, [locale]);

	if (!locale) {
		return <LoaderCentered />;
	}

	return (
		<Wrapper className={className} data-testid={testId}>
			<ReactDayPicker
				showOutsideDays
				defaultMonth={selectedDay}
				mode="single"
				selected={selectedDay}
				onSelect={onSelect}
				weekStartsOn={1}
				fixedWeeks
				locale={locale}
				endMonth={changeUntil}
				startMonth={changeSince}
				disabled={{
					before: changeSince,
					after: changeUntil,
				}}
				classNames={{
					selected: 'day-picker-selected',
					today: 'day-picker-today',
					chevron: 'day-picker-chevron',
					disabled: 'day-picker-disabled',
				}}
				captionLayout={captionLayout ?? 'label'}
			/>
		</Wrapper>
	);
};
