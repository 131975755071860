import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, To, useNavigate } from 'react-router-dom';
import directDebitOk from 'url:../../static/shapes/connect-bank.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { getEnvironment } from '../../../shared/environment';

import { Box, Spacing } from '../../../base-ui/components';

import { useInterface, useUserData } from '../../contexts/hooks';
import { OnboardingSuccess } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { PageVariant, usePageLinks } from '../use-page-links.hook';
import {
	CreditBoostDirectDebitCardCancelModal,
	CustomRentDirectDebitCardCancelModal,
} from './direct-debit-card-cancel-modal';

type Props = {
	variant: PageVariant;
};

export function CustomRentDirectDebitSetup({ variant }: Props) {
	const pageLinks = usePageLinks('direct-debit.setup');
	const navigate = useNavigate();
	const { setModal } = useInterface();

	const trackEvent = useTrackOnboardingEvent();

	function handleNext() {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitStart },
			{
				onSuccess: () => {
					navigate(pageLinks.onNext({ variant }));
				},
			},
		);
	}
	function handleDecline() {
		setModal(<CustomRentDirectDebitCardCancelModal />);
	}
	return <DirectDebitSetup pageLinks={pageLinks} handleNext={handleNext} handleDecline={handleDecline} />;
}
export function CreditBoostDirectDebitSetup({ variant }: Props) {
	const pageLinks = usePageLinks('credit-boost.direct-debit.setup');
	const navigate = useNavigate();
	const { setModal } = useInterface();

	function handleNext() {
		navigate(pageLinks.onNext({ variant }));
	}
	function handleDecline() {
		setModal(<CreditBoostDirectDebitCardCancelModal variant={variant} />);
	}
	return <DirectDebitSetup pageLinks={pageLinks} handleNext={handleNext} handleDecline={handleDecline} />;
}

type SharedProps = {
	pageLinks: {
		onNext: (i: { variant: PageVariant }) => To;
		onAccessForbidden: () => To;
	};
	handleNext: () => void;
	handleDecline: () => void;
};

const DirectDebitSetup = ({ handleNext, handleDecline, pageLinks }: SharedProps) => {
	const { user } = useUserData();

	if (
		user?.paymentCardId ||
		(getEnvironment() === 'production' &&
			user?.pmsResidency?.landlord?.id &&
			// Avenue Living
			user.pmsResidency.landlord.id !== '1f9c2b6a-2b49-4167-9e99-dace7f2016a1' &&
			!user?.email.includes('directdebitenabled@myzenbase.com'))
	) {
		return <Navigate to={pageLinks.onAccessForbidden()} />;
	}

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={directDebitOk} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Worry free payments"
							id="custom-rent-onboarding.direct-debit-setup.title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="Never worry about missing a payment or paying NSF fees with your debit card. For just 0.6% of your payment amount, you can make your Zenbase payments hassle and worry free."
							id="custom-rent-onboarding.direct-debit-setup.text"
						/>
					}
					cta={
						<FormattedMessage
							defaultMessage="Use debit card"
							id="custom-rent-onboarding.direct-debit-setup.confirm"
						/>
					}
					secondaryCta={
						<FormattedMessage
							defaultMessage="No, I don’t want to pay with debit card"
							id="custom-rent-onboarding.direct-debit-setup.decline"
						/>
					}
					ctaTestId="direct-debit-setup-continue-button"
					onNext={handleNext}
					onSecondaryCta={handleDecline}
				/>
			</Box>
		</>
	);
};
