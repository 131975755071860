import React, { ComponentProps, ReactNode } from 'react';

import { colors } from '../../variables';
import { Button } from '../button/button';
import { Configuration } from '../icon/icons';
import { IconCheck } from './icons/icon-check';
import { IconError } from './icons/icon-error';
import { ModalIllustration, ModalText, ModalTitle, ModalWrapper } from './modal.styles';

type Props = {
	type?: 'positive' | 'warning' | 'configuration';
	headerText: ReactNode;
	contentText: ReactNode;
	buttonText?: ReactNode;
	altButtonText?: ReactNode;
	buttonHandler?: () => void;
	buttonTestId?: string;
	altButtonHandler?: () => void;
	buttonDisabled?: boolean;
	altButtonDisabled?: boolean;
	altButtonTestId?: string;
	titleIllustration?: ReactNode;
	titleProps?: ComponentProps<typeof ModalTitle>;
};

export const Modal = ({
	type,
	headerText,
	contentText,
	buttonText,
	buttonHandler,
	buttonDisabled,
	altButtonText,
	altButtonHandler,
	altButtonDisabled,
	titleIllustration,
	altButtonTestId,
	buttonTestId,
	titleProps = {},
}: Props) => {
	return (
		<ModalWrapper>
			{type === 'positive' ? (
				<>
					<IconCheck />
					<ModalTitle {...titleProps}>{headerText}</ModalTitle>
				</>
			) : type === 'warning' ? (
				<>
					{titleIllustration ?? <IconError />}
					<ModalTitle color="error" {...titleProps}>
						{headerText}
					</ModalTitle>
				</>
			) : type === 'configuration' ? (
				<>
					<ModalIllustration>
						<Configuration fill={colors.white} />
					</ModalIllustration>
					<ModalTitle {...titleProps}>{headerText}</ModalTitle>
				</>
			) : (
				<>
					{titleIllustration}
					<ModalTitle {...titleProps}>{headerText}</ModalTitle>
				</>
			)}

			<ModalText>{contentText}</ModalText>
			{buttonText && buttonHandler && (
				<Button onClick={buttonHandler} disabled={buttonDisabled} testId={buttonTestId}>
					{buttonText}
				</Button>
			)}
			{altButtonText && altButtonHandler && (
				<Button type="link" onClick={altButtonHandler} disabled={altButtonDisabled} testId={altButtonTestId}>
					{altButtonText}
				</Button>
			)}
		</ModalWrapper>
	);
};

export const ConfigurationModal = (props: Omit<Props, 'type'>) => <Modal type="configuration" {...props} />;
export const PositiveModal = (props: Omit<Props, 'type'>) => <Modal type="positive" {...props} />;
export const WarningModal = (props: Omit<Props, 'type'>) => <Modal type="warning" {...props} />;
