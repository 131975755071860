import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { getEnvironment } from '../shared/environment';
import { isImpersonating, stopImpersonationAndRedirectBackToAdmin } from '../shared/impersonation';
import { TrpcProvider } from '../shared/trpc/provider';

import { ErrorBoundary } from '../base-ui/error-boundary';

import { Container } from './components/app-container/app-container';
import { AppWrapper } from './components/app-wrapper';
import { Header } from './components/profile/header';
import { SplashScreen } from './components/splash-screen';
import { AuthProvider, InterfaceProvider, UserDataProvider } from './contexts';
import { useFirebase, useUserData } from './contexts/hooks';
import { GlobalStyles } from './global-styles';
import { InternalError } from './pages/internal-error';
import { links } from './router/paths';
import { Router } from './router/router';
import { useRouteChangeScroll } from './utils/hooks/use-route-change-scroll';
import { LocaleSync } from './utils/i18n/locale-sync';
import { LocalesProvider } from './utils/i18n/locales-provider';

export const App = () => {
	useRouteChangeScroll();

	return (
		<TrpcProvider passImpersonationHeader>
			<LocalesProvider>
				<GlobalStyles />
				<AppWrapper>
					<ErrorBoundary errorPage={<InternalError />}>
						<InterfaceProvider>
							<AuthProvider
								appName="app"
								paths={{
									default: links.DEFAULT.ROOT,
									login: links.DEFAULT.SIGN_IN,
									onLogin: links.ACCOUNT.DASHBOARD,
									onPhoneVerify: links.DEFAULT.VERIFY_PHONE,
									onRegistrationEmailVerify: links.REGISTRATION.USER.VERIFY_EMAIL,
									onRegistrationPhoneVerify: links.REGISTRATION.USER.VERIFY_PHONE,
									onRegistrationCompleted: links.REGISTRATION.RESIDENCY.SELECT_PROPERTY,
								}}
							>
								<UserDataProvider splashScreen={<SplashScreen />}>
									<LocaleSync />
									<ErrorBoundary errorPage={<InternalError />}>
										<Container>
											<Header />
											<Router />
										</Container>
										<UtilityBar />
									</ErrorBoundary>
								</UserDataProvider>
							</AuthProvider>
						</InterfaceProvider>
					</ErrorBoundary>
				</AppWrapper>
			</LocalesProvider>
		</TrpcProvider>
	);
};

const UtilityBar = () => {
	const { home } = useUserData();
	const {
		isUserAuthenticated,
		handlers: { handleSignOut },
	} = useFirebase();

	const handleSignOutClick = async () => {
		await handleSignOut();
		window.location.href = '/';
	};

	if (localStorage.getItem('screenshot-mode')) {
		return null;
	}

	if (isImpersonating()) {
		if (getEnvironment() === 'production') {
			return (
				<TemporaryLinks>
					{home && (
						<span style={{ color: 'red' }}>
							<FormattedMessage
								defaultMessage={`You are viewing account of {firstName} {lastName} User ID: {userId}`}
								id="footer-impersonations-label"
								values={{
									firstName: home.user.firstName,
									lastName: home.user.lastName,
									userId: home.user.userId,
								}}
							></FormattedMessage>
						</span>
					)}{' '}
					<button
						data-testid="home-button-stop-impersonating"
						onClick={stopImpersonationAndRedirectBackToAdmin}
					>
						<FormattedMessage
							defaultMessage="Stop impersonating"
							id="footer-impersonations.stop"
						></FormattedMessage>
					</button>
				</TemporaryLinks>
			);
		}
		return (
			<TemporaryLinks>
				{home && (
					<a
						href={`/admin/users/${home.user.userId}`}
						target="_blank"
						rel="noreferrer"
						style={{ color: 'red', textDecoration: 'none' }}
					>
						{home.user.firstName} {home.user.lastName}
					</a>
				)}{' '}
				<button onClick={stopImpersonationAndRedirectBackToAdmin}>
					<FormattedMessage defaultMessage="stop" id="footer-impersonations.stop-button"></FormattedMessage>
				</button>
			</TemporaryLinks>
		);
	}

	if (isUserAuthenticated && window.location.hostname === 'localhost') {
		return (
			<TemporaryLinks>
				<button data-testid="home-button-sign-out" onClick={handleSignOutClick}>
					<FormattedMessage defaultMessage="sign out" id="footer.sign-out"></FormattedMessage>
				</button>
			</TemporaryLinks>
		);
	}

	return null;
};

const TemporaryLinks = styled.div`
	position: fixed;
	top: 0;
	right: 0;
`;
