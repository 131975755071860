import React from 'react';
import { FormattedMessage } from 'react-intl';
import modalAlert from 'url:../../static/shapes/modal-alert.png';

import { ReturnedTransactionReason } from '../../../../../functions/src/shared/payment-error';

import { Box, Spacing } from '../../../base-ui/components';

import { OnboardingSuccess } from '../../templates/onboarding-success';

type Props = {
	onNext: () => void;
	onCancel: () => void;
	canRetry?: boolean;
	errorCode?: string | ReturnedTransactionReason;
};

function mapErrorCode(code?: string | ReturnedTransactionReason) {
	switch (code) {
		case 'invalid_account':
			return <FormattedMessage id="moneris.error.invalid-account" defaultMessage="Invalid Card" />;
		case 'nsf':
			return <FormattedMessage id="moneris.error.nsf" defaultMessage="Non sufficient funds" />;
		default:
			return <FormattedMessage id="moneris.error.default" defaultMessage="Processing Error" />;
	}
}

export const MonerisErrorCharge = ({ onNext, onCancel, errorCode, canRetry }: Props) => {
	const reason = mapErrorCode(errorCode);

	return (
		<Box isColumn verticalCenter>
			<img src={modalAlert} alt="" width="100" />
			<Spacing $size="m" />

			<OnboardingSuccess
				title={<FormattedMessage id="moneris.error-title" defaultMessage="Your card payment was declined." />}
				subtitle={
					<>
						<p>
							<FormattedMessage
								id="moneris.error-message"
								defaultMessage="Your payment was not successful due to <strong>{reason}</strong>. You can either retry or send your payment via e-Transfer to {email}."
								values={{
									reason,
									email: <a href="mailto:payments@myzenbase.com">payments@myzenbase.com</a>,
								}}
							/>
						</p>
					</>
				}
				cta={<FormattedMessage id="moneris.error-retry" defaultMessage="Retry" />}
				ctaDisabled={!canRetry}
				onNext={() => onNext()}
				secondaryCta={<FormattedMessage id="moneris.error-not-now" defaultMessage="I'll try later" />}
				onSecondaryCta={() => onCancel()}
				ctaTestId="direct-debit-card-fail-try-again-button"
			/>
		</Box>
	);
};
